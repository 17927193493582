import React from 'react'
import {Trans, useTranslation} from 'react-i18next'
import XTrans from '../XTrans'
import {NavLink} from 'react-router-dom';
import {Image} from 'react-bootstrap';

export const HowDoesItWork = (props) => {

    const {t} = useTranslation();

    return (
        <div className="container aos-init aos-animate" data-aos="fade-up">

            <div className="section-title">
                <h2><Trans>How does it work</Trans></h2>
                <p><Trans>Discover the smart contract technology used to manage the digital collectibles on the
                    blockchain.</Trans></p>
            </div>

            <div className="row content text-center">
                <div className="col-lg-6">
                    <h4><Trans>What is it?</Trans></h4>
                    <p>
                        <XTrans>The <uncs/> is a new generation of United Nations stamps that are connected to the
                            Ethereum blockchain.</XTrans>
                    </p>
                    <p>
                        <Trans>Each stamp bears a unique secret code hidden behind a scratch-off area.</Trans> <Trans>The secret code enables its owner to activate exactly one counterpart of that stamp on
                            the blockchain as a digital collectible.</Trans>
                    </p>
                    <p>
                        <Trans>The creation, ownership, exchange and trading of the digital stamps is managed through a
                            smart contract deployed on the Ethereum blockchain.</Trans>
                    </p>
                    <p>
                        <Trans>The availability is limited to 90,000 stamps in total, 30,000 per issuing office (Geneva,
                            New York, Vienna), and encoded into the smart contract.</Trans>
                    </p>
                    <NavLink to={'/background'} className="mr-1 btn-learn-more"><Trans>Background
                        information</Trans></NavLink>
                    <NavLink to={'/behind-the-scenes'} className="mr-1 btn-learn-more"><Trans>Behind the scenes</Trans></NavLink>
                </div>
                <div className="col-lg-6 pt-4 pt-lg-4 text-center mt-auto mb-auto">
                    <Image alt={t('A UN crypto stamp with unscratched fields')}
                           src={'./images/UNCS2/UNCS2-NewYork.png'} className="box-shadow-1" width="70%"/>
                </div>
            </div>

            <div className="row content">
                <div className="col-lg-6 pt-4 pt-lg-4 text-center mt-auto mb-auto">
                    <Image
                        alt={t('A UN crypto stamp with scratched-off fields revealing the hidden secret code and the stamp ID')}
                        src={`./images/assets/stamp-scratched.jpg`} className="box-shadow-1" width="70%"/>
                </div>
                <div className="col-lg-6 pt-4">
                    <h4><Trans>How can I participate?</Trans></h4>
                    <p>
                        <Trans>In order to participate you will need:</Trans>
                    </p>
                    <ul>
                        <li><i className="ri-check-double-line"></i>
                            <Trans>A funded Ethereum wallet to pay for the Ethereum transaction fees ("gas") that occur
                                when executing the activation function in the smart contract on the blockchain.</Trans>
                        </li>
                        <li><i className="ri-check-double-line"></i>
                            <Trans>One or more stamps that you can purchase from the United Nations Postal
                                Administration, either online in the United Nations stamps webshop or at the points of
                                sale listed on the United Nations stamps website.</Trans></li>
                    </ul>
                    <p className="text-center">
                        <a href="#shop" className="mr-1 btn-learn-more" onClick={props.handleScrollShop}><Trans>Get
                            stamps</Trans></a>
                        <NavLink to={'/wallet'} className="mr-1 btn-learn-more"><Trans>Learn more about your
                            wallet</Trans></NavLink>
                    </p>
                </div>
            </div>


        </div>
    )
}
