import QrReader from "react-qr-reader"


/**
 * https://github.com/JodusNodus/react-qr-reader/issues/287
 * In v3 There is a bug in the library that causes the camera to not be released when the component is unmounted.
 * and does not support react 18 yet
 * So we use v2 for now
 */
export const StampQrReader = ({
    previewStyle,
    handleError,
    handleScan,
    isActivated = false
}) => {

    if (!isActivated) return <></>;

    return <QrReader
        delay={500}
        style={previewStyle}
        onError={handleError}
        onScan={handleScan}
    />
}