import i18n from 'i18next'
import detector from "i18next-browser-languagedetector"
import {initReactI18next} from 'react-i18next'
import XHR from 'i18next-xhr-backend'
import languageEN from './locate/translations-en.json'
import languageES from './locate/translations-es.json'
import languageFR from './locate/translations-fr.json'
import languageIT from './locate/translations-it.json'
import languageZH from './locate/translations-zh-hans.json'
import languageDE from './locate/translations-de.json'
import languageXX from './locate/translations-xx.json'
i18n
.use(detector)
.use(XHR)
.use(initReactI18next)
.init({
    resources: {
        'en': {translations: languageEN},
        'es': {translations: languageES},
        'fr': {translations: languageFR},
        'it': {translations: languageIT},
        'zh': {translations: languageZH},
        'de':{translations: languageDE},
        'xx': { translations: languageXX}        
    },
    //saveMissing: true,
    //saveMissingTo:'current',
    /* default language when load the website in browser, if setup then detector does not work fine*/
    //lng: "en",
    /* When react i18next not finding any language to as default in browser */
    fallbackLng: "en",
    detection: {
        // order and from where user language should be detected
        order: ['localStorage', 'querystring', 'cookie', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
       
        // keys or params to lookup language from
        lookupQuerystring: 'lng',
        lookupCookie: 'i18next',
        lookupLocalStorage: 'i18nextLng',
        lookupSessionStorage: 'i18nextLng',
        lookupFromPathIndex: 0,
        lookupFromSubdomainIndex: 0,
       
        // cache user language on
        caches: ['localStorage', 'cookie'],
        excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
       
        // optional expire and domain for set cookie
        cookieMinutes: 10,
        cookieDomain: 'UNCS',
       
        // optional htmlTag with lang attribute, the default is:
        htmlTag: document.documentElement,
       
        // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
        cookieOptions: { path: '/', sameSite: 'strict' }
      },
    /* debugger For Development environment */
    // debug: process.env.NODE_ENV !== 'production',
    debug: false,
    ns: [],
    nsSeparator: false,
    defaultNS: 'translations',
    keySeparator: false,
    load:'languageOnly',// we only provide en, de -> no region specific locals like en-US, de-DE
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        wait: true,
        useSuspense:false,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        //nsMode: 'default'
    }
})

export default i18n;