import React from 'react';
import { Button, NavbarBrand, Navbar, Nav, Form } from 'react-bootstrap';
import { withTranslation, Trans } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import ReactGA from 'react-ga';

/*
 * The language bar should always be displayed fixed at the top of the page. 
 * The welcome message button on the left corner should link to the UN home page. 
 * Available languages should be placed on the far right side, in English alphabetical order.
 * @see https://www.un.org/styleguide/
 */

class LanguageBar extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  changeLanguage(lang) {
    if(this.props.i18n.language !== lang) {
      console.log("Changing language to ", lang)
      this.props.i18n.changeLanguage(lang);
      ReactGA.event({
        category: 'Change Language',
        action: lang
      });
      ReactGA.set({ lang })
    }
  }

  render() {
    return (
      <Navbar expand="lg" className="un-lang-navbar un-blue-border-bottom">
        <NavbarBrand href="https://unstamps.org" className="nav-lang-header-text">
          <FontAwesomeIcon icon={faHome} style={{ fontSize: "16px", xxmarginTop: 0 }} /> <Trans>Welcome to the United Nations Postal Administration</Trans>
        </NavbarBrand>
        <Nav className="mr-auto"></Nav>
        <Form style={{ zIndex: 100 }}>
          <Navbar.Toggle aria-controls="language-switcher" />
          <Navbar.Collapse expand="lg" id="language-switcher">
            <Nav className="mr-auto">
              <Nav.Item>
                <Button size={"sm"} variant="link" className="nav-lang-header-text"
                  style={{ color: this.props.i18n.language === 'zh' ? 'black' : '' }}
                  onClick={() => { this.changeLanguage('zh') }}>
                  简体中文
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button size={"sm"} variant="link" className="nav-lang-header-text"
                  style={{ color: this.props.i18n.language === 'en' ? 'black' : '' }}
                  onClick={() => { this.changeLanguage('en') }}>
                  English
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button size={"sm"} variant="link" className="nav-lang-header-text"
                  style={{ color: this.props.i18n.language === 'fr' ? 'black' : '' }}
                  onClick={() => { this.changeLanguage('fr') }}>
                  Français
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button size={"sm"} variant="link" className="nav-lang-header-text"
                  style={{ color: this.props.i18n.language === 'de' ? 'black' : '' }}
                  onClick={() => { this.changeLanguage('de') }}>
                  Deutsch
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button size={"sm"} variant="link" className="nav-lang-header-text"
                  style={{ color: this.props.i18n.language === 'it' ? 'black' : '' }}
                  onClick={() => { this.changeLanguage('it') }}>
                  Italiano
                </Button>
              </Nav.Item>
              <Nav.Item>
                <Button size={"sm"} variant="link" className="nav-lang-header-text"
                  style={{ color: this.props.i18n.language === 'es' ? 'black' : '' }}
                  onClick={() => { this.changeLanguage('es') }}>
                  Español
                </Button>
              </Nav.Item>
              { process.env.NODE_ENV !== 'production' ?
                <Nav.Item>
                  <Button size={"sm"} variant="link" className="nav-lang-header-text"
                    style={{ color: this.props.i18n.language === 'xx' ? 'black' : '' }}
                    onClick={() => { this.changeLanguage('xx') }}>
                    XXX
                  </Button>
                </Nav.Item>: null
              }
            </Nav>
          </Navbar.Collapse>
        </Form>
      </Navbar>
    );
  }
}

export default withTranslation()(LanguageBar);