import React from 'react'
import XTrans from '../XTrans';
import { Trans } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';

export const TradingPlatforms = () => {
  return (
    <div className="container" data-aos="fade-up">

          <div className="section-title">
            <h2><Trans>Trading Platforms</Trans></h2>
            <p><XTrans>The <uncs/> conforms to established standards and can be safely traded on third party platforms.</XTrans></p>
          </div>

          <div className="row content">
            <div className="col-lg-6">
              <h6>ERC-721</h6>
              <p>
                <XTrans>The smart contract used to manage the <uncs/> adheres to and was implemented using the open ERC-721 standard.</XTrans>
              </p>
              <p>
                <Trans>The items managed by such a smart contract are called tokens and can be safely traded on third-party platforms.</Trans>
              </p>
              <a href={'https://eips.ethereum.org/EIPS/eip-721'} target="_blank" rel="noopener noreferrer" className="mr-1">
                <Trans>Learn about the ERC-721 standard</Trans>
                <FontAwesomeIcon icon={faExternalLinkSquareAlt} className="ml-2" />
              </a>              
            </div>
            <div className="col-lg-6 pt-4 pt-lg-0">
              <h6><Trans>Secondary markets</Trans></h6>
              <p>
                <Trans>OpenSea is a marketplace for rare digital goods and crypto collectibles that specialize in the trading and auctioning of tokens managed by ERC-721 smart contracts.</Trans>
              </p>
              <p>
                <XTrans>Whether you want to sell one of your stamps or are looking to complete your collection, please visit the <uncs/> collection on either of these marketplaces.</XTrans>
              </p>
              <p>
                <XTrans>Visit the <uncs /> collection on</XTrans>
                &nbsp;
                <a href={`${process.env.REACT_APP_OPENSEA_COLLECTION_URL_SERIES_1}`} target="_blank" rel="noopener noreferrer" className="mr-1">
                  OpenSea.io (Series I)
                  <FontAwesomeIcon icon={faExternalLinkSquareAlt} className="ml-2" />
                </a>
                &nbsp;|&nbsp;
                <a href={`${process.env.REACT_APP_OPENSEA_COLLECTION_URL_SERIES_2}`} target="_blank" rel="noopener noreferrer" className="mr-1">
                OpenSea.io (Series II)
                  <FontAwesomeIcon icon={faExternalLinkSquareAlt} className="ml-2" />
                </a>
              </p>
            </div>
          </div>

      </div>
  )
}
