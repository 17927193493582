import React from "react";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import XTrans from "src/components/XTrans";
import {ReactComponent as InstructionsIcon} from "src/images/instructions.svg";
import {Container, Alert, NavLink, Button} from "react-bootstrap";
import {Trans} from "react-i18next";

export function InstructionAlert({existsStatus}) {
    return (
        <Alert
            variant="info"
            hidden={existsStatus === "loading" || existsStatus === "owned_by"}
        >
            <Alert.Heading>
                <InstructionsIcon
                    style={{
                        height: 24,
                        width: "auto",
                    }}
                    fill="currentColor"
                    className="mr-1"
                />
                <Trans>Instructions</Trans>
            </Alert.Heading>
            <Container>
                <XTrans>
                    If you are the owner of this <uncs/> we have some instructions for
                    you on how to get started
                </XTrans>
                <NavLink to={"/start"} className="ml-2">
                    <Button size="sm">
                        <FontAwesomeIcon icon={faArrowRight} className="mr-1"/>
                        <Trans>Show instructions</Trans>
                    </Button>
                </NavLink>
            </Container>
        </Alert>
    );
}
