import React, { Component } from 'react';
import { Alert, Button, CardDeck, Col, Container, Row } from "react-bootstrap";
import StampCard from './StampCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationCircle, faHourglassHalf, faHourglassStart } from '@fortawesome/free-solid-svg-icons';
import { Redirect } from 'react-router-dom';
import Emoji from '../Emoji';
import { Grid, Rings, TailSpin, ThreeDots, Triangle } from 'react-loader-spinner';
import EthTx from '../styles/EthTx';
import StampID from '../styles/StampID';
import { Trans, withTranslation } from 'react-i18next';
import XTrans from '../XTrans';
import ReactGA from 'react-ga';
import web3Handler from '../../utils/web3/Web3Handler';


class StampMintingExecutionComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activating: false, error: null, hash: null, receipt: null, chainId: props.chainId, account: props.account
        };
    }

    componentDidMount() {
        ReactGA.pageview('/activate-stamp/exec');
    }

    componentDidUpdate(prevProps) {
        if (prevProps.chainId !== this.props.chainId || prevProps.account !== this.props.account) {
            this.setState({
                chainId: this.props.chainId, account: this.props.account
            });
        }
    }

    componentWillUnmount() {
        if (this.mintingEvents) {
            this.mintingEvents.removeAllListeners();
        }
    }

    handleMintNow() {
        this.setState({
            activating: true, error: null, hash: null, receipt: null
        })
        const stampJson = this.props.stamp;
        const address = "0x" + this.props.wallet.getAddress().toString("hex");
        const privateKey = this.props.wallet.privateKey;
        // substr(2) removes 0x prefix from hex string
        const leaf = Buffer.from(stampJson.leaf.substring(2), "hex");
        // convert to array of byte arrays
        const proof = stampJson.proof.map(el => {
            return Buffer.from(el.substring(2), "hex")
        });
        const that = this;
        const web3 = web3Handler.getWeb3();
        web3.eth.getAccounts().then((accounts) => {
            const toAddress = accounts[0];

            const version = web3Handler.getVersionFrom(stampJson.stampId);
            // pack and hash transfer data (from merkle leaf token owner to active message sender)
            web3Handler.getUnitedNationStamp(version).methods
                .packAndHashTransferData(address, toAddress, stampJson.tokenId)
                .call({}, (error, result) => {
                    if (error) {
                        console.error("packAndHashTransferData", error);
                        this.setState({
                            error: error
                        });
                    } else {
                        this.setState({
                            sendingTx: true
                        })
                        // sign transfer data
                        const signature = web3.eth.accounts.sign(result, privateKey.toString('hex'));
                        // mint stamp
                        this.mintingEvents = web3Handler.getUnitedNationStamp(version).methods.mintClaimWithMerkleProof(
                            // the address derived from the stamp mnemonic
                            address, stampJson.tokenId, // transfer to the address of the active wallet
                            toAddress, // use the signature property of the signature object (r|s|v)
                            Buffer.from(signature.signature.substring(2), "hex"), leaf, proof).send({
                                // use the address selected in the web3 provider/extension
                                from: toAddress
                            });
                        // subscribe to events
                        this.mintingEvents.on('transactionHash', function (hash) {
                            console.log('on transactionHash', hash);
                            that.setState({
                                hash: hash
                            });
                        })
                            .on('confirmation', function (confirmationNumber, receipt) {
                                console.log('on confirmation', confirmationNumber, receipt);
                                that.setState({
                                    confirmationNumber: confirmationNumber, receipt: receipt
                                });
                            })
                            .on('receipt', function (receipt) {
                                console.log('on receipt', receipt);
                                that.setState({
                                    receipt: receipt
                                });
                                ReactGA.event({
                                    category: 'Minting', action: 'success'
                                });
                            })
                            .on('error', function (error, receipt) {
                                // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                                console.log('on error', error, receipt);
                                that.setState({
                                    error: error, receipt: receipt, activating: false
                                });
                                ReactGA.exception({
                                    description: 'Minting error', details: error, fatal: false
                                });
                            });
                    }
                });
        });
    }

    txRow() {
        const txHashText = this.state.hash ? <EthTx tx={this.state.hash} suffix={true} /> :
            <span><Trans>Waiting for hash...</Trans></span>;
        const loaderColor = '#009edb';
        return (<Row>
            <Col xs={4}><Trans>Transaction Hash</Trans></Col>
            <Col xs={1}>{this.state.hash ? <FontAwesomeIcon icon={faCheck} /> : <>
                <span className="">
                    <div style={{ width: 20 }}>
                        <ThreeDots color={loaderColor}
                            height={20} width={20} />
                    </div>
                </span>

            </>}</Col>
            <Col>{txHashText}</Col>
        </Row>);
    }

    receiptRow() {
        const receiptText = this.state.receipt ? <span><Trans>Stamp activated!</Trans>
            {/*
                <Link to={`${process.env.PUBLIC_URL}/UNCS1/${this.props.stamp.stampId}`}><Trans>View stamp</Trans></Link>
                */}
        </span> : <span><Trans>Receipt pending...</Trans></span>;
        const loaderColor = '#009edb';
        let tailSpinLoader = <span className=""><div style={{ width: 20 }}><TailSpin color={loaderColor}
            height={20} width={20} /></div></span>
        return (<Row>
            <Col xs={4}><Trans>Receipt</Trans></Col>
            <Col xs={1}>{this.state.receipt ? <FontAwesomeIcon icon={faCheck} /> : tailSpinLoader}</Col>
            <Col>{receiptText}</Col>
        </Row>);
    }

    confRow() {
        const transSingular = <Trans>First block confirmation received</Trans>;
        const confText = this.state.confirmationNumber ? (this.state.confirmationNumber === 1 ? transSingular :
            <span>{this.state.confirmationNumber} <Trans>block confirmations received</Trans></span>) :
            <span><Trans>Waiting for confirmations...</Trans></span>;
        const loaderColor = '#009edb';
        let tailSpinLoader = <span className=""><div style={{ width: 20 }}><TailSpin color={loaderColor}
            height={20} width={20} /></div></span>
        return (<Row>
            <Col xs={4}><Trans>Confirmations</Trans></Col>
            <Col xs={1}>{this.state.confirmationNumber ? <FontAwesomeIcon icon={faCheck} /> : tailSpinLoader}</Col>
            <Col>{confText}</Col>
        </Row>);
    }

    render() {
        // account -1: disconnected
        if (this.state.account === -1) {
            return <Redirect to={`${process.env.PUBLIC_URL}/wallet`} />;
        }
        // network -1: no provider
        if (this.state.chainId === -1) {
            return <Redirect to={`${process.env.PUBLIC_URL}/network`} />;
        }
        //
        const loaderColor = '#009edb';
        let ringsLoader = <Rings color={loaderColor} height={50} width={50} />
        let triangleLoader = <Triangle color={loaderColor} height={50} width={50} />;
        let gridLoader = <Grid color={loaderColor} height={50} width={50} />
        //
        let stampWasMintedSuccessfully = this.state.hash !== null && this.state.receipt !== null && this.state.confirmationNumber > 0;
        //
        let error = <div />;
        if (this.state.error) {
            error = <Container className="mt-2"><Alert variant="danger" className="pt-2">
                <Alert.Heading><Trans>The stamp could not be activated</Trans></Alert.Heading>
                <p><Trans>The activate operation returned the following error message:</Trans>
                    <span>{this.state.error.message}</span></p>
                <hr />
                <p className="mb-0">
                    <ul>
                        <li><Trans>When being asked to acknowledge the transaction in your wallet or metamask please
                            press the confirm button after checking all the parameters.</Trans></li>
                        <li><Trans>Ensure the availability of sufficient funds in your wallet to pay for the transaction
                            costs on the blockchain (gas costs).</Trans></li>
                        <li><Trans>Make sure to select a safe gas price when sending the transaction.</Trans></li>
                    </ul>
                </p>
            </Alert></Container>
        }
        let status = <div />;
        if (this.state.activating === true) {
            status = <Container className="mt-2">
                {this.txRow()}
                {this.receiptRow()}
                {this.confRow()}
            </Container>
        }

        let stampClass = "stamp-card-mint";
        if (stampWasMintedSuccessfully) {
            stampClass = "stamp-card-mint anim-glow";
        }

        return (<section>
            <Container data-aos="flip-left">
                <Row>
                    <Col>
                        <h3>
                            <XTrans components={{ stampid: <StampID stampId={this.props.stamp.stampId} /> }}>Activate <uncs /> <stampid /></XTrans>
                        </h3>
                    </Col>
                </Row>
                <Row>
                    <Col className="mint-card-deck-col" md={4} xs={12}>
                        <Container>
                            <CardDeck className="mint-card-deck">
                                <StampCard stamp={this.props.stamp} className={stampClass}
                                    version={web3Handler.getVersionFrom(this.props.stamp.stampId)}
                                    glow={stampWasMintedSuccessfully} linked={stampWasMintedSuccessfully} />
                            </CardDeck>
                        </Container>
                    </Col>
                    <Col md={8} xs={12}>
                        <Container hidden={this.state.activating === true || this.state.error}
                            className="mt-2 ml-2">
                            <h6><Trans>Congratulations!</Trans></h6>
                            <p>
                                <XTrans components={{ stampid: <StampID stampId={this.props.stamp.stampId} /> }}>
                                    You are about to activate the <uncs /> <stampid />.
                                    After the activation is completed the stamp will be automatically added to your
                                    wallet.
                                </XTrans>
                            </p>
                            <p><Trans>All parameters have already been verified and you posses all credentials
                                necessary to initiate the activation process on the blockchain.</Trans></p>
                            <p><Trans>After clicking the "Activate Now" button below your Ethereum wallet will be
                                asking you to confirm the transaction.</Trans></p>
                            <p><Trans>Once the transaction is confirmed and initiated the status on the blockchain
                                and the transaction progress will be shown on this page until completed.</Trans></p>
                        </Container>
                        <Container hidden={this.state.activating === true && !this.state.error}
                            className="mt-2 ml-2">
                            <Container className="pb-3 text-center">
                                <Button disabled={this.state.activating === true}
                                    onClick={this.handleMintNow.bind(this)}>
                                    <Trans>Activate now</Trans>
                                </Button>
                            </Container>
                        </Container>
                        <Container hidden={this.state.sendingTx !== true || this.state.error} className="mt-2 ml-2">
                            <h6><Trans>Activating!</Trans></h6>
                            <Container>
                                <Row>
                                    <Col md={1} xs={2} className="pl-1 pr-1">
                                        <div hidden={this.state.hash !== null}>{triangleLoader}</div>
                                        <div
                                            hidden={this.state.hash === null || this.state.receipt !== null}>{ringsLoader}</div>
                                        <div
                                            hidden={!(this.state.hash !== null && this.state.receipt !== null && this.state.confirmationNumber < 24)}>{gridLoader}</div>
                                    </Col>
                                    <Col md={11} xs={10}>
                                        <Alert variant="primary" hidden={this.state.hash !== null}>
                                            <FontAwesomeIcon icon={faExclamationCircle} /> <Trans>Confirm the
                                                transaction in your wallet!</Trans></Alert>
                                        <Alert variant="info"
                                            hidden={this.state.hash === null || this.state.receipt !== null}>
                                            <FontAwesomeIcon icon={faHourglassStart} /> <Trans>Waiting for the
                                                transaction receipt...</Trans></Alert>
                                        <Alert variant="info"
                                            hidden={!(this.state.hash !== null && this.state.receipt !== null && this.state.confirmationNumber < 24)}>
                                            <FontAwesomeIcon icon={faHourglassHalf} /> <Trans>Waiting for incoming
                                                block confirmations...</Trans></Alert>
                                    </Col>
                                </Row>
                            </Container>
                        </Container>
                        {error}
                        {status}
                        <Container hidden={!stampWasMintedSuccessfully} className="mt-3">
                            <p><Trans>Congratulations!</Trans>&nbsp;<Emoji symbol="🎉🎉🎉"
                                label="three party popper emojis" /></p>
                            <p><XTrans>Your <uncs /> has been successfully activated on the Ethereum
                                blockchain.</XTrans></p>
                            <p><Trans>The incoming block confirmations are showing that the transaction in which
                                your stamp was activated is being fully incorporated into the distributed
                                blockchain.</Trans></p>
                            <p><Trans>You can click on your stamp on the left to see more details.</Trans></p>
                            <p><Trans>You can always retrieve all stamps added to your wallet by following the
                                "Wallet" link at the top of this page.</Trans></p>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </section>);
    }
}

export default withTranslation()(StampMintingExecutionComponent);
