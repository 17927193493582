import React, { useEffect } from "react";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { withTranslation } from "react-i18next";
import ConnectWalletComponent from "./ConnectWalletComponent";

import ReactGA from "react-ga";
import { useCryptoStamp } from "src/utils/provider/stamp";
import web3Handler from "src/utils/web3/Web3Handler";

const WalletRedirectComponent = () => {
  const cryptoStamp = useCryptoStamp();

  useEffect(() => {
    ReactGA.pageview("/wallet-redirect");
  }, []);

  if (!web3Handler.isConnectedToBlockChain()) {
    return <Redirect to={`${process.env.PUBLIC_URL}/network`} />;
  } else if (
    web3Handler.isConnectedToBlockChain() && cryptoStamp.account === -1
  ) {
    return (
      <ConnectWalletComponent
        chainId={cryptoStamp.chainId}
        account={cryptoStamp.account}
      />
    );
  } else if (cryptoStamp.account && cryptoStamp.account !== -1) {
    return (
      <Redirect
        to={`${process.env.PUBLIC_URL}/wallet/${cryptoStamp.account}`}
      />
    );
  } else {
    // undef
    return <></>;
  }
};

export default withTranslation()(WalletRedirectComponent);
