import React from 'react'
import XTrans from '../XTrans';
import { Trans, useTranslation } from 'react-i18next'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faExternalLinkSquareAlt } from '@fortawesome/free-solid-svg-icons';
import {  Image, Col} from 'react-bootstrap';
import { CollectionSeries2 } from '../stamps/Series2/Collection';

export const Shop = (props) => {

    const { t } = useTranslation();

  return (
    <div className="container" data-aos="fade-up">
        <div className="section-title">
        <h2><Trans>Get stamps</Trans></h2>
        <p><XTrans>Read about how and where to buy <uncss/>.</XTrans></p>
        </div>

        <div className="row content">
        <div className="col-lg-6">
            <p>
            <XTrans><uncss/> are sold exclusively by the United Nations Postal Administration.</XTrans>
            </p>
            <p>
            <Trans>You may buy these stamps at:</Trans>
            </p>
            <ul>
            <li><i className="ri-check-double-line"></i> <Trans>The unstamps.org website.</Trans></li>
            <li><i className="ri-check-double-line"></i> <Trans>United Nations stamp shops at the United Nations Headquarters in New York, Geneva and Vienna.</Trans></li>
            </ul>
            <p>
                <a href={'https://unstamps.org/product-category/series/crypto/'} target="_blank" rel="noopener noreferrer" className="mr-1">
                    <Trans>Go to the unstamps.org webshop</Trans>
                    <FontAwesomeIcon icon={faExternalLinkSquareAlt} className="ml-2" />
                </a>
                {/* <a href={'https://unstamps.org/product-category/series/crypto/'} target="_blank" rel="noopener noreferrer" className="mr-1">
                    <Trans>Buy un stamp series 1</Trans>
                    <FontAwesomeIcon icon={faExternalLinkSquareAlt} className="ml-2" />
                </a> */}
            </p>
        </div>
        <div className="col-lg-6 pt-4 pt-lg-0">
            <div className="text-center">
            <a href={'https://unstamps.org/product-category/series/crypto/'} target="_blank" rel="noopener noreferrer">
                <Image src={'/images/assets/UNPA-crypto-shop.png'} 
                alt={t('An icon of a yellow shopping cart containing the brand icon of a UN crypto stamp')}
                style={{
                maxHeight:200
                }}/>
            </a>
            </div>    
        </div>
        </div>
        <div className="row content mt-4">
            <CollectionSeries2 />
        </div>
        <div className="row content">
            <Col>
            <h4><Trans>Trading</Trans></h4>
            <p>
                <XTrans>In case you wish to buy only the digital part of the <uncs/> from another participant you may also be interested in the trading options that exist for the <uncs/>.</XTrans>
            </p>
            <p className="text-center">
                <a href="#trade" className="btn-learn-more" onClick={props.handleScrollTrade}><Trans>Learn about trading options</Trans></a>
            </p>
            </Col>
        </div>
    </div>
  )
}
