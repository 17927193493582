import React from 'react';
import { Container, Alert } from 'react-bootstrap';
import InstallProviderComponent from './InstallProviderComponent';
import { Redirect } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
//import { faChrome, faFirefoxBrowser, faEthereum } from '@fortawesome/free-brands-svg-icons';
import { withTranslation, Trans } from 'react-i18next'
import XTrans from './XTrans';
import ReactGA from 'react-ga';
// import Web3 from 'web3';
import queryString from 'query-string';
import { CryptoStampContext } from '../utils/provider/stamp/Provider';
import web3Handler from '../utils/web3/Web3Handler';

class NetworkStatusComponent extends React.Component {

  static contextType = CryptoStampContext;
  constructor(props) {    
    super(props);
    this.state = {
      case: 'init',
    };
  }

  componentDidMount() {
    const { address } = queryString.parse(this.props.location.search);
    if(address) {
      this.setState({ address })
    }
    ReactGA.pageview(window.location.pathname);
  }  

  render() {
    let screen = "showInstallProvider";
    if (web3Handler.isConnectedToBlockChain()) {
      if (!web3Handler.isConnectionReady()) {
        // connected, but wrong network   
        screen = "showWrongNetwork"
      } else {
        // connected
        screen = "redirectToWallet"        
      }
    }

    if (screen === 'init') {
      return (
        <section>
        <Container>
          <p><Trans>Detecting Ethereum provider</Trans></p>
        </Container>
        </section>
      );
    } else if (screen === 'redirectToWallet') {
      // target will validate address
      return <Redirect to={`${process.env.PUBLIC_URL}/wallet/${this.state.address}`}/>;
    } else if (screen === 'showWrongNetwork') {
      return (
        <section>
        <Container>
          <Alert variant="danger">
            <Alert.Heading><FontAwesomeIcon icon={faExclamationTriangle} /> <Trans>Wrong Network Selected</Trans></Alert.Heading>
            <Container>
              <Trans>You are connected to the wrong Ethereum network.</Trans>
            </Container>
            <Container>
              <XTrans components={{network: process.env.REACT_APP_NETWORK_NAME}}>
                In your browser or metamask extension please select the network with the name '<network/>'.
              </XTrans>
            </Container>
          </Alert>
        </Container>
        </section>
      );
    } else if (screen === 'showInstallProvider') {
      return <InstallProviderComponent />
    }
  }
}

export default withTranslation()(NetworkStatusComponent);
