import React, { useRef } from "react";
import { Badge, Card } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { STAMP_URL_BASE } from "src/utils/web3/const";
import StampID from "src/components/styles/StampID";
import { Link } from "react-router-dom";

export const StampCardV2 = ({
  stamp,
  loadingJson,
  officeName,
  imageLink,
  order,
  linked,
  ...props
}) => {
  const { t } = useTranslation();

  const ref = useRef(null);

  const CardDetails = () =>
    loadingJson ? (
      <Card.Text>
        <span>
          <Trans>loading...</Trans>
        </span>
      </Card.Text>
    ) : (
      <>
        <Card.Text>
          <small>
            <Trans>Issuing Office</Trans> <Trans>{officeName}</Trans>
          </small>
        </Card.Text>
      </>
    );

  const CardImage = () => (
    <Card.Img
      variant="top"
      src={imageLink}
      alt={t(
        "UN crypto stamp with the UN building of the issuing office on the left and the associated SDG on the right.",
      )}
    />
  );

  return (
    <Card
      ref={ref}
      border="light"
      bg="light"
      text="dark"
      style={{ order: order }}
      className={props.className}
      key={stamp.stampId}
      data-aos={props.aos}
      data-aos-delay={props.aosDelay}
      data-aos-easing={props.aosEasing}
      data-aos-duration={props.aosDuration}
      data-aos-once="true"
    >
      {linked ? (
        <Link
          to={`${process.env.PUBLIC_URL}/${STAMP_URL_BASE[props.version]}/${
            stamp.stampId
          }`}
        >
          <CardImage />
        </Link>
      ) : (
        <CardImage />
      )}
      <Card.Body className="pt-4 pb-2 bg-secondary text-white">
        <Card.Title>
          {STAMP_URL_BASE[props.version]} <StampID stampId={stamp.stampId} />
        </Card.Title>
        <CardDetails />
      </Card.Body>
      <Card.Footer className="bg-secondary border-secondary">
        <Badge variant="light">
          <Trans>Token Id</Trans> {stamp.tokenId}
        </Badge>
      </Card.Footer>
    </Card>
  );
};
