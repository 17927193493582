/* 
 * Sourced from
 * https://reactrouter.com/web/guides/scroll-restoration
 * See App.js for effective usage
 */
import React from "react";
import { withRouter } from "react-router-dom";

class ScrollToTop extends React.Component {

    componentDidUpdate(prevProps) {        
        if(this.props.location.pathname !== prevProps.location.pathname || window.scrollY > 0) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        }
    }

    render() {
        return null;
    }
}

export default withRouter(ScrollToTop);