import React from 'react';
import { Button, Container} from 'react-bootstrap';
import { withTranslation, Trans } from 'react-i18next'
import XTrans from '../XTrans';
import { HowDoesItWork } from './HowDoesItWork';

import './WelcomeComponent.css'

import UNCSBrand from '../styles/UNCSBrand';
import { Link, NavLink } from 'react-router-dom';
import ReactGA from 'react-ga';
import { TradingPlatforms } from './TradingPlatforms';
import { Shop } from './Shop';

class WelcomeComponent extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
    this.myRefAbout = React.createRef();
    this.myRefShop = React.createRef();
    this.myRefTrade = React.createRef();
  }

  componentDidMount() {
    ReactGA.pageview(window.location.pathname);
  }

  scrollTo(el) {
    // console.log("scrollTo", el.offsetTop, window.innerHeight, el.height > window.innerHeight)
    const y = el.offsetTop - (window.innerHeight / 2) + 300;
    console.log('y', el.offsetTop, y);
    window.scrollTo(
      {
        top: y,
        left: 0,
        behavior: 'smooth'
      }
    );
  }

  handleScrollAbout(e) {
    e.preventDefault();
    this.scrollTo(this.myRefAbout.current);
  }

  handleScrollShop(e) {
    e.preventDefault();
    this.scrollTo(this.myRefShop.current);
  }

  handleScrollTrade(e) {
    e.preventDefault();
    this.scrollTo(this.myRefTrade.current);
  }

  officeImage(office) {
    return `./images/assets/Office-${office}.png`;
  }

  render() {
    return (
    <>

        {/* <ContractEvents /> */}


          <section id="hero" className="d-flex align-items-center pt-0">
            <div className="mainc container position-relative" data-aos="fade-up" data-aos-delay="100">
              <div className="row justify-content-center">
                <div className="col-xl-7 col-lg-9 text-center center-block">
                  <div className="welcome-logo ml-auto mr-auto"/>
                  <div>
                    <h1><span style={{fontWeight:500}}><UNCSBrand brand rotateLang={true}/></span></h1>
                    <h2><Trans>Connects a new generation of United Nations stamps with the blockchain</Trans></h2>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <Button className="mt-3 border-light bg-secondary" onClick={this.handleScrollAbout.bind(this)}>
                  <Trans>Get Started</Trans>
                </Button>
              </div>
              <div className="row icon-boxes">

                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="200">
                  <div className="icon-box">
                    <div className="icon">
                      <i className="bg-icon what-icon" />
                    </div>
                    <h4 className="title"><Link to={`.`} onClick={this.handleScrollAbout.bind(this)}><Trans>ABOUT</Trans></Link></h4>
                    <p className="description"><Trans>Discover the smart contract technology used to manage the digital collectibles on the blockchain.</Trans></p>
                  </div>
                </div>
        
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="300">
                  <div className="icon-box">
                    <div className="icon">
                      <i className="bg-icon shop-icon" />
                    </div>
                    <h4 className="title"><Link to={`.`} onClick={this.handleScrollShop.bind(this)}><Trans>BUY</Trans></Link></h4>
                    <p className="description"><XTrans>Read about how and where to buy <uncss/>.</XTrans></p>
                  </div>
                </div>
        
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="400">
                  <div className="icon-box">
                    <div className="icon">
                      <i className="bg-icon activate-icon" />
                    </div>
                    <h4 className="title"><NavLink to={`${process.env.PUBLIC_URL}/activate-stamp`}><Trans>ACTIVATE</Trans></NavLink></h4>
                    <p className="description"><XTrans>After you buy a <uncs/> it needs to be activated on the blockchain.</XTrans></p>
                  </div>
                </div>
        
                <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0" data-aos="zoom-in" data-aos-delay="500">
                  <div className="icon-box">
                    <div className="icon">
                      <i className="bg-icon trade-icon" />
                    </div>
                    <h4 className="title"><Link to={`.`} onClick={this.handleScrollTrade.bind(this)}><Trans>TRADE</Trans></Link></h4>
                    <p className="description"><XTrans>The <uncss/> conform to established standards and can be safely traded on third-party platforms.</XTrans></p>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section ref={this.myRefAbout} id="about" className="about spaced-section">
            <HowDoesItWork handleScrollAbout={this.handleScrollAbout.bind(this)} />
          </section>
          
          <section ref={this.myRefShop} id="shop" className="about spaced-section">
            <Shop handleScrollTrade={this.handleScrollTrade.bind(this)}/>
          </section>
    
          <section ref={this.myRefTrade} id="trade" className="about spaced-section">
            <TradingPlatforms />
          </section>

          <Container>
            <small>
            Credit: This landing page is based on a design created by <a href="https://bootstrapmade.com/" target="_blank" rel="noopener noreferrer">BootstrapMade</a>.
            </small>
          </Container>
     </>);
  }
}

export default withTranslation()(WelcomeComponent);
