import React from 'react';
import './StampID.css';
import { Link } from 'react-router-dom';
import web3Handler from '../../utils/web3/Web3Handler';

class StampID extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if(this.props.withLink === true) {
            const baseURL = web3Handler.getDecodedStamp(this.state.searchFieldStampId).stampBaseURL;
            return <Link to={`${process.env.PUBLIC_URL}/${baseURL}/${this.props.stampId}`}><span className="StampID">{this.props.stampId}</span></Link>
        } else {
            return <span className="StampID">{this.props.stampId}</span>
        }
    }
}

export default StampID;