import {Card, Col, Container, Image, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faGlobeAfrica, faIdBadge} from "@fortawesome/free-solid-svg-icons";
import {Trans, useTranslation} from "react-i18next";
import StampID from "src/components/styles/StampID";
import {assetsByVersion} from "src/components/stamps/const";
import {VERSIONS} from "src/utils/web3/const";
import {faEthereum} from "@fortawesome/free-brands-svg-icons";
import StampDetailsView from "src/components/stamps/Series1/StampDetailsView";
import React from "react";

export const StampV1Display = ({imageStampSrc, officeName, location, altOfficeIcon, ...props}) => {

    const {t} = useTranslation();

    return <Row>
        <Col xs={12} md={12} lg={6} className="pl-1 pr-1 mb-3">
            <Col
                style={{
                    borderLeft: "1px solid rgba(0, 0, 0, 0.125)",
                    borderTop: "1px solid rgba(0, 0, 0, 0.125)",
                    borderBottom: "0.5px solid rgba(0, 0, 0, 0.125)",
                    borderRight: "0.5px solid rgba(0, 0, 0, 0.125)",
                    borderRadius: 5,
                    borderBlockStyle: "solid",
                    boxShadow: "5px 5px 5px #555",
                }}
                className="text-center pl-0 pr-0"
            >
                <Container className="p-0">
                    <Card.Header
                        className="bg-dark text-white"
                        as="h5"
                        aria-label={t("Card header describing the UN crypto stamp")}
                    >
                        UNCS1
                    </Card.Header>
                </Container>
                <Container className="p-4">
                    <Container className="pt-4">
                        <div className="stamp">
                            <img
                                alt={t(
                                    "United Nations crypto stamp with the United Nations building of the issuing office on the left and the associated Goal on the right.",
                                )}
                                src={imageStampSrc}
                                style={{width: "100%", height: "100%"}}
                            />
                        </div>
                    </Container>
                </Container>
                <Container
                    className="p-4 stamp-properties"
                    aria-label={t(
                        "Properties grid describing the United Nations crypto stamp",
                    )}>
                    <Row>
                        <Col className="prop-col-0 p-2" xs={1}>
                            <span
                                role="img"
                                id="aria-label-stamp-id"
                                aria-label={t(
                                    "Icon of an ID badge representing the Stamp ID",
                                )}
                            />
                            <FontAwesomeIcon
                                icon={faIdBadge}
                                size="lg"
                                aria-labelledby="aria-label-stamp-id"
                            />
                        </Col>
                        <Col className="prop-col-1 text-center" xs={5}>
                            <div
                                style={{
                                    display: "flex",
                                    height: "100%",
                                    justifyContent: "center",
                                    alignContent: "flex-end",
                                }}
                                className="pt-1">
                                <Trans>Stamp ID</Trans>
                            </div>
                        </Col>
                        <Col className="prop-col-2">
                            <div style={{
                                    display: "flex",
                                    height: "100%",
                                    justifyContent: "center",
                                    alignContent: "flex-end",
                                }}
                                className="pt-0">
                                <StampID stampId={props.state.stampJson.stampId}/>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="prop-col-0 p-2" xs={1}>
                            <span role="img"
                                id="aria-label-globe"
                                aria-label={t(
                                    "Icon of globe representing the issuing office",
                                )}
                            />
                            <FontAwesomeIcon
                                icon={faGlobeAfrica}
                                aria-labelledby="aria-label-globe"
                                size="lg"
                            />
                        </Col>
                        <Col className="prop-col-1 text-center" xs={5}>
                            <div style={{
                                    display: "flex",
                                    height: "100%",
                                    justifyContent: "center",
                                    alignContent: "flex-end",
                                }}
                                className="pt-1">
                                <Trans>Issuing Office</Trans>
                            </div>
                        </Col>
                        <Col className="prop-col-2" xs={2}>
                            <img
                                alt={altOfficeIcon}
                                src={`./images/assets/Office-${location}.png`}
                                style={{height: 36}}
                            />
                        </Col>
                        <Col className="prop-col-2" xs={4}>
                            <div style={{
                                    display: "flex",
                                    height: "100%",
                                    justifyContent: "center",
                                    alignContent: "flex-end",
                                }}
                                className="pt-1">
                                <Trans>{officeName}</Trans>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="prop-col-0 p-2" xs={1}>
                            <Image alt={t("Black icon of SDG logo")}
                                src="./images/assets/sdgsLogoBW.png"
                                className="sdg-wheel"
                            />
                        </Col>
                        <Col className="prop-col-1 text-center" xs={5}>
                            <div style={{
                                    display: "flex",
                                    height: "100%",
                                    justifyContent: "center",
                                    alignContent: "flex-end",
                                }}
                                className="pt-1">
                                <Trans>SDG</Trans>
                            </div>
                        </Col>
                        <Col className="prop-col-2" xs={2}>
                            <Image aria-label={t("Icon of the associated SDG.")}
                                src={assetsByVersion(props.state.version).svgIcon(
                                    props.state.stampJson.designNumber,
                                )}
                                style={{width: "auto", height: 24}}
                            />
                        </Col>
                        <Col className="prop-col-2" xs={4}>
                            <div style={{
                                    display: "flex",
                                    height: "100%",
                                    justifyContent: "center",
                                    alignContent: "flex-end",
                                }}
                                className="pt-1">
                                <Trans>Goal</Trans>
                                {props.state.stampJson.designNumber}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="prop-col-0 p-2" xs={1}>
                            <span role="img"
                                id="aria-label-ethereum"
                                aria-label={t("Icon of the ethereum blockchain")}/>
                            <FontAwesomeIcon
                                onClick={props.onEtherClick}
                                aria-labelledby="aria-label-ethereum"
                                data-aos="zoom-in"
                                data-aos-delay="50"
                                icon={faEthereum}
                                size="lg"
                                spin={props.state.loadingOnChainStatus}
                            />
                        </Col>
                        <Col className="prop-col-1 text-center" xs={5}>
                            <div style={{
                                    display: "flex",
                                    height: "100%",
                                    justifyContent: "center",
                                    alignContent: "flex-end",
                                }}
                                className="pt-1"
                            >
                                <Trans>On Chain Status</Trans>
                            </div>
                        </Col>
                        <Col className="prop-col-2">
                            <div style={{
                                    display: "flex",
                                    height: "100%",
                                    justifyContent: "center",
                                    alignContent: "flex-end",
                                }}
                                className="pt-1"
                            >
                                {props.onChainStatus}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Col>
        </Col>
        <Col
            style={{
                alignItems: "center",
                borderColor: "black",
            }}
            xs={12}
            md={12}
            lg={6}
            className="pl-1 pr-1"
        >
            <StampDetailsView
                number={props.state.stampJson.designNumber}
                history={props.history}
                version={VERSIONS.V1}
            />
        </Col>
    </Row>;
}
