import Base58 from "base-58";
import {
  VERSION_1_NUMBER_OF_VARIATION,
  VERSION_2_NUMBER_OF_VARIATION,
  STAMP_BOUNDARY,
  VERSIONS,
  NUMBER_OF_DESIGNS,
  STAMP_PREFIX,
  STAMP_URL_BASE,
  NUMBER_OF_VARIATION,
  NUMBER_OF_OFFICE,
  getStampDesignIdentifierMapper
} from "./const";

export default class StampEthUtil {
  static OFFICES = ["Geneva", "NewYork", "Vienna"];
  static MAX_TOKENS = ~~process.env.REACT_APP_MAX_TOKENS;
  static MAX_TOKENS_V2 = ~~process.env.REACT_APP_MAX_TOKENS_2;

  static getStampVersionNumber(mask) {
    if (mask) {
      if (mask >= STAMP_BOUNDARY[VERSIONS.V2].LOWER_BOUND && mask <= STAMP_BOUNDARY[VERSIONS.V2].HIGHER_BOUND) {
        return VERSIONS.V2;
      }

      if (mask <= STAMP_BOUNDARY[VERSIONS.V1].HIGHER_BOUND) {
        return VERSIONS.V1;
      }
      /// if new version comes new condition should put here
    }
    return VERSIONS.INVALID;
  }

  static getStampDesignIdentifier(tokenId, mask, valid) {

    //version 1
    if (this.getStampVersionNumber(mask) === VERSIONS.V1) {

      const designNumber = ((tokenId % NUMBER_OF_DESIGNS[VERSIONS.V1]) + 1);
      return valid === true ? {
        withPrefix: getStampDesignIdentifierMapper(VERSIONS.V1, designNumber),
        designNumber: designNumber
      } : {
        withPrefix: null,
        designNumber: null
      };
    }

    //version 2
    if (this.getStampVersionNumber(mask) === VERSIONS.V2) {

      const designNumber = tokenId % 50 === 0 ? 0 : ((tokenId % NUMBER_OF_DESIGNS[VERSIONS.V2]) + 1);
      
      return valid === true ? {
        withPrefix: getStampDesignIdentifierMapper(VERSIONS.V2, designNumber),
        designNumber: designNumber
      } : {
        withPrefix: null,
        designNumber: null
      };
    }

    return null;

  }

  static getOffice(version, tokenId) {
    if (version === VERSIONS.V1) {
      return this.OFFICES[tokenId % NUMBER_OF_OFFICE] 
    }
    
    if (version === VERSIONS.V2) {
      return this.OFFICES[parseInt(tokenId / parseInt(this.MAX_TOKENS_V2 / NUMBER_OF_OFFICE))];
    }
  }

  static checkIfValid(version, tokenId, mask) {
    if (version === VERSIONS.V1) {
      return ((tokenId % VERSION_1_NUMBER_OF_VARIATION) + STAMP_BOUNDARY[VERSIONS.V1].LOWER_BOUND === mask) &&  tokenId < this.MAX_TOKENS;
    }
    
    if (version === VERSIONS.V2) {
      // debugger;
      return ((tokenId % VERSION_2_NUMBER_OF_VARIATION) + STAMP_BOUNDARY[VERSIONS.V2].LOWER_BOUND === mask) && tokenId < this.MAX_TOKENS_V2;
    }
  }


  static decodeStampId(stampId) {
    const buffer = Base58.decode(stampId);
    const tokenId = Buffer.from([
      0x00,
      buffer[1],
      buffer[2],
      buffer[3],
    ]).readInt32BE(0);

    const mask = buffer[0] & 0xff;
    const version = this.getStampVersionNumber(mask);
    const valid = this.checkIfValid(version, tokenId, mask)
    const office = valid === true ? this.getOffice(version, tokenId) : null;

    const designIdentifier = this.getStampDesignIdentifier(tokenId, mask, valid); // D1, S1, UNICORN

    return {
      tokenId,
      mask,
      valid,
      prefix: STAMP_PREFIX[version],
      stampBaseURL: STAMP_URL_BASE[version],
      version,
      stampJson: {
        tokenId,
        stampId,
        designIdentifier: designIdentifier && designIdentifier.withPrefix,
        designNumber: designIdentifier && designIdentifier.designNumber,
        office,
        location: office,
      },
    };
  }

  static getBase58StampIdForTokenId(id, version) {
    const maskByte = (id % NUMBER_OF_VARIATION[version]) + STAMP_BOUNDARY[version].LOWER_BOUND;
    const mask = this.getIntBE([maskByte, 0, 0, 0], 0);
    const buf = Buffer.allocUnsafe(4);
    buf.writeUInt32BE(mask + id);
    return Base58.encode(buf);
  }

  static getIntBE(data, offset) {
    let i = offset;
    const b0 = data[i++] & 0xff;
    const b1 = data[i++] & 0xff;
    const b2 = data[i++] & 0xff;
    const b3 = data[i++] & 0xff;
    return (b0 << 24) + (b1 << 16) + (b2 << 8) + (b3 << 0);
  }
}
