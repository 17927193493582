import React, { useState } from "react";
import { Button, Container, Card } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import ReactCardFlip from "react-card-flip";
import { SDG_TEXTS, assetsByVersion } from "../const";
import { VERSIONS } from "src/utils/web3/const";

const StampDetailsView = (props) => {
  const [state, setState] = useState({
    flipped: false,
    rotation: 0,
  });

  const { t } = useTranslation();

  const { number } = props;

  const _sdgTexts = SDG_TEXTS.map((item) => {
    return {
      name: <Trans>{item.name}</Trans>,
      ariaLabelImage: t(item.ariaLabelImage),
      color: item.color,
      textcolor: item.textcolor,
      linkword: item.linkword,
    };
  });

 

  const cardTitle = "Sustainable Development Goal";

  console.log(_sdgTexts[number-1].color);
  const styles = {
    flipcard: {
      //backgroundColor:_sdgTexts[number-1].color,
      padding: 5,
      margin: 0,
      width: "100%",
      color: _sdgTexts[number-1].textcolor,
      perspective: 1000,
    },
    flipcardFront: {
      backgroundColor: _sdgTexts[number-1].color,
      padding: 1,
      margin: 0,
      backgroundImage: state.flipped ? "" : assetsByVersion(VERSIONS.V1).backgroundImageURL(number),
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: 0,
      backgroundClip: "padding-box",
      minHeight: 545,
      width: "100%",
      color: _sdgTexts[number-1].textcolor,
    },
  };

  return (
    <Card
      style={styles.flipcard}
      onMouseEnter={() => setState({ ...state, flipped: true })}
      onMouseLeave={() => setState({ ...state, flipped: false })}
    >
      <ReactCardFlip isFlipped={state.flipped} flipDirection="horizontal">
        <Container style={styles.flipcardFront}>
          <Card.Img
            style={{
              width: 200,
              height: 200,
              position: "absolute",
              bottom: 10,
              left: 10,
            }}
            alt={_sdgTexts[number-1].ariaLabelImage}
            src={assetsByVersion(VERSIONS.V1).iconPath(number)}
          />
        </Container>
        <Container
          onClick={() => {
            window.open(assetsByVersion(VERSIONS.V1).infoLink(number - 1));
          }}
          style={{
            minHeight: 545,
            width: "100%",
            paddingTop: 10,
            backgroundColor: _sdgTexts[number-1].color,
            cursor: "pointer",
          }}
        >
          <Card.Title>
            <h4>
              <Trans>{cardTitle}</Trans>
            </h4>
          </Card.Title>
          <Card.Title>
            <h1>{number}</h1>
          </Card.Title>
          <Card.Body>
            <Card.Text>{_sdgTexts[number-1].name}</Card.Text>
            <Card.Body
              style={{
                margin: 0,
                padding: 0,
                alignContent: "center",
                alignItems: "center",
                textAlign: "center",
                position: "absolute",
                bottom: 20,
                width: "87%",
              }}
            >
              <Button
                variant="link"
                style={{
                  outlineColor: _sdgTexts[number-1].textcolor,
                  outlineStyle: "solid",
                  color: _sdgTexts[number-1].textcolor,
                  width: "100%",
                  backgroundColor: _sdgTexts[number-1].color,
                  margin: 0,
                  padding: 0,
                }}
                onClick={() => {
                  window.open(
                    `https://www.un.org/sustainabledevelopment/${_sdgTexts[number-1].linkword}`,
                  );
                }}
              >
                <Trans>More Info</Trans>
              </Button>
            </Card.Body>
          </Card.Body>
        </Container>
      </ReactCardFlip>
    </Card>
  );
};

export default StampDetailsView;
