import { Jumbotron as JumbotronBS } from 'react-bootstrap';

/**
 * Jumbotron has been removed from react-bootstrap v5.0.0
 * This is a custom Jumbotron component later to be replaced with a custom component
 * while we upgrade to react-bootstrap v5.0.0
 */
export const Jumbotron = ({ children, props }) => (

    <JumbotronBS {...props}>
        {children}
    </JumbotronBS>
);