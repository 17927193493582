import { InstructionAlert } from "./InstructionAlert";
import React, { useEffect, useState, useCallback } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { withTranslation, Trans, useTranslation } from "react-i18next";
import { ThreeDots } from "react-loader-spinner";
import { Redirect } from "react-router-dom";
import Truncate from "src/components/Truncate";
import ReactGA from "react-ga";
import { useCryptoStamp } from "src/utils/provider/stamp";
import web3Handler from "src/utils/web3/Web3Handler";
import { BASE_URL } from "src/utils/const";
import { assetsByVersion } from "./const";
import EventHistory from "./EventHistory";
import { AlreadyExistsError } from "src/components/stamps/AlreadyExistsError";
import { StampV1Display } from "src/components/stamps/Series1/StampV1Display";
import { VERSIONS } from "src/utils/web3/const";
import { StampV2Display } from "src/components/stamps/Series2/StampV2Display";

const StampDisplayComponent = (props) => {
  const cryptoStamp = useCryptoStamp();
  const displayError = props.error;
  const { t } = useTranslation();

  const getInitialStateWithStampId = (parameterStampId, chainId, account) => {
    const decodedStampId = web3Handler.getDecodedStamp(parameterStampId);
    // console.log("decodedStampId", decodedStampId)
    return {
      version: decodedStampId.version,
      stampPrefix: decodedStampId.prefix,
      stampBaseURL: decodedStampId.stampBaseURL,
      parameterValid: decodedStampId.valid,
      validStampId: decodedStampId.valid ? parameterStampId : null,
      validTokenId: decodedStampId.valid ? decodedStampId.tokenId : null,
      stampIdMask: decodedStampId.mask,
      loadingOnChainStatus: !!chainId,
      connected: !!chainId,
      stampJson: decodedStampId.stampJson,
      exists: false,
      existsStatus: "loading",
      you: false,
      chainId,
      account,
    };
  };

  const [state, setState] = useState(
    getInitialStateWithStampId(
      props.match.params.stampId,
      cryptoStamp.chainId,
      cryptoStamp.account,
    ),
  );

  const loadStampOnChainDetails = useCallback(
    (parameterStampId, chainId, account) => {
      console.log(
        "loadStampOnChainDetails",
        parameterStampId,
        chainId,
        account,
        state.validTokenId,
      );
      let stateTemp = getInitialStateWithStampId(
        parameterStampId,
        chainId,
        account,
      );

      setState({ ...state, loadingOnChainStatus: true });

      if (chainId !== -1 && stateTemp.validTokenId !== null) {
        web3Handler
          .getUnitedNationStamp(state.version)
          .methods.exists(stateTemp.validTokenId)
          .call()
          .then((exists) => {
            if (exists === true) {
              console.log("exists", parameterStampId);
              web3Handler
                .getUnitedNationStamp(state.version)
                .methods.ownerOf(stateTemp.validTokenId)
                .call()
                .then((owner) => {
                  console.log("owner", owner);
                  // exists
                  setState({
                    ...state,
                    you: owner === account,
                    owner,
                    exists: true,
                    existsStatus: "owned_by",
                    loadingOnChainStatus: false,
                  });
                });
            } else {
              console.log("doesn't exist", parameterStampId);
              // doesn't exist
              setState({
                ...state,
                exists: false,
                existsStatus: "not_activated",
                loadingOnChainStatus: false,
              });
            }
          });
      } else {
        setState({
          ...state,
          exists: false,
          existsStatus: "not_connected",
          loadingOnChainStatus: false,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.validTokenId],
  );

  useEffect(() => {
    ReactGA.pageview(`/view-stamp/${state.stampBaseURL}`);
  }, [state.stampBaseURL]);

  useEffect(() => {
    if (web3Handler.isConnectedToBlockChain()) {
      loadStampOnChainDetails(
        props.match.params.stampId,
        cryptoStamp.chainId,
        cryptoStamp.account,
      );
    } else {
      setState({
        ...state,
        existsStatus: "not_connected",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.stampId, cryptoStamp.chainId, cryptoStamp.account]);

  const imageName = (location, designIdentifier) => {
    return assetsByVersion(state.version).stampImage(designIdentifier, location);
  };

  const officeName = (office) => {
    let name;
    switch (office) {
      case "NewYork":
        name = "New York";
        break;
      default:
        name = office;
        break;
    }
    return name;
  };

  const onEtherClick = (e) => {
    loadStampOnChainDetails(
      props.match.params.stampId,
      props.chainId,
      props.account,
    );
  };

  const altOfficeIcon = (office) => {
    switch (office) {
      case "Geneva":
        return t("Icon of the UN building in Geneva");
      case "NewYork":
        return t("Icon of the UN building in New York");
      case "Vienna":
        return t("Icon of the UN building in Vienna");
      default:
        return "";
    }
  };

  const onChainStatus = () => {
    switch (state.existsStatus) {
      case "loading":
        return cryptoStamp.chainId ? (
          <i>
            <Trans>Loading ...</Trans>
          </i>
        ) : (
          <i>
            <small>Unknown, not connected</small>
          </i>
        );
      case "not_connected":
        return (
          <i>
            <Trans>Not connected</Trans>
          </i>
        );
      case "not_activated":
        return (
          <i>
            <Trans>Not activated</Trans>
          </i>
        );
      case "owned_by":
        let ownerText =
          state.you === true ? (
            <Trans>you</Trans>
          ) : (
            Truncate.shortWallet(state.owner)
          );
        return (
          <span>
            <Trans>Owned by</Trans>{" "}
            <Link
              to={`${BASE_URL}/wallet/${state.owner}`}
              className="text-info text-capitalize"
            >
              {ownerText}
            </Link>
          </span>
        );
      default:
        return "";
    }
  };

  const imageStampSrc = imageName(
    state.stampJson.location,
    state.stampJson.designIdentifier,
  );

  if (!state.parameterValid) {
    return <Redirect to={`${BASE_URL}/`} />;
  }

  if (state.loadingJson) {
    return (
      <ThreeDots
        style={{
          position: "absolute",
          left: "50%",
          top: "20%",
          transform: "translate(-50%, -20%)",
        }}
        color="#00BFFF"
        height={100}
        width={100}
        timeout={3000} //3 secs
      />
    );
  }

  return (
    <section data-aos="zoom-out" data-aos-delay="100">
      <Container className="">
        <InstructionAlert existsStatus={state.existsStatus} />
        {displayError === "exists-already" && <AlreadyExistsError />}

        {state.version === VERSIONS.V1 ? (
          <StampV1Display
            state={state}
            imageStampSrc={imageStampSrc}
            altOfficeIcon={altOfficeIcon(state.stampJson.location)}
            location={state.stampJson.location}
            officeName={officeName(state.stampJson.location)}
            onEtherClick={onEtherClick.bind(this)}
            onChainStatus={onChainStatus()}
            history={props.history}
          />
        ) : (
          <StampV2Display
            imageStampSrc={imageStampSrc}
            state={state}
            onChainStatus={onChainStatus()}
            onEtherClick={onEtherClick.bind(this)}
            location={state.stampJson.location}
            altOfficeIcon={altOfficeIcon(state.stampJson.location)}
            officeName={officeName(state.stampJson.location)}
          />
        )}
      </Container>

      <EventHistory
        version={state.version}
        validTokenId={state.validTokenId}
        existsStatus={state.existsStatus}
        exists={state.exists}
        stampJson={state.stampJson}
        you={state.you}
        stampId={props.match.params.stampId}
        loadStampOnChainDetails={loadStampOnChainDetails}
      />
    </section>
  );
};

export default withTranslation()(StampDisplayComponent);
