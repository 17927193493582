import React from "react";
import WelcomeComponent from "../components/welcome/WelcomeComponent.js";
import WalletComponent from "../components/wallet/WalletComponent";
import StampDisplayComponent from "../components/stamps/StampDisplayComponent";
import NetworkStatusComponent from "../components/NetworkStatusComponent";
import WalletRedirectComponent from "../components/wallet/WalletRedirectComponent";
import StampMintingPreparationComponent from "../components/stamps/StampMintingPreparationComponent";
import InstructionsComponent from "../components/InstructionsComponent";
import BackgroundInfoComponent from "../components/BackgroundInfoComponent";
import BehindTheScenes from "../components/BehindTheScenes";

import { Switch, Route } from "react-router-dom";
import { Series1 } from "../components/stamps/Series1/Series1.js";

export const Routes = () => {



  return (
    <Switch>
      <Route
        path={`${process.env.PUBLIC_URL}/start`}
        component={InstructionsComponent}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/activate-stamp`}
        render={(props) => <StampMintingPreparationComponent {...props} />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/network`}
        render={(props) => <NetworkStatusComponent {...props} />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/wallet/:address`}
        render={(props) => <WalletComponent {...props} />}
      />
      <Route path={`${process.env.PUBLIC_URL}/wallet`}>
        <WalletRedirectComponent />
      </Route>

      <Route
        path={`${process.env.PUBLIC_URL}/background`}
        component={BackgroundInfoComponent}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/un-stamp-series1`}
        component={Series1}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/behind-the-scenes`}
        component={BehindTheScenes}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/UNCS1/:stampId`}
        render={(props) => <StampDisplayComponent {...props} />}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/UNCS2/:stampId`}
        render={(props) => <StampDisplayComponent {...props} />}
      />

      <Route path={`${process.env.PUBLIC_URL}/`} component={WelcomeComponent} />
    </Switch>
  );
};
