import React from 'react';
import { Row, Col, Container, Image } from 'react-bootstrap';
import { Trans, withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookSquare, faFlickr, faInstagram, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';

class FooterComponent extends React.Component {
    
    constructor(props) {
      super(props);
      this.state = {};
    }

    render() {     
        const { t } = this.props;   
        return (
            <footer className="footer mt-auto pt-4">
                {/*
                <Container fluid className="pt-4">
                    <Container>
                        <h6>Background</h6>
                    </Container>
                </Container>
                */}
                <Container fluid className="bg-secondary pt-4 text-white">
                    <Container>
                        <h6><Trans>Contact Us</Trans></h6>
                        <Row>
                            <Col xs={12} md={6}>
                                <address style={{lineHeight:'1rem'}}>
                                    <small>
                                    <strong><Trans>UNITED NATIONS HEADQUARTERS, NEW YORK CITY</Trans></strong><br/><br/>
                                    United Nations Postal Administration<br/>
                                    P.O. Box 5900, Grand Central Station<br/>
                                    New York, N.Y. 10163-5900<br/><br/>
                                    <Trans>Tel.</Trans>: 1-212-963-7684 or 1-800-234-UNPA (U.S. toll-free)<br/>
                                    <Trans>Fax</Trans>: 1-917-367-1400<br/><br/>
                                    <Trans>Email</Trans>: unpanyinquiries@un.org<br/>
                                    </small>
                                </address> 
                            </Col>
                            <Col xs={12} md={6}>
                                <address style={{lineHeight:'1rem'}}>
                                    <small>
                                    <strong><Trans>UNPA - EUROPEAN OFFICE</Trans></strong><br/>
                                    <Trans>Please contact the Vienna office at:</Trans><br/><br/>
                                    Postverwaltung der Vereinten Nationen<br/>
                                    Internationales Zentrum Wien<br/>
                                    Postfach 900<br/>
                                    1400 Wien – Vereinte Nationen<br/>
                                    Österreich<br/><br/>
                                    <Trans>Call: Monday – Thursday 10:00 a.m. to 3:00 p.m.</Trans><br/>
                                    +43 (1) 26060-4032 or -4025 <Trans>German + English</Trans><br/>
                                    +43 (1) 26060-4020 <Trans>French</Trans><br/>
                                    +43 (1) 26060-4025 <Trans>Spanish</Trans><br/>
                                    +43 (1) 26060-4022 <Trans>Italian</Trans><br/>
                                    <Trans>Fax</Trans>: +43 (1) 26060-5825<br/><br/>
                                    <Trans>Email</Trans>: UNPA-Europe@un.org<br/>
                                    </small>
                                </address> 
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <small>
                                Created in collaboration with the United Nations Office of Information and Communications Technology (UN OICT).&nbsp;
                                Visit the website of UN OICT at&nbsp;
                                <a href="https://unite.un.org/" title="UN OICT" target="_blank" rel="noopener noreferrer" className="text-white">
                                    https://unite.un.org/
                                </a>.
                                </small>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="pr-0">
                                <small>
                                &copy; {new Date().getFullYear()} UNITED NATIONS POSTAL ADMINISTRATION. ALL RIGHTS RESERVED.
                                </small>
                            </Col>                
                        </Row>
                    </Container>
                </Container>
                <Container fluid className="bg-dark footer-un2 p-4">
                    <Container>
                        <Row>
                            <Col md={12}>
                                <div className="float-left footer-un-brand">
                                    <a href="https://www.un.org/en/" title="Home" target="_blank" rel="noopener noreferrer">
                                        <Image src={`./images/assets/logo-en-reverse.svg`} alt={t('Logo of the United Nations')} />
                                    </a>
                                </div>
                                <div className="float-right social-media" role="navigation">
                                    <ul className="nav list-inline">
                                        <li className="list-inline-item facebook">
                                            <a href="https://www.facebook.com/unitednations" target="_blank" rel="noopener noreferrer" aria-label={t('Link to Facebook')}>
                                            <span role="img" id="footer-label-facebook" aria-label={t('Facebook logo')} />                                            
                                            <FontAwesomeIcon icon={faFacebookSquare} size="lg" style={{color:'#c4c4c4'}} aria-describedby="footer-label-facebook"/>
                                            </a></li>
                                        <li className="list-inline-item twitter">
                                            <a href="http://twitter.com/un" target="_blank" rel="noopener noreferrer" aria-label={t('Link to Twitter')}>
                                            <span role="img" id="footer-label-twitter" aria-label={t('Twitter logo')} />
                                            <FontAwesomeIcon icon={faTwitter} size="lg" style={{color:'#c4c4c4'}} aria-describedby="footer-label-twitter"/>
                                            </a></li>
                                        <li className="list-inline-item youtube">
                                            <a href="http://www.youtube.com/unitednations" target="_blank" rel="noopener noreferrer" aria-label={t('Link to Youtube')}>
                                            <span role="img" id="footer-label-youtube" aria-label={t('Youtube logo')} />
                                            <FontAwesomeIcon icon={faYoutube} size="lg" style={{color:'#c4c4c4'}} aria-describedby="footer-label-youtube"/>
                                            </a></li>
                                        <li className="list-inline-item flickr">
                                            <a href="http://www.flickr.com/photos/un_photo/" target="_blank" rel="noopener noreferrer" aria-label={t('Link to Flickr')}>
                                            <span role="img" id="footer-label-flickr" aria-label={t('Flickr logo')} />
                                            <FontAwesomeIcon icon={faFlickr} size="lg" style={{color:'#c4c4c4'}} aria-describedby="footer-label-flickr"/>  
                                            </a></li>
                                        <li className="list-inline-item instagram">
                                            <a href="http://instagram.com/unitednations" target="_blank" rel="noopener noreferrer" aria-label={t('Link to Instagram')}>
                                            <span role="img" id="footer-label-instagram" aria-label={t('Instagram logo')} />
                                            <FontAwesomeIcon icon={faInstagram} size="lg" style={{color:'#c4c4c4'}} aria-describedby="footer-label-instagram"/>  
                                            </a></li>					
                                    </ul> 
                                </div>
                            </Col>
                        </Row>
                        <Row className="footer-links">
                            <div className="divider"></div>
                            <Col md={12}>
                                <div className="float-right flip">
                                    <div id="block-menu-menu-footer-links" className="block block-menu" role="navigation">
                                        <ul className="nav list-inline">
                                            <li className="list-inline-item ">
                                                <a href="https://www.un.org/en/contact-us/" target="_blank" rel="noopener noreferrer">Contact</a></li>
                                            <li className="list-inline-item ">
                                                <a href="https://www.un.org/en/sections/about-website/copyright/" target="_blank" rel="noopener noreferrer">Copyright</a></li>
                                            <li className="list-inline-item ">
                                                <a href="https://www.un.org/en/sections/about-website/fraud-alert/" target="_blank" rel="noopener noreferrer">Fraud Alert</a></li>
                                            <li className="list-inline-item ">
                                                <a href="https://www.un.org/en/sections/about-website/privacy-notice/" target="_blank" rel="noopener noreferrer">Privacy Notice</a></li>
                                            <li className="list-inline-item last">
                                                <a href="https://www.un.org/en/sections/about-website/terms-use/" target="_blank" rel="noopener noreferrer">Terms of Use</a></li>
                                        </ul> 
                                    </div> 
                                </div>
                            </Col>
                        </Row>    
                    </Container>                
                </Container>
            </footer>
        );
    }

}

export default withTranslation()(FooterComponent);
