import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'
import { ThreeDots } from 'react-loader-spinner'

// Importing the Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
// custom index style
import './index.css';


const container = document.getElementById('root');
const root = createRoot(container);

const AppRoot = () => (
  <React.StrictMode>
    <I18nextProvider i18n={i18n} >
      <Suspense fallback={<ThreeDots
        style={{
          position: 'absolute', left: '50%', top: '20%',
          transform: 'translate(-50%, -20%)'
        }}
        color="#00BFFF"
        height={100}
        width={100}
        timeout={3000} //3 secs 
      />
      }>
        <App />
      </Suspense>
    </I18nextProvider>
  </React.StrictMode>
);

root.render(<AppRoot />);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
