import {Component} from "react";
import {
    Container,
    Row,
    Col,
    Form,
    Button,
    Tab,
    Tabs,
    Alert,
    Image,
} from "react-bootstrap";
import {withTranslation, Trans} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faQrcode,
    faKeyboard,
    faCamera,
    faExclamationTriangle,
    faCheckCircle,
    faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { ThreeDots } from "react-loader-spinner";
import StampMintingExecutionComponent from "./StampMintingExecutionComponent";
import StampDisplayComponent from "./StampDisplayComponent";
import {Redirect} from "react-router-dom/cjs/react-router-dom.min";
import XTrans from "../XTrans";
import ReactGA from "react-ga";
import web3Handler from "../../utils/web3/Web3Handler";
import {CryptoStampContext} from "../../utils/provider/stamp/Provider";
import ScrollToTop from "../ScrollToTop";
import { StampQrReader } from "./components/StampQrReader";

class StampMintingPreparationComponent extends Component {
    static contextType = CryptoStampContext;

    constructor(props) {
        super(props);
        this.handleSubmitManualMnemonic = this.handleSubmitManualMnemonic.bind(
            this
        );
        //
        this.handleActivateCamera = this.handleActivateCamera.bind(this);
        this.handleDeactivateCamera = this.handleDeactivateCamera.bind(this);
        //
        this.state = {
            showCamera: false,
            cameraError: null,
            manualMnemonicError: null,
            activateManualSubmitButton: false,
            manualMnemonicValid: false,
            screen: "init",
            qrMnemonicError: null,
        };
    }

    componentDidMount() {
        ReactGA.pageview("/activate-stamp/prepare");
        if (web3Handler.isConnectedToBlockChain()) {
            this.setState({redirectToWallet: false});
        }
    }

    handleActivateCamera() {
        this.setState({
            showCamera: true,
            cameraError: null,
            qrMnemonicError: null,
            stampJson: null,
        });
    }

    handleDeactivateCamera() {
        this.setState({
            showCamera: false,
            cameraError: null,
        });
    }

    handleChangeManualMnemonic(event) {
        console.log("handleChangeManualMnemonic", event.target.value);
        if (event.target.value && event.target.value.trim() !== "") {

            let mnemonicWords = event.target.value
                .trim()
                .toLowerCase()
                .split(/(\s+)/)
                .filter(function (e) {
                    return e.trim().length > 0;
                });
            //console.log(mnemonicWords);
            if (mnemonicWords.length < 12) {
                this.setState({
                    manualMnemonicError: "too-short",
                    manualMnemonicValid: false,
                });
            } else if (mnemonicWords.length === 12) {
                // pause input
                this.setState({
                    checkingManualMnemonic: true,
                });
                const that = this;
                this.verifyMnemonicCanDeriveWallet(mnemonicWords.join(" "), function (wallet) {
                        that.setState({
                            manualMnemonicError: null,
                            manualMnemonicValid: true,
                            activateManualSubmitButton: true,
                            derivedWallet: wallet,
                            // restart input
                            checkingManualMnemonic: false,
                        });
                    },
                    function (errCode) {
                        that.setState({
                            manualMnemonicError: errCode,
                            manualMnemonicValid: false,
                            activateManualSubmitButton: false,
                            // restart input
                            checkingManualMnemonic: false,
                        });
                    }
                );
            } else if (mnemonicWords.length > 12) {
                this.setState({
                    manualMnemonicError: "too-long",
                    manualMnemonicValid: false,
                });
            }
        }
    }

    verifyMnemonicCanDeriveWallet = (mnemonic, setWalletCallback, setErrorCallback) => {
        try {

            web3Handler.verifyMnemonicCanDeriveWallet(mnemonic, (wallet) => {
                    setWalletCallback(wallet);
                }, () => {
                    setErrorCallback("invalid-bip39");
                }
            );
        } catch (err) {
            setErrorCallback("unknown-error", err.message);
        }
    };

    handleSubmitManualMnemonic(event) {
        this.setState({
            checkingManualMnemonic: true,
        });

        var address = "0x" + this.state.derivedWallet.getAddress().toString("hex");

        this.loadStampDetails(
            address,
            (json) => {
                console.log("StampDetails", json);

                const version = web3Handler.getVersionFrom(json.stampId);

                this.verifyStampIsMintable(json, version, (stampJson) => {
                        this.setState({
                            stampJson: stampJson,
                            screen: "stampMintPreview",
                        });
                    },
                    (err) => {
                        // handle not mintable
                        console.log("cannot loadStampDetails", err);

                        if (err === "exists-already") {
                            this.setState({
                                stampJson: json,
                                screen: "stampDisplay",
                                stampDisplayError: err,
                            });
                        } else {
                            this.setState({
                                stampJson: json,
                                manualMnemonicError: "proof-error",
                                manualMnemonicValid: false,
                                activateManualSubmitButton: false,
                                checkingManualMnemonic: false,
                            });
                        }
                    }
                );
            },
            (error) => {
                console.log("error", error);

                this.setState({
                    manualMnemonicError: "non-existant-wallet-stamp",
                    manualMnemonicValid: false,
                    activateManualSubmitButton: false,
                    checkingManualMnemonic: false,
                });
            }
        );

        event.preventDefault();
    }

    loadStampDetails(address, successCallback, errorCallback) {
        try {
            web3Handler
                .fetchStampJson(address)
                .then((json) => successCallback(json))
                .catch((err) => errorCallback(err));
        } catch (error) {
            errorCallback(error);
        }
    }

    verifyStampIsMintable(stampJson, version, successCallback, errorCallback) {

        web3Handler
            .getUnitedNationStamp(version)
            .methods.exists(stampJson.tokenId)
            .call({}, (error, result) => {
                console.log("exists", error, result);
                if (error) {
                    errorCallback("unknown-error");
                } else {
                    if (result === true) {
                        errorCallback("exists-already");
                    } else {

                        const address = "0x" + this.state.derivedWallet.getAddress().toString("hex");

                        const leaf = Buffer.from(stampJson.leaf.substring(2), "hex");
                        const proof = stampJson.proof.map((el) => {
                            return Buffer.from(el.substring(2), "hex");
                        });

                        web3Handler
                            .getUnitedNationStamp(version)
                            .methods.verifyClaimWithMerkleProof(
                            address,
                            stampJson.tokenId,
                            leaf, // leaf = hash(address | tokenId)
                            proof
                        )
                            .call({}, (error, result) => {
                                console.log("Verification", error, result);
                                if (error) {
                                    console.err("proof-verification-failed", error, stampJson);
                                    errorCallback("proof-verification-failed");
                                } else {
                                    if (result === true) {
                                        successCallback(stampJson);
                                    } else {
                                        errorCallback("proof-verification-failed");
                                    }
                                }
                            });
                    }
                }
            });
    }

    handleScan = (data) => {
        if (data) {
            this.setState({
                showCamera: false,
                qrMnemonicError: null,
            });
            const that = this;
            this.verifyMnemonicCanDeriveWallet(
                data,
                function (wallet) {
                    const address = "0x" + wallet.getAddress().toString("hex");

                    that.setState({
                        derivedWallet: wallet,
                    });

                    that.loadStampDetails(
                        address,
                        (stampJson) => {
                            console.log(stampJson);
                            const version = web3Handler.getVersionFrom(stampJson.stampId);

                            that.verifyStampIsMintable(
                                stampJson,
                                version,
                                () => {
                                    // forward to activation screen
                                    that.setState({
                                        stampJson: stampJson,
                                        screen: "stampMintPreview",
                                    });
                                },
                                (err) => {
                                    console.log("cannot activate ", err);
                                    // handle cannot activate
                                    if (err === "exists-already") {
                                        that.setState({
                                            stampJson: stampJson,
                                            screen: "stampDisplay",
                                            stampDisplayError: err,
                                        });
                                    } else {
                                        that.setState({
                                            stampJson: stampJson,
                                            qrMnemonicError: "proof-error",
                                        });
                                    }
                                }
                            );
                        },
                        (error) => {
                            console.log("error", error);
                            //
                            that.setState({
                                qrMnemonicError: "non-existant-wallet-stamp",
                            });
                        }
                    );
                },
                function (errCode, errMessage) {
                    console.log("cannot derive wallet", errCode, errMessage);
                    // 'invalid-bip39' or 'unknown-error'
                    that.setState({
                        qrMnemonicError: errCode,
                    });
                }
            );
        }
    };

    handleError = (err) => {
        console.error("handleError", err);
        this.setState({
            cameraError: err.message,
            showCamera: false,
        });
    };

    render() {
        // account -1: disconnected
        if (this.context.account === -1) {
            return <Redirect to={`${process.env.PUBLIC_URL}/wallet`}/>;
        }
        // network -1: no provider
        if (!web3Handler.isConnectedToBlockChain()) {
            return <Redirect to={`${process.env.PUBLIC_URL}/network`}/>;
        }

        if (this.state.screen === "stampMintPreview") {
            return (
                <StampMintingExecutionComponent
                    wallet={this.state.derivedWallet}
                    stamp={this.state.stampJson}
                    chainId={this.context.chainId}
                    account={this.context.account}
                />
            );
        } else if (this.state.screen === "stampDisplay") {
            let match = {
                params: {
                    stampId: this.state.stampJson.stampId,
                },
            };
            return (
                <StampDisplayComponent
                    match={match}
                    error={this.state.stampDisplayError}
                />
            );
        } else {
            const isLoading =  this.state.checkingManualMnemonic === true;
            
            return (
                <section>
                    <ScrollToTop/>
                    <Container data-aos="zoom-in-down">
                        <Row>
                            <Col md={7} xs={12}>
                                <h3>
                                    <XTrans>
                                        Activate <uncs/>
                                    </XTrans>
                                </h3>
                                <p>
                                    <XTrans>
                                        The secret code needed to activate your <uncs/> on the
                                        blockchain is contained behind the scratch-off area 3.
                                    </XTrans>
                                </p>
                                <p>
                                    <Trans>
                                        Please carefully scratch open the area 3 and make sure all
                                        12 words and the QR code are visible.
                                    </Trans>
                                </p>
                                <p>
                                    <Trans>
                                        Either scan the QR code using the camera of your device or
                                        manually enter the 12 words contained in area 3 into the
                                        below text field.
                                    </Trans>
                                </p>
                            </Col>
                            <Col md={5} xs={12}>
                                <Container
                                    style={{
                                        display: "flex",
                                        height: "100%",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    className="pb-3"
                                >
                                    <Image
                                        alt="Unscratched stamp"
                                        src="./images/UNCS2/UNCS2-NewYork.png"
                                        style={{width: "80%"}}
                                    />
                                </Container>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={7} xs={12}>
                                <Tabs defaultActiveKey="camera">
                                    <Tab
                                        eventKey="camera"
                                        title={
                                            <span>
                        <FontAwesomeIcon icon={faQrcode}/>{" "}
                                                <Trans>Scan QR code</Trans>
                      </span>
                                        }
                                    >
                                        <Container className="pt-2">
                                            <p>
                                                <Trans>
                                                    Click this button to start your camera. When requested
                                                    hold the QR code into the camera and wait for
                                                    confirmation.
                                                </Trans>
                                            </p>
                                            <Form.Group className="text-center">
                                                <Button
                                                    onClick={this.handleActivateCamera}
                                                    hidden={this.state.showCamera}
                                                    style={{alignItems: "center"}}
                                                >
                                                    <FontAwesomeIcon icon={faCamera}/>{" "}
                                                    <Trans>Activate camera</Trans>
                                                </Button>
                                                <Button
                                                    onClick={this.handleDeactivateCamera}
                                                    hidden={!this.state.showCamera}
                                                    style={{alignItems: "center"}}
                                                >
                                                    <Trans>Deactivate Camera</Trans>
                                                </Button>
                                            </Form.Group>
                                        </Container>
                                        <Container>
                                            <Alert
                                                variant="danger"
                                                hidden={this.state.cameraError === null}
                                            >
                                                <FontAwesomeIcon icon={faExclamationTriangle}/>
                                                &nbsp;
                                                <Trans>Could not activate the camera:</Trans>{" "}
                                                <span>{this.state.cameraError}.</span>
                                            </Alert>
                                            <Alert
                                                variant="info"
                                                hidden={this.state.cameraError === null}
                                            >
                                                <FontAwesomeIcon icon={faInfoCircle}/>
                                                &nbsp;
                                                <Trans>
                                                    If your device does not have a camera or the camera
                                                    cannot be activated you can also manually enter the 12
                                                    words (see above).
                                                </Trans>
                                            </Alert>
                                            <Alert
                                                variant="success"
                                                hidden={
                                                    !(
                                                        this.state.cameraError !== null &&
                                                        this.state.showCamera === true
                                                    )
                                                }
                                            >
                                                <FontAwesomeIcon icon={faCheckCircle}/>
                                                &nbsp;
                                                <Trans>
                                                    Camera activated! Hold the QR code into the camera
                                                    using the preview window.
                                                </Trans>
                                            </Alert>
                                            <Alert
                                                variant="danger"
                                                hidden={this.state.qrMnemonicError !== "unknown-error"}
                                            >
                                                <FontAwesomeIcon icon={faExclamationTriangle}/>
                                                &nbsp;
                                                <Trans>
                                                    Unexpected error. Please try again, or revert to
                                                    manually entering the 12 words if the error persists.
                                                </Trans>
                                            </Alert>
                                            <Alert
                                                variant="danger"
                                                hidden={this.state.qrMnemonicError !== "invalid-bip39"}
                                            >
                                                <FontAwesomeIcon icon={faExclamationTriangle}/>
                                                &nbsp;
                                                <Trans>
                                                    Invalid code. Make sure all words are exactly as
                                                    printed on the stamp.
                                                </Trans>
                                            </Alert>
                                            <Alert
                                                variant="danger"
                                                hidden={this.state.qrMnemonicError !== "proof-error"}
                                            >
                                                <FontAwesomeIcon icon={faExclamationTriangle}/>
                                                &nbsp;
                                                <Trans>
                                                    Unexpected failure of Merkle proof pre-validation.
                                                </Trans>
                                            </Alert>
                                            <Alert
                                                variant="danger"
                                                hidden={
                                                    this.state.qrMnemonicError !==
                                                    "non-existant-wallet-stamp"
                                                }
                                            >
                                                <FontAwesomeIcon icon={faExclamationTriangle}/>
                                                &nbsp;
                                                <XTrans>
                                                    Could not find any <uncs/> linked to this QR code.
                                                </XTrans>
                                            </Alert>
                                        </Container>
                                        <Container
                                            className="text-center"
                                            style={{maxWidth: 300}}
                                        >
                                            <StampQrReader
                                                isActivated={this.state.showCamera === true && this.state.cameraError === null}
                                                handleError={this.handleError}
                                                handleScan={this.handleScan}
                                                previewStyle={{
                                                    display: "inline"
                                                }}
                                            />
                                        </Container>
                                    </Tab>
                                    <Tab
                                        eventKey="manual"
                                        title={
                                            <span>
                                                <FontAwesomeIcon icon={faKeyboard}/>{" "}
                                                <Trans>Manually enter 12 words</Trans>
                                            </span>
                                        }
                                    >
                                        <Container className="pt-2">
                                            <p>
                                                <Trans>
                                                    Start typing the 12 words from the stamp area 3 into
                                                    the below field. Then press the submit button.
                                                </Trans>
                                            </p>
                                            <p>
                                                <Trans>
                                                    All 12 words must be typed in the exact same order as
                                                    on the stamp, each word in lower case and separated by
                                                    a whitespace.
                                                </Trans>
                                            </p>
                                            <Form.Group
                                                disabled={this.state.checkingManualMnemonic === true}
                                            >
                                                <Form.Label>
                                                    <Trans>Secret code (12 words):</Trans>
                                                </Form.Label>
                                               
                                                {isLoading &&
                                                    <ThreeDots
                                                            style={{
                                                                position: "absolute",
                                                                left: "50%",
                                                                // top: '20%',
                                                                transform: "translate(-50%, -20%)",
                                                                display: "block",
                                                            }}
                                                            color="#00BFFF"
                                                            height={100}
                                                            width={100}
                                                    />
                                                }
                                                <Form.Control
                                                    isValid={this.state.manualMnemonicValid}
                                                    isInvalid={this.state.manualMnemonicError !== null}
                                                    as="textarea"
                                                    rows="3"
                                                    onChange={this.handleChangeManualMnemonic.bind(this)}
                                                />
                                                <Container className="pt-1 text-center">
                                                    <Alert
                                                        variant="warning"
                                                        hidden={
                                                            this.state.manualMnemonicError !== "too-short"
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faExclamationTriangle}/>
                                                        &nbsp;<Trans>Too short, keep on typing...</Trans>
                                                    </Alert>
                                                    <Alert
                                                        variant="danger"
                                                        hidden={
                                                            this.state.manualMnemonicError !== "too-long"
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faExclamationTriangle}/>
                                                        &nbsp;<Trans>Too long :-(</Trans>
                                                    </Alert>
                                                    <Alert
                                                        variant="danger"
                                                        hidden={
                                                            this.state.manualMnemonicError !== "invalid-bip39"
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faExclamationTriangle}/>
                                                        &nbsp;
                                                        <Trans>
                                                            Invalid code. Make sure all words are exactly as
                                                            printed on the stamp.
                                                        </Trans>
                                                    </Alert>
                                                    <Alert
                                                        variant="danger"
                                                        hidden={
                                                            this.state.manualMnemonicError !== "proof-error"
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faExclamationTriangle}/>
                                                        &nbsp;
                                                        <Trans>
                                                            Unexpected failure of Merkle proof pre-validation.
                                                        </Trans>
                                                    </Alert>
                                                    <Alert
                                                        variant="danger"
                                                        hidden={
                                                            this.state.manualMnemonicError !==
                                                            "non-existant-wallet-stamp"
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faExclamationTriangle}/>
                                                        &nbsp;
                                                        <XTrans>
                                                            Could not find any <uncs/> linked to this code.
                                                        </XTrans>
                                                    </Alert>
                                                    <Alert
                                                        variant="success"
                                                        hidden={this.state.manualMnemonicValid === false}
                                                    >
                                                        <FontAwesomeIcon icon={faCheckCircle}/>
                                                        &nbsp;<Trans>Looking good!</Trans>
                                                    </Alert>
                                                    <Button
                                                        hidden={this.state.manualMnemonicValid === false}
                                                        disabled={!this.state.activateManualSubmitButton}
                                                        onClick={this.handleSubmitManualMnemonic}
                                                    >
                                                        <Trans>Submit Mnemonic</Trans>
                                                    </Button>
                                                </Container>
                                            </Form.Group>
                                        </Container>
                                    </Tab>
                                </Tabs>
                            </Col>
                            <Col md={5} xs={12}>
                                <Container
                                    className="pt-2"
                                    style={{
                                        display: "flex",
                                        height: "100%",
                                        justifyContent: "center",
                                        alignItems: "flex-start",
                                    }}
                                >
                                    <Image
                                        alt="Scratched stamp"
                                        src={`./images/assets/stamp-scratched.jpg`}
                                        style={{width: "80%"}}
                                    />
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </section>
            );
        }
    }
}

export default withTranslation()(StampMintingPreparationComponent);
