import React from 'react';
import './EthAddress.css';
import Truncate from '../Truncate';
import { Link } from 'react-router-dom';

class EthAddress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if("0x0000000000000000000000000000000000000000" === this.props.address) {
            return <span className="EthAddress text-info">{Truncate.wallet(this.props.address)}</span>;
        } else {
            return <Link to={`${process.env.PUBLIC_URL}/wallet/${this.props.address}`} className="EthAddress text-info">{Truncate.wallet(this.props.address)}</Link>
        }        
    }
}

export default EthAddress;
