import Web3 from "web3";
import stamp1abi from "./UnitedNationsCryptostamp.json";
import stamp2abi from "./UnitedNationsCryptostamp2.json";
import * as bip39 from "bip39";
import { hdkey } from "ethereumjs-wallet";
import StampEthUtil from "./StampEthUtil";
import {
  VERSIONS,
  VERSION_1_URL_WALLETS,
  VERSION_2_URL_WALLETS,
} from "./const";

const win = window;

export class Web3Handler {
  unitedNationsCryptoStamp1 = null;
  unitedNationsCryptoStamp2 = null;
  web3 = null;

  constructor() {

    this.web3 = new Web3(window.ethereum);

    if (!this.unitedNationsCryptoStamp1) {
      this.unitedNationsCryptoStamp1 = this.createStampContract(
        stamp1abi,
        process.env.REACT_APP_ETH_CONTRACT
      );
    }

    if (!this.unitedNationsCryptoStamp2) {
      this.unitedNationsCryptoStamp2 = this.createStampContract(
        stamp2abi,
        process.env.REACT_APP_ETH_CONTRACT_2
      );
    }
  }

  getWeb3() {
    if (!this.web3) {
      return new Web3(window.ethereum);
    }
    return this.web3;
  }

  initWeb3AndGetAccount(callback = (_chainId, _account) => { }) {
    // set initial chainId
    const web3 = this.getWeb3();
    let account = -1;

    web3.eth.getAccounts().then((accounts) => {
      if (accounts && accounts.length > 0) {
        account = accounts[0];
      }
      callback(window.ethereum.chainId, account);
    });
  }

  isMetamaskInstalled() {
    return win.ethereum && win.ethereum.isMetaMask;
  }

  isMetamaskEnabled() {
    return win.ethereum.isConnected();
  }

  isConnectedToBlockChain() {
    return window.ethereum && win.ethereum.chainId && win.ethereum.chainId !== -1;
  };

  isConnectionReady() {
    return !!(win.ethereum && this.isMetamaskEnabled() && win.ethereum.chainId === process.env.REACT_APP_NETWORK_ID);
  }

  isReady() {
    return this.isMetamaskInstalled();
  }

  subscribeToEthAccountChange(account, callback = (_address) => { }) {
    // disable auto-refresh as we listen to events below
    window.ethereum.autoRefreshOnNetworkChange = false;
    // events
    window.ethereum.on("accountsChanged", (accounts) => {
      // Handle the new accounts, or lack thereof.
      // "accounts" will always be an array, but it can be empty.
      console.log("Eth Accounts Changed happened", accounts);
      if (accounts && accounts.length > 0) {
        if (accounts[0] !== account) {
          // for some reason accountsChanged does not return the ChecksumAddress
          const web3 = this.getWeb3();
          const checksumAddress = web3.utils.toChecksumAddress(accounts[0]);

          callback(checksumAddress);
        }
      } else {
        callback(-1);
      }
    });
  }

  subscribeToChainChange(chainIdOld, callback = (_chainId) => { }) {
    window.ethereum.on("chainChanged", (chainId) => {
      // Handle the new chain.
      // Correctly handling chain changes can be complicated.
      // We recommend reloading the page unless you have a very good reason not to.
      console.log("Eth Chain Changed", chainId, "state", chainIdOld);
      if (!chainIdOld || chainId !== chainIdOld) {
        callback(chainId);
      }
    });
  }

  createStampContract(abi, contractDeployedAddress) {
    const web3 = this.getWeb3();
    return new web3.eth.Contract(abi, contractDeployedAddress);
  }

  getUnitedNationStamp(version) {

    if (version === VERSIONS.V1) {
      if (!this.unitedNationsCryptoStamp1) {
        this.unitedNationsCryptoStamp1 = this.createStampContract(
          stamp1abi,
          process.env.REACT_APP_ETH_CONTRACT
        );
      }

      return this.unitedNationsCryptoStamp1;
    } else {
      if (!this.unitedNationsCryptoStamp2) {
        this.unitedNationsCryptoStamp2 = this.createStampContract(
          stamp2abi,
          process.env.REACT_APP_ETH_CONTRACT_2
        );


        return this.unitedNationsCryptoStamp2;
      }

      return this.unitedNationsCryptoStamp2;
    }
  }

  async loadOwnedStampsByVersion(walletAddress, version) {
    let promises = [];

    try {
      const balance = await this.getUnitedNationStamp(version)
        .methods.balanceOf(walletAddress)
        .call();

      for (let i = 0; i < balance; i++) {
        promises.push(
          new Promise((resolve, reject) => {
            this.getUnitedNationStamp(version)
              .methods.tokenOfOwnerByIndex(walletAddress, i)
              .call()
              .then((token) => {
                let tokenId = ~~token;
                resolve({
                  balance,
                  tokenId,
                  stampId: StampEthUtil.getBase58StampIdForTokenId(tokenId, version),
                });
              })
              .catch((err) => {
                resolve(err);
              });
          })
        );
      }
    } catch (error) {
      return promises;
    }

    return promises;

  }


  //stamp -> we load v1 and v2 together here
  async loadOwnedStamps(walletAddress, callback = (_ownedStampsV1, _ownedStampsV2) => { }) {
    const web3 = this.getWeb3();
    if (web3.utils.isAddress(walletAddress)) {
      const promises1 = await this.loadOwnedStampsByVersion(walletAddress, VERSIONS.V1);
      const promises2 = await this.loadOwnedStampsByVersion(walletAddress, VERSIONS.V2);

      try {
        const results = await Promise.all(promises1);
        const results2 = await Promise.all(promises2);

        callback(results, results2);

      } catch (error) {

      }


    } else {
      callback([], []);
    }
  }

  verifyMnemonicCanDeriveWallet = async (
    mnemonic,
    setWalletCallback,
    setErrorCallback
  ) => {
    try {
      const valid = bip39.validateMnemonic(mnemonic);
      if (valid) {
        const path = "m/44'/60'/0'/0/0"; // default wallet (account 1 in metamask)
        const seed = await bip39.mnemonicToSeed(mnemonic);
        const hdwallet = hdkey.fromMasterSeed(seed);
        const wallet = hdwallet.derivePath(path).getWallet();
        setWalletCallback(wallet);
      } else {
        setErrorCallback("invalid-bip39");
      }
    } catch (err) {
      throw err;
    }
  };

  fetchStampJson(address) {
    const urlVersion1 =
      process.env.PUBLIC_URL + `${VERSION_1_URL_WALLETS}${address}.json`;
    const urlVersion2 =
      process.env.PUBLIC_URL + `${VERSION_2_URL_WALLETS}${address}.json`;

    return fetch(urlVersion1)
      .then((response) => response.json())
      .then((response) => response)
      .catch((err) => {
        return fetch(urlVersion2)
          .then((response) => response.json())
          .then((response) => response)
          .catch((err) => {
            throw err;
          });
      });
  }

  getDecodedStamp(stampId) {
    return StampEthUtil.decodeStampId(stampId);
  }

  getVersionFrom(stampId) {
    return StampEthUtil.decodeStampId(stampId).version;
  }

  // Wallets
  async loadBalanceAndInitWallet(walletAddress) {
    const web3 = this.getWeb3();
    if (web3.utils.isAddress(walletAddress)) {
      return await web3.eth.getBalance(walletAddress);
    }
    return 0;
  }
}

const web3HandlerIns = new Web3Handler();
export default web3HandlerIns;
