import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { Trans } from 'react-i18next';
import { CONTRACT_ADDRESS } from 'src/utils/web3/const';

class EtherscanToken extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tokenId: props.tokenId
        };
    }

    render() {
        const contractAddress = CONTRACT_ADDRESS[this.props.version];
        return <a className="text-info" href={`${process.env.REACT_APP_ETHERSCAN_BASE_URL}/token/${contractAddress}?a=${this.state.tokenId}`}
                    target="_blank" rel="noopener noreferrer">
           <Trans>View on etherscan.io</Trans>
           <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-1"/>
        </a>        
    }
}

export default EtherscanToken;
