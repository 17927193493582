import React from "react";
import {Col, Row, Table} from "react-bootstrap";
import {Trans, useTranslation} from "react-i18next";
import XTrans from "../../XTrans";
import {faCheck, faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import SDG from "./SDG";

export const CollectionSeries1 = () => {
    const {t} = useTranslation();

    return (
        <>
            <h4>
                <Trans>UN Crypto Stamps Series 1 Collection</Trans>
            </h4>
            <Row>
                <Col lg={6}>
                    <h6>
                        <Trans>Issuing Offices</Trans>
                    </h6>
                    <p>
                        <XTrans>
                            The <uncs/> has three distinct designs featuring the United
                            Nations Headquarters buildings in New York, Geneva and Vienna.
                        </XTrans>
                    </p>
                    <p>
                        <Trans>
                            The total print quantity is 90,000 stamps. There are 30,000 per
                            each issuing office for New York, Geneva and Vienna.
                        </Trans>
                    </p>
                </Col>
                <Col lg={6} className="pt-3">
                    <div className="text-center">
                        <img
                            src="./images/assets/cancel-stamp-NewYork.jpg"
                            alt={t(
                                "Canceled UN crypto stamp, New York, light white to blue gradient stamp on red background with white dots arranged in concentric circles"
                            )}
                            style={{
                                minWidth: 140,
                            }}
                            width={"31%"}
                            className="pb-2"
                        />
                        <img
                            src="./images/assets/cancel-stamp-Geneva.jpg"
                            alt={t(
                                "Canceled UN crypto stamp,Geneva, white to pink gradient stamp on purple background with white dots arranged in concentric circles"
                            )}
                            style={{
                                minWidth: 140,
                            }}
                            width={"31%"}
                            className="pb-2"
                        />
                        <img
                            src="./images/assets/cancel-stamp-Vienna.jpg"
                            alt={t(
                                "Canceled UN crypto stamp, Vienna, yellow stamp on blue background with white dots arranged in concentric circles"
                            )}
                            style={{
                                minWidth: 140,
                            }}
                            width={"31%"}
                            className="pb-2"
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="mt-2">
                    <h6>
                        <Trans>Variations</Trans>
                    </h6>
                    <p>
                        <XTrans>
                            You may be excited to know that each stamp is linked to one
                            Sustainable Development Goal.
                        </XTrans>
                    </p>
                    <p>
                        <XTrans>
                            Since there are three issuing offices and 17 Goals, there are 51
                            variations of the <uncsb/> in the collection.
                        </XTrans>
                    </p>
                    <p>
                        <Trans>
                            You are encouraged to find and collect all 51 variations!
                        </Trans>
                    </p>
                    <p>
                        <Trans>
                            There are equal quantities of all stamp variations and they are
                            arranged sequentially from Goal 1 to Goal 17.
                        </Trans>
                    </p>
                    <p>
                        <Trans>
                            To learn more about the United Nations Sustainable Development
                            Goals, please visit
                        </Trans>
                        &nbsp;
                        <a
                            href="https://sdgs.un.org/goals"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            https://sdgs.un.org/goals{" "}
                            <FontAwesomeIcon icon={faExternalLinkAlt}/>
                        </a>
                        .
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md={0} lg={1}/>
                <Col className="mt-4">
                    <Table striped bordered hover size="sm">
                        <thead>
                        <tr>
                            <th colSpan={2}>
                                <div className="float-right">
                                    <Trans>Issuing Office</Trans>
                                    <br/>
                                    <Trans>Language</Trans>
                                    <br/>
                                    <Trans>Denomination</Trans>
                                </div>
                            </th>
                            <th className="text-center">
                                <Trans>New York</Trans>
                                <br/>
                                <Trans>English</Trans>
                                <br/>
                                USD
                            </th>
                            <th className="text-center">
                                <Trans>Geneva</Trans>
                                <br/>
                                <Trans>French</Trans>
                                <br/>
                                CHF
                            </th>
                            <th className="text-center">
                                <Trans>Vienna</Trans>
                                <br/>
                                <Trans>German</Trans>
                                <br/>
                                EUR
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {SDG.range().map((num) => {
                            return (
                                <tr key={`stamp-tbl-row-sdg-${num}`}>
                                    <td className="text-center">
                                        <SDG num={num} display="mini"/>
                                    </td>
                                    <td>
                                        <SDG num={num}/>
                                    </td>
                                    <td className="text-center">
                                        <FontAwesomeIcon icon={faCheck}/>
                                    </td>
                                    <td className="text-center">
                                        <FontAwesomeIcon icon={faCheck}/>
                                    </td>
                                    <td className="text-center">
                                        <FontAwesomeIcon icon={faCheck}/>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                </Col>
                <Col md={0} lg={1}/>
            </Row>
        </>
    )
}
