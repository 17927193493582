import React from "react";
import {Container, Alert} from "react-bootstrap";
import {withTranslation, Trans} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import StampEthUtil from "../../utils/web3/StampEthUtil";
import {VERSIONS} from "src/utils/web3/const";
import {assetsByVersion} from "./const";
import {StampCardV2} from "src/components/stamps/Series2/StampCardV2";
import {StampCardV1} from "src/components/stamps/Series1/StampCardV1";

class StampCard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            image: "./images/assets/placeholder.jpg",
            loadingJson: true,
            linked: props.linked,
            glow: props.glow,
            orderBy: props.orderBy,
            stampJson: props.stamp,
            placeholder: props.placeholder,
        };
    }

    imageLink() {
        if (this.state.loadingJson === true) {
            return "./images/assets/stamp-placeholder.svg";
        } else {
            const designIdentifier = this.state.stampJson.designIdentifier;
            return assetsByVersion(this.props.version).stampImage(
                designIdentifier,
                this.state.stampJson.location,
            );
        }
    }

    calcOrder() {
        if (this.state.loadingJson === true) {
            return 0;
        }
        switch (this.state.orderBy) {
            case "tokenId":
                return this.state.stampJson.tokenId;
            case "sorted":
            case "collection":
                return (
                    this.locationMask(this.state.stampJson.location) +
                    this.getMask(~~this.state.stampJson.designNumber) +
                    ~~this.state.stampJson.tokenId
                );
            default:
                return 0;
        }
    }

    locationMask(location) {
        switch (location) {
            case "Geneva":
                return 10000000;
            case "NewYork":
                return 20000000;
            case "Vienna":
                return 30000000;
            default:
                return 0;
        }
    }

    getOfficeName(office) {
        switch (office) {
            case "Geneva":
                return <Trans>Geneva</Trans>;
            case "NewYork":
                return <Trans>New York</Trans>;
            case "Vienna":
                return <Trans>Vienna</Trans>;
            default:
                return "";
        }
    }

    getMask(stamp) {
        return stamp * 100000;
    }

    render() {
        const order = this.calcOrder();
        let Warning = () => {
            return (
                <Container>
                    <Alert variant="warning">
                        <FontAwesomeIcon icon={faExclamationTriangle}/> Can't display
                        stamp, invalid data.
                    </Alert>
                </Container>
            );
        }


        const getStampCardByVersion = (version) => {
            if (version === VERSIONS.V1) {
                return <StampCardV1
                        stamp={this.props.stamp}
                        order={order}
                        loadingJson={this.state.loadingJson}
                        linked={this.state.linked}
                        imageLink={this.imageLink()}
                        officeName={this.getOfficeName(this.state.stampJson.location)}
                        {...this.props}
                      />
            }
            if (version === VERSIONS.V2) {
                return <StampCardV2
                        stamp={this.props.stamp}
                        order={order}
                        loadingJson={this.state.loadingJson}
                        linked={this.state.linked}
                        imageLink={this.imageLink()}
                        officeName={this.getOfficeName(this.state.stampJson.location)}
                        {...this.props}
                       />
            }
            return <></>
        }
        return (
            <>
                {this.state.showStampInvalid && <Warning/>}
                {getStampCardByVersion(this.props.version)}
            </>
        );
    }

    loadStampDetails = async () => {
        if (this.state.placeholder === true) {
            this.setState({
                loadingJson: false,
            });
            return;
        }
        //
        let stamp = StampEthUtil.decodeStampId(this.props.stamp.stampId);
        if (stamp.valid) {
            this.setState({
                stampJson: stamp.stampJson,
                loadingJson: false,
            });
            if (this.props.registerCallback) {
                this.props.registerCallback(stamp.stampJson);
            }
        } else {
            this.setState({
                stampJson: null,
                loadingJson: true,
                showStampInvalid: true,
            });
        }
    };

    componentDidMount() {
        this.loadStampDetails();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.linked !== this.props.linked ||
            prevProps.glow !== this.props.glow ||
            prevProps.orderBy !== this.props.orderBy
        ) {
            this.setState({
                linked: this.props.linked,
                glow: this.props.glow,
                orderBy: this.props.orderBy,
            });
        }
    }
}

export default withTranslation()(StampCard);
