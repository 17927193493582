import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faPlayCircle, faClipboardList, faStar, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import { withTranslation, Trans } from 'react-i18next'
import XTrans from './XTrans';
import ReactGA from 'react-ga';
import { Jumbotron } from './core/Jumbotron/Jumbotron';

class InstallProviderComponent extends React.Component {
    constructor() {
      super();
      this.state = {
        case: 'init'
      };
    }

    componentDidMount() {
      ReactGA.pageview('/install-provider');
      window.scrollTo(0, 0);
    }

    render() {
        return (
          <section>            
        <Container data-aos="flip-up">
          <h2><Trans>Add a crypto wallet to your browser</Trans></h2>
          <Row>
            <Col>
              <p><FontAwesomeIcon icon={faInfoCircle} /> <XTrans>To use all available functions in the <uncs/> blockchain app, please enable your browser to access the Ethereum blockchain by installing a crypto wallet.</XTrans></p>
              <h4><Trans>What is a crypto wallet?</Trans></h4>
              <p>
                <Trans>A crypto wallet is a small program that enables your browser to securely communicates with the Ethereum blockchain on your behalf.</Trans>
              </p>
              <p>
                <XTrans>With a crypto wallet you can manage your blockchain identity and the assets owned by your identity on the blockchain: crypto coins (ETH) and digital collectibles such as the <uncs/>.</XTrans>
              </p>
            </Col>
            <Col>
              <Jumbotron className="slim-jumbo">
                <h5><FontAwesomeIcon icon={faEthereum} /> <Trans>What is Ethereum?</Trans></h5>
                <p><a href="https://ethereum.org/en/what-is-ethereum/" target="_blank" rel="noopener noreferrer">
                  Ethereum <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a> <Trans>is a global, open-source platform for decentralized applications.</Trans></p>
                <blockquote className="quote-box">
                  <p className="small mb-0">
                    &quot;Ethereum is the second-largest cryptocurrency platform by market capitalization, behind Bitcoin. 
                    It is a decentralized open source blockchain featuring smart contract functionality.&quot;
                  </p>
                  <footer className="blockquote-footer"> <cite title="Wikipedia"><a href="https://en.wikipedia.org/wiki/Ethereum" 
                    target="_blank" rel="noopener noreferrer">Wikipedia <FontAwesomeIcon icon={faExternalLinkAlt} className="ml-1" /></a>, <Trans>retrieved August 2020.</Trans></cite></footer>
                </blockquote>
              </Jumbotron>
            </Col>
          </Row>          
          <Row>
            <Col>
              <h4><Trans>How can I add a crypto wallet?</Trans></h4>
              <p className="xxx-font-weight-bold"><FontAwesomeIcon icon={faClipboardList} /> <Trans>Read about your different options.</Trans></p>
            </Col>
          </Row>
          <Row>
            <Col className="align-self-center">
              <h4 className="text-center"><Trans>Option 1</Trans></h4>
              <p className="text-center">(<FontAwesomeIcon icon={faStar} /> <Trans>Most people choose this option</Trans>)</p>
            </Col>
            <Col xs={8}>
              <Jumbotron className="slim-jumbo">
                <h4><FontAwesomeIcon icon={faPlayCircle} /> <Trans>Install metamask</Trans></h4>
                <p><Trans>Metamask is a gateway to blockchain apps that can be installed as an extension in your Chrome or Firefox browser</Trans>.</p>                
                <p><a href="https://metamask.io/" target="_blank" rel="noopener noreferrer">
                  <Trans>Go to the metamask website and follow the instructions</Trans> <FontAwesomeIcon icon={faExternalLinkAlt} />
                </a><Trans>, then come back to this page.</Trans></p>
                {/*
                <FontAwesomeIcon icon={faChrome} />
                <FontAwesomeIcon icon={faFirefoxBrowser} />
                */}
              </Jumbotron>
            </Col>
          </Row>
          <Row>
            <Col className="align-self-center">
              <h4 className="text-center"><Trans>Option 2</Trans></h4>
              <p className="text-center"></p>
            </Col>
            <Col xs={8}>
              <Jumbotron className="slim-jumbo">
                <h4><Trans>Use an Ethereum-enabled browser</Trans></h4>
                <p><Trans>Some newer browsers are already enabled to access the Ethereum blockchain, such as the Mist browser or Brave browser</Trans>.</p>
                <p><Trans>Use your preferred search engine to find out about your available options.</Trans></p>
              </Jumbotron>
            </Col>
          </Row>
        </Container>
        </section>
        );
    }
}  

export default withTranslation()(InstallProviderComponent);
