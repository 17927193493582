import React from "react";
import "./StampCardv2.css";
import {Trans} from "react-i18next";
import {STAMP_NAME, _getDesignName} from "src/components/stamps/const";
import StampID from "src/components/styles/StampID";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEthereum, faRavelry} from "@fortawesome/free-brands-svg-icons";

export const StampV2Display = ({ officeName, imageStampSrc, state, onChainStatus, altOfficeIcon, location, onEtherClick }) => {
    return <div className="stamp-card">
        <div className="stamp-card__wrp swiper-wrapper">
            <div className="stamp-card__item swiper-slide swiper-slide-active">
                <div className="stamp-card__img">

                    <img
                        src={imageStampSrc}
                        alt=""/>
                </div>
                <div className="stamp-card__content">
                    <span className="stamp-card__code"> <StampID stampId={state.stampJson.stampId}/></span>
                    <div className="stamp-card__title">{state.stampBaseURL}</div>
                    <table className="table table-striped rounded-lg table-borderless table-hover">
                        <tbody>
                        <tr>
                            <td className="stamp-card__text">
                                <span className="mr-2">
                                    <img
                                        alt={altOfficeIcon}
                                        src={`./images/assets/Office-${location}.png`}
                                        style={{height: 20}}
                                    />
                                </span>
                                <Trans>Issuing Office</Trans>
                            </td>
                            <td className="stamp-card__text">{officeName}</td>
                        </tr>
                        <tr>
                            <td className="stamp-card__text">
                                 <span className="mr-2">
                                      <FontAwesomeIcon
                                          icon={faRavelry}
                                          aria-labelledby="aria-label-globe"
                                          size="lg"
                                      />
                                </span>
                                <Trans>{STAMP_NAME[state.version]}</Trans>
                            </td>
                                <td><Trans>{_getDesignName(state.version, state.stampJson.designNumber)}</Trans></td>
                        </tr>
                        <tr>
                            <td className="stamp-card__text">
                                 <span className="mr-2">
                                     <FontAwesomeIcon
                                         onClick={onEtherClick}
                                         aria-labelledby="aria-label-ethereum"
                                         icon={faEthereum}
                                         size="lg"
                                         spin={state.loadingOnChainStatus}
                                     />
                                </span>
                                <Trans>On Chain Status</Trans>
                            </td>
                            <td className="stamp-card__text">{onChainStatus}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>
    </div>
}
