import { useRef, useState, useCallback, useEffect } from "react";
import { Trans } from "react-i18next";
import { TailSpin } from "react-loader-spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EthAddress from "../styles/EthAddress";
import EtherscanToken from "../styles/EtherscanToken";
import OpenSeaAsset from "../styles/OpenSeaAsset";
import {
    Container,
    Row,
    Col,
    Alert,
    Form,
    Button,
    Spinner,
} from "react-bootstrap";
import { useCryptoStamp } from "src/utils/provider/stamp";
import web3Handler from "../../utils/web3/Web3Handler";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { START_BLOCK } from "src/utils/web3/const";

const EventHistory = ({
    version,
    validTokenId,
    exists,
    stampJson,
    you,
    existsStatus,
    stampId,
    loadStampOnChainDetails,
}) => {

    const refs = useRef(null);

    const cryptoStamp = useCryptoStamp();
    const [state, setState] = useState({
        pastEvents: [],
        eventsLoading: false,
        transferStarted: false,
        transferTo: null,
        transferError: null,
        transferAck: false,
        invalidTransferTo: false,
    });

    const loadEvents = useCallback(() => {
        console.log("loadEvents");
        setState({
            ...state,
            eventsLoading: true,
        });

        web3Handler
            .getUnitedNationStamp(version)
            .getPastEvents("Transfer", {
                fromBlock: START_BLOCK[version] + "",
                toBlock: "latest",
                filter: {
                    tokenId: "" + ~~validTokenId,
                },
            })
            .then(function (events) {
                setState({
                    ...state,
                    pastEvents: events,
                    eventsLoading: false,
                });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validTokenId, exists, existsStatus]);

    useEffect(() => {
        if (exists) {
            loadEvents();
        }
    }, [exists, loadEvents]);

    const transferDestinationChanged = (e) => {
        if (
            e.target.value &&
            !web3Handler.getWeb3().utils.isAddress(e.target.value)
        ) {
            console.log("Invalid address,", e.target.value);
            setState({
                ...state,
                invalidTransferTo: true,
                transferTo: null,
                transferAck: null,
            });
        } else {
            setState({
                ...state,
                invalidTransferTo: false,
                transferTo: e.target.value,
            });
        }
    };

    const formTransferAckCheckboxChange = (e) => {
        setState({
            ...state,
            transferAck: e.target.checked,
        });
    };

    const transferStamp = () => {
        setState({
            ...state,
            transferStarted: true,
            transferError: null,
        });

        web3Handler
            .getWeb3()
            .eth.getAccounts()
            .then((accounts) => {
                if (accounts && accounts.length > 0) {
                    const fromAddress = accounts[0];
                    console.log("metamask address: ", fromAddress);
                    web3Handler
                        .getUnitedNationStamp(version)
                        .methods.safeTransferFrom(
                            fromAddress,
                            state.transferTo,
                            validTokenId,
                        )
                        .send({
                            // use the address selected in the web3 provider/extension
                            from: fromAddress,
                        })
                        .on("transactionHash", (hash) => {
                            console.log("on transactionHash", hash);
                        })
                        .on("confirmation", (confirmationNumber, receipt) => {
                            console.log("on confirmation", confirmationNumber, receipt);
                        })
                        .on("receipt", (receipt) => {
                            console.log("on receipt", receipt);
                            refs.formTransferStamp.reset();
                            setState({
                                ...state,
                                transferStarted: false,
                                transferTo: null,
                                transferAck: null,
                            });
                            loadStampOnChainDetails(
                                stampId,
                                cryptoStamp.chainId,
                                cryptoStamp.account,
                            );
                        })
                        .on("error", (error, receipt) => {
                            // If the transaction was rejected by the network with a receipt, the second parameter will be the receipt.
                            console.log("on error", error, receipt);
                            setState({
                                ...state,
                                transferError: error.message,
                                transferStarted: false,
                            });
                        });
                } else {
                    // no account
                }
            });

        // 0xcA4668548496F56CfA0890622ea609a973b39f0f
        // 0x318A830776A8b77d30306742b95E19F69121ef11
    };

    return (
        <Container hidden={!exists}>
            <Row className="">
                <Col lg={6} md={12} className="mt-3">
                    <h5>
                        <Trans>Events</Trans>
                    </h5>
                    {state.eventsLoading === true ? (
                        <div style={{ width: 20 }}>
                            <TailSpin
                                color="#009edb"
                                height={20}
                                width={20}
                            />
                        </div>
                    ) : (
                        state.pastEvents.map((transfer) => {
                            return (
                                <Row
                                    key={transfer.id}
                                    className="border-bottom border-dark ml-0 mr-1"
                                >
                                    <Col lg={2} md={12} className="pl-0">
                                        <Trans>Transfer</Trans>
                                    </Col>
                                    <Col lg={5} md={6} xs={12} className="pl-0">
                                        <Trans>From</Trans>{" "}
                                        <EthAddress address={transfer.returnValues.from} />
                                    </Col>
                                    <Col lg={5} md={6} xs={12} className="pl-0">
                                        <Trans>To</Trans>{" "}
                                        <EthAddress address={transfer.returnValues.to} />
                                    </Col>
                                </Row>
                            );
                        })
                    )}
                </Col>
                <Col lg={6} md={12} className="mt-3">
                    <Row>
                        <Col>
                            <h5>
                                <Trans>Links</Trans>
                            </h5>
                            <Row>
                                <Col>
                                    <OpenSeaAsset tokenId={stampJson.tokenId} version={version} />
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <EtherscanToken tokenId={stampJson.tokenId} version={version} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="mt-3" hidden={!you === true}>
                        <Col>
                            <h5>
                                <Trans>Transfer</Trans>
                            </h5>
                            <p>
                                <Trans>
                                    You can safely transfer your stamp to another wallet if you
                                    wish.
                                </Trans>
                            </p>
                            <Form noValidate ref={refs}>
                                <Form.Group controlId="formTransferStampToAddress">
                                    <Form.Label>
                                        <Trans>Destination wallet address</Trans>
                                    </Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        autoComplete="off"
                                        placeholder="wallet address" // TODO: use translate function
                                        isInvalid={state.invalidTransferTo}
                                        isValid={state.transferTo && !state.invalidTransferTo}
                                        onChangeCapture={transferDestinationChanged.bind(this)}
                                        disabled={state.transferStarted}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        <Trans>Invalid wallet address.</Trans>
                                    </Form.Control.Feedback>
                                    <Form.Text className="text-muted">
                                        <Trans>
                                            Make sure that the destination wallet address is correct.
                                        </Trans>
                                        <Trans>
                                            Once transferred the stamp will be owned by the new wallet
                                            and will be under that wallet's control.
                                        </Trans>
                                    </Form.Text>
                                </Form.Group>
                                <Form.Group controlId="formTransferAckCheckbox">
                                    <Form.Check required type="checkbox">
                                        <Form.Check.Input
                                            disabled={state.transferStarted || !state.transferTo}
                                            onChange={formTransferAckCheckboxChange.bind(this)}
                                        />
                                        <Form.Check.Label>
                                            <FontAwesomeIcon
                                                icon={faExclamationTriangle}
                                                className="mr-1"
                                            />
                                            <Trans>
                                                I understand that by transferring this stamp to the
                                                above address the ownership of the stamp will change.
                                            </Trans>
                                        </Form.Check.Label>
                                    </Form.Check>
                                </Form.Group>
                                <Button
                                    variant="primary"
                                    type="submit"
                                    disabled={
                                        state.transferStarted ||
                                        !state.transferTo ||
                                        !state.transferAck
                                    }
                                    onClick={transferStamp.bind(this)}
                                >
                                    <Trans>Execute Transfer</Trans>
                                    <Spinner
                                        as="span"
                                        size="sm"
                                        variant="light"
                                        hidden={!state.transferStarted}
                                        animation="border"
                                        role="status"
                                        className="ml-1"
                                    >
                                        <span className="sr-only">
                                            <Trans>Transferring...</Trans>
                                        </span>
                                    </Spinner>
                                </Button>
                                <Alert
                                    variant="danger"
                                    className="mt-2"
                                    hidden={!state.transferError}
                                >
                                    <Alert.Heading>
                                        <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                                        <Trans>Tranfer Error</Trans>
                                    </Alert.Heading>
                                    <Trans>
                                        The stamp could not be transferred. Please make sure that:
                                    </Trans>
                                    <ul>
                                        <li>
                                            <Trans>
                                                the transaction is properly confirmed in your wallet,
                                            </Trans>
                                        </li>
                                        <li>
                                            <Trans>
                                                the destination address can receive ERC-721 tokens,
                                            </Trans>
                                        </li>
                                        <li>
                                            <Trans>
                                                enough gas is provided for executing the transfer
                                                transaction.
                                            </Trans>
                                        </li>
                                    </ul>
                                    <div>
                                        <small className="text-break">{state.transferError}</small>
                                    </div>
                                </Alert>
                            </Form>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Container>
    );
};

export default EventHistory;
