import React from 'react';
import { withTranslation, Trans } from 'react-i18next'
import UNCSBrand from './styles/UNCSBrand';

class XTrans extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    stringify(val) {
        if(Array.isArray(val)) {
            return val.map(el => {
                if(typeof el === 'object') {
                    return '<' + el.type + '/>';
                }
                // if(typeof el === 'string') {
                    return el;
                // }
            }).join('');
        } else {
            return val;
        }
    }

    render() {
        const comps = this.props.components || {};
        comps.uncs = <UNCSBrand/>;
        comps.uncss = <UNCSBrand plural={true}/>;
        comps.uncsb = <UNCSBrand brand={true}/>;
        // console.log('debugging: ',this.props.components? this.props.components.lenght:'no components' ,this.props.components, this.stringify(this.props.children))
        return <Trans 
            components={comps}
            i18nKey={this.stringify(this.props.children)}
            values={this.props.values}
        />
    }
}

export default withTranslation()(XTrans);