import React, { forwardRef } from "react";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSlash,
  faQuestion,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { faEthereum } from "@fortawesome/free-brands-svg-icons";
import { withTranslation, Trans } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { useCryptoStamp } from "../utils/provider/stamp";

const BlockchainButton = forwardRef((props, ref) => {
  const { chainId, account } = useCryptoStamp();

  const handleConnectedClick = () => {
    if (!props.inactive === true) {
      if (chainId === -1) {
        props.history.push(process.env.PUBLIC_URL + "/network");
      } else {
        props.history.push(process.env.PUBLIC_URL + "/wallet");
      }
    }
  };

  const { t } = props;
  
  /**
   * ToDo: needs to be refactored
   */
  let btnClass = "border-warning btn-secondary";
  let btnAltText = "No Ethereum provider detected";
  let slashVisible = true;
  let questionVisible = false;
  let checkVisible = false;
  if (account && account !== -1 && chainId !== -1) {
    btnClass = "border-success btn-secondary";
    slashVisible = false;
    checkVisible = true;
    btnAltText = "Wallet is connected";
  } else if (chainId !== -1) {
    btnClass = "border-info btn-secondary";
    slashVisible = false;
    btnAltText = "Wallet is not connected";
    questionVisible = true;
  }

  const cursor = props.inactive === true ? "default" : "pointer";
  

  return (
    <OverlayTrigger
      placement={props.placement}
      overlay={
        <Tooltip>
          <span hidden={
              props.inactive === true || (chainId !== -1 || account !== -1)
            }>
            <Trans>
              Your browser is not connected to the blockchain. Please click this
              button to read the instructions on how to connect your browser to
              the blockchain.
            </Trans>
          </span>
          <span
            hidden={
              props.inactive === true || !(chainId !== -1 && account === -1)
            }>
            <Trans>
              Your browser is connected to the blockchain, but your wallet is
              not connected to this page. Please click this button to learn
              more.
            </Trans>
          </span>
          <span hidden={props.inactive === true || chainId === -1 || account === -1}>
            <Trans>
              You are connected to the blockchain! Click this button to access
              your wallet and activate or see your stamps.
            </Trans>
          </span>
          <span hidden={props.inactive !== true}>
            <Trans>Click on the button in the menu bar to learn more!</Trans>
          </span>
        </Tooltip>
      }
    >
      <Button
        ref={ref}
        aria-label={t(
          "Button that shows a modal dialog containing connection parameters to the Ethereum blockchain on hover."
        )}
        onClick={handleConnectedClick}
        className={btnClass}
        style={{ fontSize: "14px", paddingBottom: "0px", cursor: `${cursor}` }}
        alt={btnAltText}
      >
        <span className="fa-stack">
          <FontAwesomeIcon
            icon={faEthereum}
            className="fa-stack-2x"
            style={{ fontSize: "14px" }}
          />
          <FontAwesomeIcon
            icon={faSlash}
            className="fa-stack-2x text-warning"
            visibility={slashVisible ? "" : "hidden"}
            style={{ fontSize: "14px" }}
          />
          <FontAwesomeIcon
            icon={faQuestion}
            className="fa-stack-1x text-info"
            visibility={questionVisible ? "" : "hidden"}
            style={{ fontSize: "16px" }}
          />
          <FontAwesomeIcon
            icon={faCheck}
            className="fa-stack-1x text-success"
            visibility={checkVisible ? "" : "hidden"}
            style={{ fontSize: "16px" }}
          />
        </span>
      </Button>
    </OverlayTrigger>
  );
});

export default withRouter(withTranslation()(BlockchainButton));
