import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {CollectionSeries1} from "./Collection";
import {DesignAndMintSeries1} from "./DesignAndMint";

export const Series1 = () => {
    return (
        <section className="mt-3" data-aos="zoom-out">
            <Container>

                <Col>
                    <Row>
                        <CollectionSeries1/>
                    </Row>
                    <Row>
                        <DesignAndMintSeries1/>
                    </Row>
                </Col>

            </Container>
        </section>
    );
};
