import { STAMP_URL_BASE, VERSIONS } from "../../utils/web3/const";

export const SDG_TEXTS = [
  {
    // GOAL 1
    name: "End poverty in all its forms everywhere",
    // https://unsplash.com/photos/-F9NSTwlnjo
    ariaLabelImage: "A girl leaning on the armrest of a sofa",
    color: "#e5243b",
    textcolor: "white",
    linkword: "poverty",
  },
  {
    // GOAL 2
    name: "End hunger, achieve food security and improved nutrition and promote sustainable agriculture",
    // https://unsplash.com/photos/3EkT6xb4K9w
    ariaLabelImage:
      "A group of children of all ages sitting and standing in or near a wide gap in a brick wall.",
    color: "#DDA63A",
    textcolor: "white",
    linkword: "hunger",
  },
  {
    // GOAL 3
    name: "Ensure healthy lives and promote well-being for all at all ages",
    // https://www.rawpixel.com/image/379100/free-photo-image-ice-pack-arthriti-injury
    ariaLabelImage: "A person pressing a blue bag against someone's knee",
    color: "#4C9F38",
    textcolor: "white",
    linkword: "health",
  },
  {
    // GOAL 4
    name: "Ensure inclusive and equitable quality education and promote lifelong learning opportunities for all",
    // https://unsplash.com/photos/NsPDiPFTp4c
    ariaLabelImage: "Girl writing on a chalk board mounted on a wall",
    color: "#C5192D",
    textcolor: "white",
    linkword: "education",
  },
  {
    // GOAL 5
    name: "Achieve gender equality and empower all women and girls",
    // https://unsplash.com/photos/2qOSbokGXeU
    ariaLabelImage: "Girl wearing a read tank top carying a baby",
    color: "#FF3A21",
    textcolor: "white",
    linkword: "gender-equality",
  },
  {
    // GOAL 6
    name: "Ensure availability and sustainable management of water and sanitation for all",
    // https://www.pexels.com/photo/man-drinking-water-from-drinking-fountain-1362909/
    ariaLabelImage: "Man drinking water from a fountain with his hands",
    color: "#26BDE2",
    textcolor: "white",
    linkword: "water-and-sanitation",
  },
  {
    // GOAL 7
    name: "Ensure access to affordable, reliable, sustainable and modern energy for all",
    // https://www.pexels.com/photo/beach-blur-clouds-dawn-462030/
    ariaLabelImage:
      "Orange mason jar containing a light guirland in a body of water against a sunset background",
    color: "#FCC30B",
    textcolor: "white",
    linkword: "energy",
  },
  {
    // GOAL 8
    name: "Promote sustained, inclusive and sustainable economic growth, full and productive employment and decent work for all",
    // no source found
    ariaLabelImage:
      "Two women standing and sitting at a desk against a background of potted plants in a black metal shelf unit",
    color: "#A21942",
    textcolor: "white",
    linkword: "economic-growth",
  },
  {
    // GOAL 9
    name: "Build resilient infrastructure, promote inclusive and sustainable industrialization and foster innovation",
    // https://www.pexels.com/photo/woman-wears-yellow-hard-hat-holding-vehicle-part-1108101/
    ariaLabelImage:
      "Woman wearing a yellow hard hat and holding a vehicle part in an industrial setting",
    color: "#FD6925",
    textcolor: "white",
    linkword: "infrastructure-industrialization",
  },
  {
    // GOAL 10
    name: "Reduce inequality within and among countries",
    // https://unsplash.com/photos/0nkFvdcM-X4
    ariaLabelImage:
      "Woman standing on the right side of a person in a wheelchair, near a green grass field",
    color: "#DD1367",
    textcolor: "white",
    linkword: "inequality",
  },
  {
    // GOAL 11
    name: "Make cities and human settlements inclusive, safe, resilient and sustainable",
    //
    ariaLabelImage: "A high-rise building with balconies covered in plants",
    color: "#FD9D24",
    textcolor: "white",
    linkword: "cities",
  },
  {
    // GOAL 12
    name: "Ensure sustainable consumption and production patterns",
    ariaLabelImage:
      "A larger piece of melting ice against a background of black sand and a swirling sea",
    color: "#BF8B2E",
    textcolor: "white",
    linkword: "sustainable-consumption-production",
  },
  {
    // GOAL 13
    name: "Take urgent action to combat climate change and its impacts", // https://unsplash.com/photos/Pp-zoKs3pXQ
    ariaLabelImage:
      "Humpback whale fluking as it dives against a background of mountains covered in snow and ice",
    color: "#3F7E44",
    textcolor: "white",
    linkword: "climate-change",
  },
  {
    // GOAL 14
    name: "Conserve and sustainably use the oceans, seas and marine resources for sustainable development",
    // https://unsplash.com/photos/FY8d721UO_4
    ariaLabelImage: "A swimming penguin from below in clear blue water",
    color: "#0A97D9",
    textcolor: "white",
    linkword: "oceans",
  },
  {
    // GOAL 15
    name: "Protect, restore and promote sustainable use of terrestrial ecosystems, sustainably manage forests, combat desertification, and halt and reverse land degradation and halt biodiversity loss",
    // https://unsplash.com/photos/ZADzPWkxSrE
    ariaLabelImage:
      "A group of elephants near a tree in knee-deep green vegetation",
    color: "#56C02B",
    textcolor: "white",
    linkword: "biodiversity",
  },
  {
    // GOAL 16
    name: "Promote peaceful and inclusive societies for sustainable development, provide access to justice for all and build effective, accountable and inclusive institutions at all levels",
    ariaLabelImage:
      "An Art Deco street lamp and a Corinthian column that is part of a representative building",
    color: "#00689D",
    textcolor: "white",
    linkword: "peace-justice",
  },
  {
    // GOAL 17
    name: "Strengthen the means of implementation and revitalize the global partnership for sustainable development",
    // https://unsplash.com/photos/DNkoNXQti3c
    ariaLabelImage: "Multiple hands grasping a wooden stem from both sides.",
    color: "#19486A",
    textcolor: "white",
    linkword: "globalpartnerships",
  },
];

export const assetsByVersion = (version) => {
  const typeOfStamp = STAMP_URL_BASE[version];

  const stampAssets = {
    [VERSIONS.V1]: {
      svgIcon: (num) => `./images/assets/SDG/icons/mini-sdg${num}.svg`,
      infoLink: (order) =>
        `https://www.un.org/sustainabledevelopment/${SDG_TEXTS[order].linkword}`,
      iconPath: (num) =>
        `./images/assets/SDG/E_SDG_Icons-${String(num).padStart(2, 0)}.jpg`,
      backgroundImageURL: (number) =>
        `url('./images/assets/SDG/img-goal-${number}.jpg')`,
      imageGoal: (number) => `./images/assets/SDG/img-goal-${number}.jpg`,
      stampImage: (designIdentifier, location) =>
        `./images/${typeOfStamp}/${typeOfStamp}-${location}-${designIdentifier}.jpg`,
    },
    [VERSIONS.V2]: {
      stampImage: (designIdentifier, location) =>
        `./images/${typeOfStamp}/${typeOfStamp}-${location}-${designIdentifier}.png`
    },
  };
  return stampAssets[version];
};

export const STAMP_NAME = {
  [VERSIONS.V1]: "SDG",
  [VERSIONS.V2]: "Design",
};

export const _getDesignName = (version, designNumber) => {
  if (version === VERSIONS.V1) return `Goal ${designNumber}`;

  if (version === VERSIONS.V2) {
    // eslint-disable-next-line default-case
    switch(designNumber) {
      case 0:
         return "Limited";
      case 1:
         return "Arabic";
      case 2:
         return "Chinese"
      case 3:
         return "English";
      case 4:
         return "French";
      case 5:
         return "Russian";
      case 6:
         return "Spanish";
   } 
    // if (designNumber === 0) return "Limited";
    // return `D${designNumber}`;
  }
};
