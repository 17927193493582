import React from 'react';
import './EthAddress.css';
import Truncate from '../Truncate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import './EthTx.css';
import { Trans } from 'react-i18next';

class EthTx extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return <a href={`${process.env.REACT_APP_ETHERSCAN_BASE_URL}/tx/${this.props.tx}`} target="_blank" rel="noopener noreferrer">
            <span className="EthTx">{Truncate.tx(this.props.tx)}</span> <FontAwesomeIcon icon={faExternalLinkAlt} />
            {this.props.suffix === true ?
                <span>&nbsp;(<Trans>View transaction on etherscan.io</Trans>)</span>
                :
                ""
            }            
        </a>    
    }
}

export default EthTx;