export const VERSIONS = {
  V1: "V1",
  V2: "V2",
  INVALID: "INVALID"
};


export const STAMP_URL_BASE = {
    [VERSIONS.V1] : "UNCS1",
    [VERSIONS.V2] : "UNCS2",
}


export const NUMBER_OF_OFFICE = 3; 
export const VERSION_1_URL_WALLETS =`/${STAMP_URL_BASE[VERSIONS.V1]}/wallets/`;
export const VERSION_2_URL_WALLETS =`/${STAMP_URL_BASE[VERSIONS.V2]}/wallets/`;


export const STAMP_BOUNDARY = {
   [VERSIONS.V1] : {
      LOWER_BOUND : 1,
      HIGHER_BOUND: 51
   },
   [VERSIONS.V2] : {
      LOWER_BOUND : 52,
      HIGHER_BOUND: 69
   },
}

export const START_BLOCK = {
   [VERSIONS.V1] : process.env.REACT_APP_ETH_CONTRACT_START_BLOCK,
   [VERSIONS.V2] : process.env.REACT_APP_ETH_CONTRACT_2_START_BLOCK
}

export const CONTRACT_ADDRESS = {
   [VERSIONS.V1] : process.env.REACT_APP_ETH_CONTRACT,
   [VERSIONS.V2] : process.env.REACT_APP_ETH_CONTRACT_2
}

export const VERSION_1_NUMBER_OF_VARIATION = 51; //stamp across all  offices
export const VERSION_2_NUMBER_OF_VARIATION = 18;  //stamp across all  offices

export const NUMBER_OF_VARIATION = {
   [VERSIONS.V1] : VERSION_1_NUMBER_OF_VARIATION,
   [VERSIONS.V2] : VERSION_2_NUMBER_OF_VARIATION
}

export const NUMBER_OF_DESIGNS = {
   [VERSIONS.V1] : 17, //unique stamp  sdg1,sdg2 .........
   [VERSIONS.V2] : 6  //unique stamp  D1,D2 .........
} 




export const STAMP_PREFIX = {
   [VERSIONS.V1] : "S",
   [VERSIONS.V2] : "D",
}

export const getStampDesignIdentifierMapper = (version, designNumber) => {
   if (version === VERSIONS.V1)   return `${STAMP_PREFIX[VERSIONS.V1]}${designNumber}`;
 
   if (version === VERSIONS.V2) {
      // eslint-disable-next-line default-case
      switch(designNumber) {
         case 0:
            return "Limited";
         case 1:
            return "Arabic";
         case 2:
            return "Chinese"
         case 3:
            return "English";
         case 4:
            return "French";
         case 5:
            return "Russian";
         case 6:
            return "Spanish";
      }
      // return `${STAMP_PREFIX[VERSIONS.V2]}${designNumber}`;
   }
 };
 