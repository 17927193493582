import React from "react";
import {Col, Row, Table} from "react-bootstrap";
import {Trans, useTranslation} from "react-i18next";
import XTrans from "../../XTrans";
import {faCheck} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {DesignAndMintSeries2} from "./DesignAndMint";
import Design from "../Series2/Design";

export const CollectionSeries2 = () => {
    const {t} = useTranslation();

    return (
        <Col>
            <h4>
                <Trans>UN Crypto Stamps Series 2 Collection</Trans>
            </h4>
            <Row>
                <Col lg={6}>
                    <h6>
                        <Trans>Issuing Offices</Trans>
                    </h6>
                    <p>
                        <XTrans>
                            The <uncs /> (Series II)  have three different designs featuring symbols of peace and the word “peace” in six languages.
                        </XTrans>
                    </p>
                    <p>
                        <Trans>
                            The total print quantity is 90,000 stamps. There are 30,000 per
                            each issuing office for New York, Geneva and Vienna.
                        </Trans>
                    </p>
                </Col>
                <Col lg={6} className="pt-3">
                    <div className="text-center">
                        <img
                            src="./images/UNCS2/UNCS2-NewYork.png"
                            alt={t(
                                "Canceled UN crypto stamp, New York, light white to blue gradient stamp on red background with white dots arranged in concentric circles"
                            )}
                            style={{
                                minWidth: 140,
                            }}
                            width={"31%"}
                            className="pb-2"
                        />
                        <img
                            src='./images/UNCS2/UNCS2-Geneva.png'
                            alt={t(
                                "Canceled UN crypto stamp,Geneva, white to pink gradient stamp on purple background with white dots arranged in concentric circles"
                            )}
                            style={{
                                minWidth: 140,
                            }}
                            width={"31%"}
                            className="pb-2"
                        />
                        <img
                            src='./images/UNCS2/UNCS2-Vienna.png'
                            alt={t(
                                "Canceled UN crypto stamp, Vienna, yellow stamp on blue background with white dots arranged in concentric circles"
                            )}
                            style={{
                                minWidth: 140,
                            }}
                            width={"31%"}
                            className="pb-2"
                        />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="mt-2">
                    <h6>
                        <Trans>Variations</Trans>
                    </h6>
                    <p>
                        <XTrans>
                            Since there are three issuing offices and 7 designs, there are 21
                            variations of the <uncsb/> in the collection.
                        </XTrans>
                    </p>
                    <p>
                        <Trans>
                            You are encouraged to find and collect all 21 variations!
                        </Trans>
                    </p>
                    <p>
                        <Trans>
                            Two percent (1/50th) of all stamps bear the limited design. All other stamp designs exist in equal 
                            quantities and are arranged sequentially from Design 1 to Design 6.
                        </Trans>
                    </p>
                </Col>
            </Row>
            <Row>
                <Col md={0} lg={1}/>
                <Col className="mt-4">
                    <Table striped bordered hover size="sm">
                        <thead>
                        <tr>
                            <th colSpan={2}>
                                <div className="float-right">
                                    <Trans>Issuing Office</Trans>
                                    <br/>
                                    <Trans>Language</Trans>
                                    <br/>
                                    <Trans>Denomination</Trans>
                                </div>
                            </th>
                            <th className="text-center">
                                <Trans>New York</Trans>
                                <br/>
                                <Trans>English</Trans>
                                <br/>
                                USD
                            </th>
                            <th className="text-center">
                                <Trans>Geneva</Trans>
                                <br/>
                                <Trans>French</Trans>
                                <br/>
                                CHF
                            </th>
                            <th className="text-center">
                                <Trans>Vienna</Trans>
                                <br/>
                                <Trans>German</Trans>
                                <br/>
                                EUR
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {Design.range().map((num) => {
                            return (
                                <tr key={`stamp-tbl-row-sdg-${num}`}>
                                    <td className="text-center">
                                        <Design num={num} display="mini"/>
                                    </td>
                                    <td>
                                        <Design num={num}/>
                                    </td>
                                    <td className="text-center">
                                        <FontAwesomeIcon icon={faCheck}/>
                                    </td>
                                    <td className="text-center">
                                        <FontAwesomeIcon icon={faCheck}/>
                                    </td>
                                    <td className="text-center">
                                        <FontAwesomeIcon icon={faCheck}/>
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </Table>
                </Col>
                <Col md={0} lg={1}/>
            </Row>
            <Row>
                <DesignAndMintSeries2/>
            </Row>
        </Col>
    );
};
