import React from "react";
import {Image} from "react-bootstrap";
import {Trans} from "react-i18next";

class Design extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            index: props.num,
            display: props.display,
            //   mini: `${ICON_SVG_PREFIX[VERSIONS.V2]}${props.num}.svg`,
            //   icon: `${ICON_NAME_PREFIX[VERSIONS.V2]}-${
            //     props.num < 10 ? "0" + props.num : props.num
            //   }.jpg`,
            //   image: `/images/assets/DESIGN/img-d-${props.num}.jpg`,
        };
    }


    static range() {
        return [0, 1, 2, 3, 4, 5, 6];
    }


    render() {
        const designs = [
            {
                num: 0,
                design: <Trans>Limited</Trans>,
            },
            {
                num: 1,
                design: <Trans>Arabic</Trans>,
            },
            {
                num: 2,
                design: <Trans>Chinese</Trans>,
            },
            {
                num: 3,
                design: <Trans>English</Trans>,
            },
            {
                num: 4,
                design: <Trans>French</Trans>,
            },
            {
                num: 5,
                design: <Trans>Russian</Trans>,
            },
            {
                num: 6,
                design: <Trans>Spanish</Trans>,
            },
        ];
        switch (this.state.display) {
            case "mini":
                return (
                    <Image
                        // src={this.state.mini}
                        style={{width: "auto", height: 24, maxWidth: 36}}
                    />
                );
            case "text":
            default:
                return (
                    <span className="SDG">
                        {designs[this.state.index].design}
                    </span>
                );
        }
    }
}

export default Design;
