import React, { Component } from "react";
import {
  Form,
  Navbar,
  Nav,
  FormControl,
  Container,
  Image,
} from "react-bootstrap";
import { withRouter } from "react-router";
import { withTranslation, Trans } from "react-i18next";
import UNCSBrand from "./styles/UNCSBrand.js";
import { NavLink } from "react-router-dom";
import BlockchainButton from "./BlockchainButton.js";
import { STAMP_URL_BASE } from "../utils/web3/const.js";
import web3Handler from "../utils/web3/Web3Handler.js";

class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validSearch: false,
      searchFieldValid: true,
      searchFieldEmpty: true,
      navigateTo: "undef",
    };
    this.handleSearchStamp = this.handleSearchStamp.bind(this);
  }

  handleSearchFieldChange(event) {
    this.setState({
      validSearch: false,
    });

    if (event.target.value && event.target.value.trim() !== "") {

      if (event.target.value.length < 2) return;

      const validatedStampId = web3Handler.getDecodedStamp(
        event.target.value.trim(),
      );

      console.log(validatedStampId);

      this.setState({
        searchFieldValid: validatedStampId.valid,
        searchFieldStampId: validatedStampId.stampId,
        validSearch: validatedStampId.valid,
      });

      if (validatedStampId.valid) {
        this.props.history.push({
          pathname: `${process.env.PUBLIC_URL}/${
            STAMP_URL_BASE[validatedStampId.version]
          }/${event.target.value.trim()}`,
          state: {
            stampId: event.target.value.trim(),
          },
        });
      }
    } else {
      this.setState({
        // empty field is valid
        searchFieldValid: true,
      });
    }
  }

  handleSearchStamp() {
    if (this.state.searchFieldValid) {
      const baseURL = web3Handler.getDecodedStamp(
        this.state.searchFieldStampId,
      ).stampBaseURL;
      this.props.history.push({
        pathname: `${process.env.PUBLIC_URL}/${baseURL}/${this.state.searchFieldStampId}`,
        state: {
          stampId: this.state.searchFieldStampId,
        },
      });
      this.setState({
        navigateTo: "undef",
      });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <Navbar bg="light" expand="lg" className="pr-0 pt-0 pb-0">
          {/*
        <Navbar.Brand className="">
          <a href="https://www.un.org/en" target="_blank" rel="noopener noreferrer" 
            style={{borderRight: "black solid 1px", 
                    paddingRight: "15px", 
                    boxSizing: "border-box",
                    textSizeAdjust: "100%",
                    height: "60px", 
                    display: "block",
                    lineHeight: "60px"}}>
            <img
            alt="United Nations emblem"
            src="./images/assets/un-emblem.svg"
            width="auto"
            height="60"
            className="d-inline-block align-middle pr-1" />
          </a>
        </Navbar.Brand>
        */}
          <Navbar.Brand className="d-none d-sm-block">
            <a
              href="https://unstamps.org"
              target="_blank"
              rel="noopener noreferrer"
              style={{
                borderRight: "black solid 1px",
                paddingRight: "15px",
                boxSizing: "border-box",
                textSizeAdjust: "100%",
                height: "60px",
                display: "block",
                lineHeight: "60px",
              }}
            >
              <Image
                src="./images/assets/unstamps-logo.jpg"
                width="auto"
                height="60"
                alt={t(
                  "The logo of the United Nations Postal Administation. It is showing an iconized blue stamp with the UN emblem in white. Next to it in large fonts above each other the words UN and STAMPS.",
                )}
                className="d-inline-block align-middle mr-1"
              />
            </a>
          </Navbar.Brand>
          <Navbar.Brand
            href={process.env.PUBLIC_URL}
            style={{ verticalAlign: "middle" }}
          >
            <div>
              <Image
                width="auto"
                height="60"
                src={"./images/assets/UNPA-pigeon-UN-emblem.png"}
                className="nav-un-crypto-brand-img d-inline-block align-middle mr-3"
                alt={t(
                  "Brand logo of the UN crypto stamp showing an iconized light blue pigeon on a white background with the UN emblem on its wing, viewed from the side. The pigeon is holding an olive branch in its mouth.",
                )}
              />
              <span className="nav-un-crypto-brand align-middle">
                <UNCSBrand brand />
              </span>
            </div>
          </Navbar.Brand>
        </Navbar>
        <Navbar bg="dark" className="un-blue-border-bottom">
          <Container>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <NavLink
                  to={`${process.env.PUBLIC_URL}/`}
                  style={{ color: "white" }}
                  className="nav-link"
                >
                  <Trans>Home</Trans>
                </NavLink>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/un-stamp-series1`}
                  style={{ color: "white" }}
                  className="nav-link"
                >
                  <Trans>Series 1</Trans>
                </NavLink>
                <NavLink
                  to={`${process.env.PUBLIC_URL}/wallet`}
                  style={{ color: "white" }}
                  className="nav-link mr-3"
                >
                  <Trans>Wallet</Trans>
                </NavLink>
                <Form>
                  <FormControl
                    isInvalid={!this.state.searchFieldValid}
                    onChange={this.handleSearchFieldChange.bind(this)}
                    aria-label={t(
                      "Form field to enter the ID of a UN crypto stamp.",
                    )}
                    type="text"
                    placeholder={t("Enter Stamp ID")}
                    className="mr-sm-2 nav-stamp-search-input"
                  />
                  {/* <Button onClick={this.handleSearchStamp} disabled={!this.state.validSearch} variant="outline-info">
                      <span className="d-lg-none"><FontAwesomeIcon icon={faSearch} /></span>
                      <span className="d-none d-lg-block"><FontAwesomeIcon icon={faSearch} />&nbsp;<Trans>Search</Trans></span>
                    </Button>
                  */}
                </Form>
              </Nav>
              <Form>
                <Nav.Item>
                  <BlockchainButton placement="left" />
                </Nav.Item>
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default withRouter(withTranslation()(NavbarComponent));
