import React from "react";
import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import web3Handler from "../../web3/Web3Handler";

export const CryptoStampContext = createContext(null);
export const CryptoStampConsumer = CryptoStampContext.Consumer;

export function CryptoStampProvider(props) {
  const { children } = props;

  const [blockChainState, setBlockChainState] = useState({
    chainId: -1,
    account: -1,
  });

  const [stampDetails, setStampDetails] = useState({
    ownedStampsV1: [],
    ownedStampsV2: [],
  });

  const [currentWallet, setCurrentWallet] = useState({
    balance: 0,
    you: false,
    address: -1,
  });

  const subscribeToEthereumEvent = useCallback(() => {
    web3Handler.subscribeToEthAccountChange(
      blockChainState.account,
      (address) => {
        setBlockChainState({
          account: address,
        });
        loadBalanceAndInitWallet(address);

        loadOwnedStamps(address);
      },
    );

    web3Handler.subscribeToChainChange(blockChainState.chainId, (chainId) => {
      web3Handler.initWeb3AndGetAccount((chainId, account) => {
        loadBalanceAndInitWallet(account);
        setBlockChainState({
          chainId: chainId,
          account: account,
        });
        loadOwnedStamps(account);
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockChainState.account, blockChainState.chainId]);

  //stamp
  const loadOwnedStamps = async (walletAddress) => {
    await web3Handler.loadOwnedStamps(
      walletAddress,
      (ownedStampsV1, ownedStampsV2) => {
        setStampDetails({
          ownedStampsV1: ownedStampsV1 || [],
          ownedStampsV2: ownedStampsV2 || [],
        });
      },
    );
  };

  // Wallets
  const loadBalanceAndInitWallet = useCallback(
    async (walletAddress) => {
      try {
        const balance = await web3Handler.loadBalanceAndInitWallet(
          walletAddress,
        );
        setCurrentWallet({
          ...currentWallet,
          balance,
          address: walletAddress,
          you: walletAddress === blockChainState.account,
        });
      } catch (error) {
        throw error;
      }
    },
    [blockChainState.account, currentWallet],
  );

  useEffect(() => {
    if (window.ethereum) {
      web3Handler.initWeb3AndGetAccount((chainId, account) => {
        loadBalanceAndInitWallet(account);

        setBlockChainState({
          chainId: chainId,
          account: account,
        });
        // subscribe to ethereum events
        subscribeToEthereumEvent();
      });
    } else {
      // not if (window.ethereum) {
      setBlockChainState({
        chainId: -1,
        account: -1,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CryptoStampContext.Provider
      value={{
        ...blockChainState,
        loadBalanceAndInitWallet,
        loadOwnedStamps,
        stampDetails,
        currentWallet,
      }}
    >
      {children}
    </CryptoStampContext.Provider>
  );
}

export function useCryptoStamp() {
  const ctx = useContext(CryptoStampContext);
  if (!ctx) {
    throw new Error("Cannot useCryptoStamp() outside <CryptoStampProvider />");
  }
  return ctx;
}
