import {Alert, Col, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationTriangle} from "@fortawesome/free-solid-svg-icons";
import {Trans} from "react-i18next";
import React from "react";

export function AlreadyExistsError() {
    return <Row>
        <Col className="pl-0">
            <Alert variant="danger">
                <FontAwesomeIcon icon={faExclamationTriangle}/>{" "}
                <Trans>This stamp has already been activated.</Trans>
            </Alert>
        </Col>
    </Row>;
}