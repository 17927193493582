export default class Truncate {
    static shortWallet(address) {
        return this.middle(address, 10);
    }
    static wallet(address) {
        return this.middle(address, 15);
    }
    static tx(address) {
        return this.middle(address, 15);
    }
    static middle(fullStr, strLen, separator) {
        if (fullStr.length <= strLen) return fullStr;        
        separator = separator || '...';        
        var sepLen = separator.length,
            charsToShow = strLen - sepLen,
            frontChars = Math.ceil(charsToShow/2),
            backChars = Math.floor(charsToShow/2);
        
        return fullStr.substr(0, frontChars) + 
                separator + 
                fullStr.substr(fullStr.length - backChars);
    }
}