import React from 'react';
import { Button, InputGroup, FormControl, Col, Row, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import UNCSBrand from './styles/UNCSBrand';
import * as bip39 from "bip39";
import { hdkey } from 'ethereumjs-wallet';
import ReactGA from 'react-ga';

class DemoWalletCalc extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    generateRandomMnemonic = async () => {
        const path = "m/44'/60'/0'/0/0"; // default wallet (account 1 in metamask)
        var mnemonic = bip39.generateMnemonic();         
        var seed = await bip39.mnemonicToSeed(mnemonic);
        var hdwallet = hdkey.fromMasterSeed(seed);
        var wallet = hdwallet.derivePath(path).getWallet();
        this.setState({
            mnemonic,
            seed: seed.toString('hex'),   
            privateKey: wallet.privateKey.toString('hex'),
            publicKey: wallet.pubKey.toString('hex'),
            address: wallet.getAddress().toString('hex')
        })
        ReactGA.event({
            category: 'Behind The Scenes',
            action: 'Try out wallet demo'
        });
    }

    render() {
        return <Container className="mt-3">
            <h6>Wallet Derivation</h6>
        <Row>
            <Col md={12} lg={6}>
                <p>
                    This wallet derivation demo shows how the secret code printed on the stamp is used to derive a public-private key pair and a wallet address.<br/>
                    The secret code printed on the stamp is a random value according to 
                    the <a href="https://github.com/bitcoin/bips/blob/master/bip-0039.mediawiki" target="_blank" rel="noopener noreferrer">
                        BIP39 standard <FontAwesomeIcon icon={faExternalLinkAlt}/></a> describing the use of mnemonic codes for the generation of deterministic keys.
                </p>
                <p>
                    First the code, representing 128 bits of entropy, is expanded to a 512 bits master 
                    seed using the code itself as salt for mutiple iterations of a key derivation function  
                    (also defined in BIP39).
                </p>
                <p>
                    This master seed is then used to derive a private key according to the&nbsp; 
                    <a href="https://github.com/bitcoin/bips/blob/master/bip-0032.mediawiki" target="_blank" rel="noopener noreferrer">BIP32 standard <FontAwesomeIcon icon={faExternalLinkAlt}/></a> 
                    &nbsp;on Hierarchical Deterministic Wallets. By using a pre-defined derivation path the same code can be used accross different Ethereum wallets and will always produce the same private key.
                </p>
                <p>
                    The public key is calculated from the private key. This is a one-way process, 
                    i.e. it is computationally infeasible to derive the private key corresponding 
                    to a given public key.
                </p>
                <p>
                    The wallet address is then obtained by hashing the public key and using the last 20 bytes of the output.
                </p>
            </Col>
            <Col md={12} lg={6}>                            
                    <Row className="mt-2">
                        <Col xs={5} className="pr-0 my-auto text-right" >
                            <Button onClick={this.generateRandomMnemonic.bind(this)}>
                                <small>Generate Random Mnemonic</small>                                        
                            </Button>
                        </Col>
                        <Col xs={7} className="pl-0">
                            <InputGroup>
                                <FontAwesomeIcon icon={faArrowRight} className="centered-arrow" />  
                                <InputGroup.Text className='behind-gen-label'><small>128 bits<br/>Random<br/>Mnemonic</small></InputGroup.Text>
                                <FormControl as="textarea" rows="3" aria-label="Mnemonic" className="behind-gen-textarea" value={this.state.mnemonic}/>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs={5} className="pr-0 text-center center-text-flex" >
                            <FontAwesomeIcon icon={faArrowRight} className="centered-arrow"/>
                            <div className="w-100 h-100 center-text-flex bubble-blue">
                                <div className="mt-auto mb-auto pl-2 pr-2">
                                    <small>Convert to master seed (BIP39)</small>                                                
                                </div>
                            </div>
                        </Col>
                        <Col xs={7} className="pl-0">
                            <InputGroup>
                                <FontAwesomeIcon icon={faArrowRight} className="centered-arrow"/>
                                <InputGroup.Text className='behind-gen-label'><small>512 bits<br/>Master Seed</small></InputGroup.Text>
                                <FormControl as="textarea" rows="3" aria-label="Mnemonic" className="behind-gen-textarea"  value={this.state.seed}/>
                            </InputGroup>
                        </Col>
                    </Row>                                
                    <Row className="mt-2">
                        <Col xs={5} className="pr-0 text-center center-text-flex" >
                            <FontAwesomeIcon icon={faArrowRight} className="centered-arrow"/>
                            <div className="w-100 h-100 center-text-flex bubble-blue">
                                <div className="mt-auto mb-auto pl-2 pr-2">
                                    <small>Derive private key at index 0 (BIP32)</small>                                                
                                </div>
                            </div>
                        </Col>
                        <Col xs={7} className="pl-0">
                            <InputGroup>
                                <FontAwesomeIcon icon={faArrowRight} className="centered-arrow"/>
                                <InputGroup.Text className='behind-gen-label'><small>Private Key</small></InputGroup.Text>
                                <FormControl as="textarea" rows="3" aria-label="Mnemonic" className="behind-gen-textarea"  value={this.state.privateKey}/>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs={5} className="pr-0 text-center center-text-flex" >
                            <FontAwesomeIcon icon={faArrowRight} className="centered-arrow"/>
                            <div className="w-100 h-100 center-text-flex bubble-blue">
                                <div className="mt-auto mb-auto pl-2 pr-2">
                                    <small>Calculate corresponding public key</small>                                                
                                </div>
                            </div>
                        </Col>
                        <Col xs={7} className="pl-0">
                            <InputGroup>
                                <FontAwesomeIcon icon={faArrowRight} className="centered-arrow"/>
                                <InputGroup.Text className='behind-gen-label'><small>Public Key</small></InputGroup.Text>
                                <FormControl as="textarea" rows="3" aria-label="Mnemonic" className="behind-gen-textarea"  value={this.state.publicKey}/>
                            </InputGroup>
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col xs={5} className="pr-0 text-center center-text-flex" >
                            <FontAwesomeIcon icon={faArrowRight} className="centered-arrow"/>
                            <div className="w-100 h-100 center-text-flex bubble-blue">
                                <div className="mt-auto mb-auto pl-2 pr-2">
                                    <small>Hash public key and retain last 20 bytes</small>                                                
                                </div>
                            </div>
                        </Col>
                        <Col xs={7} className="pl-0">
                            <InputGroup>
                                <FontAwesomeIcon icon={faArrowRight} className="centered-arrow"/>
                                <InputGroup.Text className='behind-gen-label'><small>Wallet<br/>address</small></InputGroup.Text>
                                <FormControl as="textarea" rows="3" aria-label="Mnemonic" className="behind-gen-textarea"  value={this.state.address}/>
                            </InputGroup>
                        </Col>
                    </Row>
                </Col>                        
            </Row>
            <Row className="mt-3">
                <Col>
                    The calculation demonstrates that there is a deterministic path from a randomly generated code to a wallet address that corresponds
                    to a public-private key pair, which is why the <UNCSBrand/> carries only both ends of the path, the one being used to bootstrap the
                    calcuation and the other to confirm the identity of the cryptographic secret.
                </Col>
            </Row>
        </Container>

    }

}

export default DemoWalletCalc;