import React from "react";

import {
    faExclamationTriangle,
    faExternalLinkSquareAlt,
} from "@fortawesome/free-solid-svg-icons";
import {Alert, Row, Col} from "react-bootstrap";
import {Trans} from "react-i18next";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useCryptoStamp} from "src/utils/provider/stamp/Provider";

export function InsufficientBalance({you}) {
    const cryptoStamp = useCryptoStamp();

    return (
        <Alert
            variant="warning"
            hidden={
                !you ||
                cryptoStamp.currentWallet.balance !== "0" ||
                cryptoStamp.stampDetails.ownedStampsV1.length > 0
            }
        >
            <Alert.Heading>
                <Trans>Insufficient Funding!</Trans>
            </Alert.Heading>
            <Row>
                <Col>
                    <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2"/>
                    <Trans>
                        The balance of your wallet shows insufficient funding needed to
                        activate a new stamp.
                    </Trans>
                </Col>
            </Row>
            <Row className="pt-2">
                <Col>
                    <a
                        href={"https://ethereum.org/en/get-eth/"}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="mr-1"
                    >
                        <Trans>Learn more about getting ETH and funding your wallet</Trans>
                        <FontAwesomeIcon icon={faExternalLinkSquareAlt} className="ml-2"/>
                    </a>
                    <small>
                        <Trans>(Opens in a new window, in english only)</Trans>
                    </small>
                </Col>
            </Row>
        </Alert>
    );
}
