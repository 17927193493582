import React from 'react';
import {Container, Row, Col, Button} from 'react-bootstrap';
import {Redirect} from 'react-router-dom';
import {withTranslation, Trans} from 'react-i18next'
import XTrans from '../XTrans';
import web3Handler from 'src/utils/web3/Web3Handler';

class ConnectWalletComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            chainId: props.chainId,
            account: props.account
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.chainId !== this.props.chainId || prevProps.account !== this.props.account) {
            this.setState({
                chainId: this.props.chainId,
                account: this.props.account
            });
        }
    }

    render() {
        if (!web3Handler.isConnectedToBlockChain()) {
            return <Redirect to={`${process.env.PUBLIC_URL}/network`}/>;
        } else if (this.state.account && this.state.account !== -1) {
            return <Redirect
                to={`${process.env.PUBLIC_URL}/wallet/${web3Handler.getWeb3().utils.toChecksumAddress(this.state.address)}`}/>;
        } else {
            return (
                <section>
                    <Container data-aos="flip-up">
                        <Row>
                            <Col lg={3}></Col>
                            <Col lg={6}>
                                <h3><Trans>Wallet not connected</Trans></h3>
                                <p><Trans>An Ethereum provider has been detected in your browser.</Trans></p>
                                <p><XTrans>Please click the below button to connect your wallet to the <uncs/> smart
                                    contract.</XTrans></p>
                                <p className="text-center">
                                    <Button onClick={this.handleEnableEthereum.bind(this)}><XTrans>Connect Wallet
                                        to <uncs/></XTrans></Button>
                                </p>
                            </Col>
                            <Col lg={3}></Col>
                        </Row>
                    </Container>
                </section>
            );
        }
    }

    handleEnableEthereum() {
        if (window.ethereum && this.state.chainId && this.state.chainId !== -1) {
            // window.ethereum.enable() is deprecated
            // the callback for the connected account is handled upstream in App.js
            window.ethereum.request({method: 'eth_requestAccounts'});
        }
    }
}

export default withTranslation()(ConnectWalletComponent);
