import React from "react";
import { Alert, Row, Col, Card } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import StampCardDeck from "../stamps/StampCardDeck";
import XTrans from "../XTrans";
import { Trans } from "react-i18next";
import { VERSIONS } from "src/utils/web3/const";

export const WalletStampViewCard = ({ orderBy, version, stamps }) => {
  const TextInfoByVersion = () => {
    if (version === VERSIONS.V1) {
      return <Trans>{`UN Crypto Stamps Series 1 Collection`}</Trans>;
    } else {
      return <Trans>{`UN Crypto Stamps Series 2 Collection`}</Trans>;
    }
  };

  const EmptyTextByVersion = () => {
    if (version === VERSIONS.V1) {
      return (
        <XTrans>
          This wallet doesn't contain any <uncs /> (Series 1).
        </XTrans>
      );
    } else {
      return (
        <XTrans>
          This wallet doesn't contain any <uncs /> (Series 2).
        </XTrans>
      );
    }
  };

  return (
    <>
      <Card bg="white" text="dark" className="mb-3 shadow-lg" border="info">
        <Card.Body>
          <Row>
            <Col>
              <h5 className="text-info">
                <TextInfoByVersion />
              </h5>
            </Col>
            <Col xs={12}>
              <Alert variant="info" hidden={stamps.length > 0}>
                <FontAwesomeIcon icon={faInfoCircle} />
                <EmptyTextByVersion />
              </Alert>
              <StampCardDeck
                stamps={[...stamps]}
                orderBy={orderBy}
                key={version}
                cardClassName="stamp-card-wallet"
                version={version}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};
