import React from 'react'
import {Row, Col, Table} from 'react-bootstrap';
import {Trans} from 'react-i18next';
import XTrans from '../../XTrans';

export const DesignAndMintSeries2 = () => {
    return (
        <Col>
            <h6><Trans>Design and mint</Trans></h6>
            <Row>
                <Col md={12} lg={6}>
                    <p>
                        <XTrans>The <uncs/> (series 2) consists of three mini-sheets of one stamp each in the
                            denominations of $ 4.50, CHF 3.80 and &euro; 3.65.</XTrans>
                    </p>
                    <Table striped bordered hover size="sm">
                        <tbody>
                        <tr>
                            <td><strong><Trans>Date of issue</Trans></strong></td>
                            <td><Trans>18 November 2022</Trans></td>
                        </tr>
                        <tr>
                            <td><strong><Trans>Designer</Trans></strong></td>
                            <td>Sergio Baradat (<Trans>United Nations</Trans>)</td>
                        </tr>
                        <tr>
                            <td><strong><Trans>Denominations</Trans></strong></td>
                            <td>$ 4.50, CHF 3.80, &euro; 3.65</td>
                        </tr>
                        <tr>
                            <td><strong><Trans>Stamp size</Trans></strong></td>
                            <td>25 mm x 35mm</td>
                        </tr>
                        <tr>
                            <td><strong><Trans>Sheet size</Trans></strong></td>
                            <td>84mm x 55mm</td>
                        </tr>
                        <tr>
                            <td><strong><Trans>Format</Trans></strong></td>
                            <td><Trans>Mini-sheets of one stamp</Trans></td>
                        </tr>
                        <tr>
                            <td><strong><Trans>Printer</Trans></strong></td>
                            <td>Royal Joh. Ensched&eacute; (<Trans>Netherlands</Trans>)</td>
                        </tr>
                        <tr>
                            <td><strong><Trans>Process</Trans></strong></td>
                            <td><Trans>Hexachrome</Trans></td>
                        </tr>
                        <tr>
                            <td><strong><Trans>Perforation</Trans></strong></td>
                            <td>-</td>
                        </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Col>
    )
}
