import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { VERSIONS } from 'src/utils/web3/const';
import { assetsByVersion } from '../const';

class SDG extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            index: props.num - 1,
            display: props.display,
            mini: assetsByVersion(VERSIONS.V1).svgIcon(props.num),
            icon: assetsByVersion(VERSIONS.V1).iconPath(props.num),
            image: assetsByVersion(VERSIONS.V1).imageGoal(props.num)
        };
    }

    static range() {
        return [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17]
    }

    render() {
        const SET = [{
            num: 1,
            goal: <Trans>No poverty</Trans>
        },{
            num: 2,
            goal: <Trans>Zero hunger</Trans>
        },{
            num: 3,
            goal: <Trans>Good health and well-being</Trans>
        },{
            num: 4,
            goal: <Trans>Quality education</Trans>
        },{
            num: 5,
            goal: <Trans>Gender equality</Trans>
        },{
            num: 6,
            goal: <Trans>Clean water and sanitation</Trans>
        },{
            num: 7,
            goal: <Trans>Affordable and clean energy</Trans>
        },{
            num: 8,
            goal: <Trans>Decent work and economic growth</Trans>
        },{
            num: 9,
            goal: <Trans>Industry, innovation and infrastructure</Trans>
        },{
            num: 10,
            goal: <Trans>Reduced inequalities</Trans>        
        },{
            num: 11,
            goal: <Trans>Sustainable cities and communities</Trans>
        },{
            num: 12,
            goal: <Trans>Responsible consumption and production</Trans>
        },{
            num: 13,
            goal: <Trans>Climate action</Trans>
        },{
            num: 14,
            goal: <Trans>Life below water</Trans>
        },{
            num: 15,
            goal: <Trans>Life on land</Trans>
        },{
            num: 16,
            goal: <Trans>Peace, justice and strong institutions</Trans>
        },{
            num: 17,
            goal: <Trans>Partnerships for the Goals</Trans>
        }]        
        switch(this.state.display) {
            case 'mini':
                return <Image src={this.state.mini} style={{width:'auto', height: 24, maxWidth: 36}} />
            case 'text':
            default:
                return <span className="SDG"><Trans>Goal</Trans>&nbsp;{SET[this.state.index].num} {SET[this.state.index].goal}</span>
        }
        
    }
}

export default SDG;