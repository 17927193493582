import { InsufficientBalance } from "./InsufficientBalance";
import React, { useEffect, useState } from "react";
import {
  Container,
  Alert,
  Row,
  Col,
  ButtonGroup,
  ToggleButton,
  Badge,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

import Web3 from "web3";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import { withTranslation, Trans } from "react-i18next";
import EthAddress from "../styles/EthAddress";
import { NavLink } from "react-router-dom";
import XTrans from "../XTrans";
import ReactGA from "react-ga";
import { useCryptoStamp } from "src/utils/provider/stamp";
import { VERSIONS } from "src/utils/web3/const";
import web3Handler from "../../utils/web3/Web3Handler";
import { WalletStampViewCard } from "./WalletStampViewCard";

const WalletComponent = (props) => {
  const cryptoStamp = useCryptoStamp();

  const [state, setState] = useState({
    address: props.match.params.address,
    loading: true,
    orderBy: "activation",
    balance: "0",
  });

  useEffect(() => {
    ReactGA.pageview("/wallet");
    if (web3Handler.isConnectedToBlockChain()) {
      cryptoStamp.loadBalanceAndInitWallet(props.match.params.address);
      cryptoStamp.loadOwnedStamps(props.match.params.address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.match.params.address]);

  useEffect(() => {
    if (web3Handler.isConnectedToBlockChain() && (cryptoStamp.account === -1 || !cryptoStamp.account)) {
      props.history.push("/wallet");
    }
  }, [cryptoStamp.account, props]);

  const getTitle = () => {
    switch (state.orderBy) {
      case "collection":
        return <Trans>Collected stamps</Trans>;
      case "activation":
      case "sorted":
      default:
        return <Trans>Owned stamps</Trans>;
    }
  };

  const getDescription = () => {
    switch (state.orderBy) {
      case "sorted":
        return <Trans>Sorted by properties</Trans>;
      case "collection":
        return (
          <Trans>
            Shows the full collection and the collected stamps owned by this
            wallet
          </Trans>
        );
      case "activation":
      default:
        return <Trans>In order of activation on the blockchain</Trans>;
    }
  };
  if (!web3Handler.isConnectedToBlockChain()) {
    console.log("Redirect to network", props.match.params.address);
    return (
      <Redirect
        to={`${process.env.PUBLIC_URL}/network?address=${props.match.params.address}`}
      />
    );
  }

  const radios = [
    { name: <Trans>Activation Order</Trans>, value: "activation" },
    { name: <Trans>Sorted</Trans>, value: "sorted" },
    { name: <Trans>Collection</Trans>, value: "collection" },
  ];

  let ethBalance = "...";
  if (window.ethereum) {
    const web3 = new Web3(window.ethereum);
    if (!web3.utils.isAddress(props.match.params.address)) {
      console.log(
        "Invalid address, redirect to home,",
        props.match.params.address,
      );
      return <Redirect to={`${process.env.PUBLIC_URL}/`} />;
    }
    const checksumAddress = web3.utils.toChecksumAddress(
      props.match.params.address,
    );
    if (checksumAddress !== props.match.params.address) {
      // redirect to correct checksum address
      return (
        <Redirect to={`${process.env.PUBLIC_URL}/wallet/${checksumAddress}`} />
      );
    }
    ethBalance = web3.utils.fromWei(
      cryptoStamp.currentWallet.balance + "",
      "ether",
    );
  }
  const you =
    cryptoStamp.currentWallet.you === true ? (
      <span>
        &nbsp;(<Trans>That's you</Trans>)
      </span>
    ) : (
      ""
    );
  const stampsTitle = getTitle();
  const stampsDescription = getDescription();
  return (
    <section data-aos="zoom-in" data-aos-delay="100">
      <Container>
        <Row className="pb-1">
          <Col xs={12}>
            <h5>
              <Trans>Wallet</Trans>{" "}
              <EthAddress address={props.match.params.address} />
              {you}
            </h5>
            <h6>
              <Badge variant="success" className="h3">
                <Trans>Balance</Trans> {ethBalance} ETH
              </Badge>{" "}
            </h6>
            <InsufficientBalance you={you} />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={12}>
            <h5 className="text-capitalize">{stampsTitle}</h5>
            <p
              hidden={cryptoStamp.stampDetails.ownedStampsV1.length === 0}
              className="small text-muted"
            >
              {stampsDescription}
            </p>
          </Col>
          <Col lg={6} md={12} className="justify-content-center text-center">
            <ButtonGroup
              toggle
              hidden={
                cryptoStamp.stampDetails.ownedStampsV1.length === 0 &&
                cryptoStamp.stampDetails.ownedStampsV2.length === 0
              }
              className="pb-2 wallet-btn-ctrl"
            >
              {radios.map((radio, idx) => (
                <ToggleButton
                  key={idx}
                  type="radio"
                  variant="primary"
                  className={`wallet-btn-${radio.value}`}
                  name="radio"
                  value={radio.value}
                  checked={state.orderBy === radio.value}
                  onChange={(e) => setState({ orderBy: e.currentTarget.value })}
                >
                  {radio.name}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </Col>
        </Row>
        <Row>
          <Col lg={3} md={3} className="mb-3">
            <NavLink
              to={`${process.env.PUBLIC_URL}/activate-stamp`}
              hidden={!you || cryptoStamp.currentWallet.balance === "0"}
              className="btn btn-info btn-block"
            >
              <Trans>Add Stamp</Trans>
            </NavLink>
          </Col>
        </Row>

        <Row>
          <Col>
            <Alert
              variant="success"
              hidden={
                !you ||
                cryptoStamp.stampDetails.ownedStampsV1.length > 0 ||
                cryptoStamp.currentWallet.balance === "0" ||
                cryptoStamp.stampDetails.ownedStampsV2.length > 0
              }
            >
              <FontAwesomeIcon icon={faInfoCircle} />{" "}
              <XTrans>
                Click the above button to add your first <uncs /> to your
                wallet.
              </XTrans>
            </Alert>
          </Col>
        </Row>
         
        {/* Nothing just ordering depending on the stamps -> if empty display after */}
        {cryptoStamp.stampDetails.ownedStampsV1.length === 0 ? (
          <>
            <WalletStampViewCard
              orderBy={state.orderBy}
              version={VERSIONS.V2}
              stamps={cryptoStamp.stampDetails.ownedStampsV2}
            />
            <WalletStampViewCard
              orderBy={state.orderBy}
              version={VERSIONS.V1}
              stamps={cryptoStamp.stampDetails.ownedStampsV1}
            />
          </>
        ) : (
          <>
            <WalletStampViewCard
              orderBy={state.orderBy}
              version={VERSIONS.V1}
              stamps={cryptoStamp.stampDetails.ownedStampsV1}
            />
            <WalletStampViewCard
              orderBy={state.orderBy}
              version={VERSIONS.V2}
              stamps={cryptoStamp.stampDetails.ownedStampsV2}
            />
          </>
        )}
      </Container>
    </section>
  );
};

export default withTranslation()(WalletComponent);
