import React from 'react';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import UNCSBrand from './styles/UNCSBrand';
import * as bip39 from "bip39";
import { hdkey } from 'ethereumjs-wallet';
import keccak from 'keccak'
import Truncate from './Truncate'

import './BehindTheScenes.css'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BinaryTreeNode from './BinaryTreeNode';
import DemoWalletCalc from './DemoWalletCalc';
// import CodeBox from './CodeBox';
import ReactGA from 'react-ga';
import { withTranslation } from 'react-i18next';

class BehindTheScenes extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stamps: [],
            tree: []
        };
        this.treeNodes = {};
    }

    componentDidMount() {
        ReactGA.pageview('/behind-the-scenes');
    }

    tryOutMerkleTree = async () => {
        const stamps = [];
        const path = "m/44'/60'/0'/0/0"; // default wallet (account 1 in metamask)
        for (var id = 0; id < 4; id++) {
            var mnemonic = bip39.generateMnemonic();
            var seed = await bip39.mnemonicToSeed(mnemonic);
            var hdwallet = hdkey.fromMasterSeed(seed);
            var wallet = hdwallet.derivePath(path).getWallet();
            var leafhash = this.getLeafHash(wallet, id);
            stamps.push({ id, mnemonic, wallet, leafhash });
        }
        const tree = this.merkleTree([...stamps]);
        this.setState({ stamps, tree });
        //
        ReactGA.event({
            category: 'Behind The Scenes',
            action: 'Try out Merkle tree'
        });
    }

    getLeafHash(wallet, id) {
        const b = Buffer.alloc(52);
        b.fill(wallet.getAddress());
        b.writeIntBE(0, 20, 28);
        b.writeIntBE(id, 48, 4);
        return keccak('keccak256').update(b).digest('hex');        
    }

    merkleTree(stamps) {
        const layerNull = [];
        const leafs = stamps.map((stamp, idx) => {
            layerNull.push({
                type: 'Wallet address',
                hash: stamp.wallet.getAddress().toString('hex'),
                address: [0, idx*2]
            }, {
                type: 'Token ID',
                hash: stamp.id,
                address: [0, idx*2 + 1]
            });            
            return {
                type: 'Leaf',
                values: [{
                    name: 'Wallet address',
                    value: stamp.wallet.getAddress().toString('hex')
                }, {
                    name: 'Token ID',
                    value: stamp.id
                }],
                hash: stamp.leafhash,
                address: [1, idx]
            }
        });
        const merkleList = [];
        this.merklify(merkleList, leafs);
        merkleList.unshift(layerNull);
        merkleList.reverse();
        const tree = {
            node: merkleList[0][0],
            children: [
                this.layers(merkleList, 1, 0),
                this.layers(merkleList, 1, 1)
            ]
        };
        return tree;
    }

    compAndConcat(hash1, hash2) {
        if(this.compare(hash1, hash2) <= 0) {
            return hash1 + hash2;
        } else {
            return hash2 + hash2;
        }
    }

    compare(hash1, hash2) {
        return hash1 <= hash2 ? -1 : 1;
    }

    merklify(merkleList, nodes) {
        if (nodes.length === 0) return;
        merkleList.push(nodes);
        const next = [];
        for (let i = 0; i <= nodes.length - 2; i += 2) {
            const b = Buffer.alloc(128);
            b.write(this.compAndConcat(nodes[i].hash, nodes[i + 1].hash));
            let c = this.compare(nodes[i].hash, nodes[i + 1].hash);
            next.push({
                type: nodes.length > 2 ? 'Node' : 'Root node',
                values: [{
                    value: c <= 0 ? nodes[i].hash : nodes[i + 1].hash
                }, {
                    value: c <= 0 ? nodes[i + 1].hash : nodes[i].hash
                }],
                hash: keccak('keccak256').update(b).digest('hex'),
                address: [nodes[i].address[0] + 1, i/2]
            })
        }
        this.merklify(merkleList, next);
    }

    layers(merkleList, layerIdx, childIdx) {
        const node = merkleList[layerIdx][childIdx];
        return {
            node,
            children: merkleList.length === layerIdx + 1 ? null : [
                this.layers(merkleList, layerIdx + 1, childIdx * 2),
                this.layers(merkleList, layerIdx + 1, childIdx * 2 + 1)
            ]
        }
    }

    render() {
        const { t } = this.props;
        return (
            <section className="mt-3" data-aos="zoom-in-up">
            <Container>
                <small className="text-muted">(Available in English only)</small>
                <h1>Behind the scenes</h1>                
                <p>
                    Discover the cryptography and the blockchain technology that makes things happen behind the scenes!
                </p>
                <section>
                    <h2>Cryptographic components of the <UNCSBrand brand lang="en"/></h2>
                    <Row>
                        <Col xs={12} md={6} className="mt-auto mb-auto">
                            Each stamp carries three attributes that distinguish it from all other stamps:
                            <ul className="mt-2">
                                <li className="li-no-dot">
                                    <Button style={{ borderRadius: '0%' }} size="sm" className="mr-2" disabled={true}>1</Button>
                                    An ID that will be associated with one specific stamp throughout the lifetime of the smart contract.
                                </li>
                                <li className="mt-1 li-no-dot">
                                    <Button style={{ borderRadius: '0%' }} size="sm" className="mr-2" disabled={true}>2</Button>
                                    A wallet address that is derived from a public key.
                                </li>
                                <li className="mt-2 li-no-dot">
                                    <Button style={{ borderRadius: '0%' }} size="sm" className="mr-2" disabled={true}>3</Button>
                                    A secret code that is used to derive a public-private key pair. The wallet address controlled by this 
                                    key pair is the temporary owner of the stamp until it is activated on the blockchain.
                                </li>
                            </ul>
                        </Col>
                        <Col xs={12} md={6} className="pt-2 pb-4 ml-auto mr-auto text-center">
                            <Image src={'./images/assets/stamp-NewYork-scratched.jpg'} 
                            alt={t('A United Nation crypto stamp with scratched-off fields revealing the hidden secret code and the stamp ID')} 
                            className="box-shadow-1" width="70%" />
                        </Col>
                    </Row>
                    <DemoWalletCalc />
                </section>
                <section>
                    <h2 className="mb-4">Encoding all stamps into a Merkle tree</h2>
                    <Row className="pt-1">
                        <Col>
                            The existence of each stamp needs to be made aware to the smart contract
                            on the blockchain to avoid the creation of illicit stamps.
                    Since storing data on the blockchain is expensive<sup>(*),(**)</sup>,
                    instead of writing all 90,000 pairs of public stamp data (Token ID and
                    public address) into the smart contract, the existence of each stamp is
                    encoded into a Merkle tree and only the root node of the Merkle tree
                    is stored in the smart contract, resulting in drastically lower deployment costs.
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} md={12}>                            
                        <blockquote className="mt-3 quote-box">
                                <p className="small mb-0">
                                    In cryptography and computer science, a hash tree or Merkle tree is a tree in which every leaf node is labelled with the cryptographic
                                    hash of a data block, and every non-leaf node is labelled with the cryptographic hash of the labels of its child nodes. Hash trees
                                    allow efficient and secure verification of the contents of large data structures.
                            </p>
                                <footer className="blockquote-footer"> <cite title="Wikipedia">Merkle tree. In <a href="https://en.wikipedia.org/wiki/Merkle_tree"
                                    target="_blank" rel="noopener noreferrer">Wikipedia <FontAwesomeIcon icon={faExternalLinkAlt} /></a>. Retrieved September 2020.</cite></footer>
                            </blockquote>
                            <div className="text-center mt-4 mb-3">
                                <Button onClick={this.tryOutMerkleTree.bind(this)}>Try it out!</Button>
                            </div>
                        </Col>
                        <Col className="ml-auto mr-auto mt-auto mb-auto">
                            {this.stampTable()}
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={4} md={12} className="mt-auto mb-auto">
                            <div hidden={this.state.tree.length === 0}>
                                <p>
                                    The generated Merkle tree is public data that is needed by the participants
                                    to proove the existence of the encoded stamp in the Merkle root
                                    node that is stored in the smart contract.
                            </p>
                                <p>
                                    This is done by calculating a proof path for each stamp that consists of the oponent
                                    nodes along the way to the root node. This proof path is then submitted along with
                                    the claimed stamp data to a verification function in the smart contract.
                            </p>
                                <p>
                                    The result of the calculation is compared to the stored root node and deemed successful if the values match.
                                </p>
                            </div>
                        </Col>
                        <Col className="ml-auto mr-auto ">
                            <div className="tree-container">
                                <div className="tree">
                                    <ul>{this.printTree(this.state.tree, 0, 0)}</ul>
                                </div>
                            </div>
                            <p className="text-center mt-4" hidden={this.state.tree.length === 0}>
                                <small className="text-muted">You can hover over the nodes of the tree to see how they were calculated.</small><br/>
                                <small className="text-muted">If you hover over the leaves the yellow nodes mark the proof path and the green nodes mark the calculation path.</small>
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <hr />
                            <small className="text-muted">* Approximately 20 Million USD per GB or 20 USD per Kilobyte, at a gas price of 100 Gwei and an ETH price of around USD 300, own calculations, July/August 2020.</small>
                            <br />
                            <small className="text-muted">** Data for the existence of one stamp naively requires at least 24 bytes (20 bytes for the address of the permissioned owner
                    and 4 bytes for the token ID), therefor total required storage for all 90,000 stamps would be 2,2 Megabytes costing around 44,000 USD as per above calcuation.</small>
                        </Col>
                    </Row>
                </section>
                {/*
                <section>
                    <h2>The Smart Contract</h2>
                    <Row className="pt-4">
                        <Col>
                            <p>
                                Smart contracts on the Ethereum blockchain are written in a language 
                                called <a href="https://solidity.readthedocs.io/en/v0.5.17/index.html" target="_blank" rel="noopener noreferrer">
                                    Solidity <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                </a>.
                            </p>
                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <Col md={12} lg={4}>
                            <h4>Stamp Minting</h4>
                            <p>The activation function is arguably the most important one in the smart contract as it controls the creation of new
                                stamps based on the cryptographic secret provided by each stamp.</p>
                        </Col>
                        <Col md={12} lg={8}>
                            <CodeBox source={`${process.env.PUBLIC_URL}/code/mint.txt`} />
                        </Col>
                    </Row>
                    <Row className="pt-4">
                        <Col md={12} lg={4}>
                            <h4>Proof verification</h4>
                            <p>The <code>verifyClaimWithMerkleProof</code> function</p>
                        </Col>
                        <Col md={12} lg={8}>
                            <CodeBox source={`${process.env.PUBLIC_URL}/code/verify.txt`} />
                            <CodeBox source={`${process.env.PUBLIC_URL}/code/merkle.txt`} />
                        </Col>
                    </Row>                    
                </section>
                */}
                <section>
                    <h6 className="mb-4">Credits</h6>
                    <Container>
                        <Row>
                            <Col>
                            <small className="text-muted">
                                Binary Tree: Olim Saidov and others, 2015:
                                <a href="https://jsfiddle.net/9zftw6o1/" className="ml-1" target="_blank" rel="noopener noreferrer">
                                    jsfiddle.net <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                </a>
                                <a href="https://stackoverflow.com/questions/27852954/center-binary-tree-with-css-or-js/27854482" className="ml-1" target="_blank" rel="noopener noreferrer">
                                    stackoverflow.com <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                </a>
                            </small>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </Container>
            </section>
        )
    }


    stampTable() {
        return <table className="table table-striped table-sm">
            <thead>
                <tr>
                    <th scope="col" className="text-center">TokenID</th>
                    <th scope="col" className="text-center">Secret Mnemonic</th>
                    <th scope="col" className="text-center">Derived Public Wallet Address</th>
                </tr>
            </thead>
            <tbody>
                {this.state.stamps.map(stamp =>
                    <tr key={`stamp-row-${stamp.id}`}>
                        <th scope="row" className="text-center">{stamp.id}</th>
                        <td><small>{Truncate.middle(stamp.mnemonic, 70)}</small></td>
                        <td className="text-center"><small>
                            {Truncate.middle(stamp.wallet.getAddress().toString("hex"), 10)}
                        </small></td>
                    </tr>
                )}
                <tr>
                    <td colSpan="3" className="text-center" height="132" hidden={this.state.stamps.length > 0}></td>                    
                </tr>
            </tbody>
        </table>
    }

    printTree(subtree) {
        if (!subtree || subtree.length === 0) return
        return !subtree.children || subtree.children.length === 0 ?
            <li>
                {this.node(subtree.node)}
            </li>
            :
            <li>
                {this.node(subtree.node)}
                <ul>
                    {this.printTree(subtree.children[0])}
                    {this.printTree(subtree.children[1])}
                </ul>
            </li>
    }

    node(node) {
        const that = this;
        return <BinaryTreeNode node={node} registerCallback={(ref, id) => {
            that.treeNodes[id]= ref;
        }} treeNodes={this.treeNodes}/>
    }
}

export default withTranslation()(BehindTheScenes);
