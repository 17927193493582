import React, { Component } from "react";
import "./App.css";

import { BrowserRouter as Router } from "react-router-dom";

import Store from "./context/Store";

import NavbarComponent from "./components/NavbarComponent.js";
import LanguageBar from "./components/LanguageBar";

import { withTranslation, Trans } from "react-i18next";
import FooterComponent from "./components/FooterComponent";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

import AOS from "aos";
import "aos/dist/aos.css";
import ScrollToTop from "./components/ScrollToTop";
import { Container, Alert } from "react-bootstrap";
import XTrans from "./components/XTrans";
import ReactGA from "react-ga";
import { Routes } from "./Routes";
import { CryptoStampContext, CryptoStampProvider } from "./utils/provider/stamp/Provider";
import web3Handler from "./utils/web3/Web3Handler";

ReactGA.initialize(process.env.REACT_APP_GA_TRACK_ID);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hideWarn: false
    };
  }
  componentDidMount() {
    AOS.init({
      duration: 2000,
    });
    AOS.refresh();
    
    // Listen for scroll events
    // window.addEventListener('scroll', function (event) {
    // Scrolling has happened...
    //  console.log("scrolling", event);
    // }, false);
    ReactGA.set({ lang: this.props.i18n.language });
  }

  handleWarnClick() {
    this.setState({ hideWarn: true });
  }

  envWarning() {
    return (
      <div
        hidden={this.state.hideWarn}
        className="container-fluid text-center p-2 bg-warning"
        onClick={this.handleWarnClick.bind(this)}
      >
        <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
        You are using the {process.env.REACT_APP_ENV_WARNING} environment
      </div>
    );
  }

  render() {
    return (
      <Store>
        <CryptoStampProvider>
          <CryptoStampContext.Consumer>
            {(cryptoDetails) => 
              <Router history={this.props.history}>
                <ScrollToTop />
                <header className="fixed-top">
                  <LanguageBar />
                  <NavbarComponent
                    history={this.props.history}
                  />
                </header>
                <main className="pr-0 pl-0 pb-3 container-fluid">
                  {process.env.NODE_ENV === "production" && process.env.REACT_APP_ENV_WARNING ? this.envWarning() : ""}
                    {cryptoDetails.chainId && cryptoDetails.chainId !== -1 && !web3Handler.isConnectionReady() ? (
                        <section>
                          <Container>
                            <Alert variant="danger">
                              <Alert.Heading>
                                <FontAwesomeIcon icon={faExclamationTriangle} />{" "}
                                <Trans>Wrong Network Selected</Trans>
                              </Alert.Heading>
                              <Container>
                                <Trans>
                                  You are connected to the wrong Ethereum network.
                                </Trans>
                              </Container>
                              <Container>
                                <XTrans
                                  components={{
                                    network: process.env.REACT_APP_NETWORK_NAME,
                                  }}
                                >
                                  In your browser or metamask extension please select the
                                  network with the name '<network />'.
                                </XTrans>
                              </Container>
                            </Alert>
                          </Container>
                        </section>
                      ) : (
                        <Routes />
                      )
                    }
                </main>
                <FooterComponent />
              </Router>
            }
          </CryptoStampContext.Consumer>
        </CryptoStampProvider>
      </Store>
    );
  }
}

export default withTranslation()(App);
