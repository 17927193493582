import React from 'react';
import { Trans, withTranslation } from 'react-i18next';
import './UNCSBrand.css';

class UNCSBrand extends React.Component {    

    constructor(props) {
        super(props);
        this.state = {
            plural: props.plural,
            brand: props.brand,
            rotateLang: props.rotateLang,
            overrideLangIndex: (props.lang || props.rotateLang) ? ['en', 'fr', 'de', 'it', 'es', 'zh'].indexOf(
                props.lang ? props.lang : (props.i18n.language ? props.i18n.language : 'en')) : null,
            lang: props.i18n.language ? props.i18n.language : 'en'
        };
    }

    startRotation() {
        if(this.props.rotateLang) {
            this.interval = setInterval(() => this.nextLang(), 6000);
        }
    }

    stopRotation() {
        if(this.props.rotateLang) {
            clearInterval(this.interval);
        }        
    }

    /**
     * On language change recalculate the override index to the make the rotation start
     * with the selected language. This also resets the rotation interval.
     */
    componentDidUpdate(prevProps) {
        if(this.props.i18n.language !== this.state.lang) {
            // clear interval first
            this.stopRotation();
            this.setState({
                lang: this.props.i18n.language ? this.props.i18n.language : 'en',
                overrideLangIndex: (this.props.lang || this.props.rotateLang) ? ['en', 'fr', 'de', 'it', 'es', 'zh'].indexOf(
                    this.props.lang ? this.props.lang : (this.props.i18n.language ? this.props.i18n.language : 'en')) : undefined
            })
            this.startRotation();
        }
    }

    componentDidMount() {
        this.startRotation();
    }

    componentWillUnmount() {
        this.stopRotation()
    }

    nextLang() {
        this.setState({
            overrideLangIndex: (this.state.overrideLangIndex + 1) % 6
        })
    }

    render() {
        const { t } = this.props;
        const lngs = ['en', 'fr', 'de', 'it', 'es', 'zh'] 
        const anims = ["fade-up", "fade-down", "fade-left", "fade-right"]
        let brand = t('UN crypto stamp', {lng: this.state.overrideLangIndex !== undefined ? lngs[this.state.overrideLangIndex] : null});
        if(this.state.rotateLang) {
            brand = <span key={this.state.overrideLangIndex} data-aos={anims[this.state.overrideLangIndex % 4]} data-aos-delay="300">{brand}</span>
        }
        let singular_trans = <Trans>United Nations crypto stamp</Trans>;
        const plural_trans = <Trans>United Nations crypto stamps</Trans>;
        return <span className="UNCSBrand" >{this.props.brand ? brand : this.props.plural ? plural_trans : singular_trans}</span>
    }
}

export default withTranslation()(UNCSBrand);