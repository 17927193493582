import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Truncate from './Truncate'

class BinaryTreeNode extends React.Component {

    constructor(props) {
        super(props);
        this.ref = React.createRef();
        this.state = {
            ...props.node,
            color : ''
        };
        props.registerCallback(this, `node-${this.state.address[0]}-${this.state.address[1]}`);        
    }

    setBgColor(color) {
        this.setState({color});
    }

    componentDidUpdate(prevProps) {        
        if(this.props.node.hash !== prevProps.node.hash) {
            this.setState({
                ...this.props.node
            })
        }
    }

    onMouseOutCapture(e) {
        this.props.treeNodes['node-1-0'].setBgColor('')
        this.props.treeNodes['node-1-1'].setBgColor('')
        this.props.treeNodes['node-1-2'].setBgColor('')
        this.props.treeNodes['node-1-3'].setBgColor('')
        this.props.treeNodes['node-2-0'].setBgColor('')
        this.props.treeNodes['node-2-1'].setBgColor('')
        this.props.treeNodes['node-3-0'].setBgColor('')
    }
    
    onMouseOverCapture(e) {
        let pathColor = '#FFFF9E';
        let calcColor = 'lightgreen';
        switch(e.target.id) {
            case 'node-1-0':
                this.props.treeNodes['node-1-1'].setBgColor(pathColor)
                this.props.treeNodes['node-2-1'].setBgColor(pathColor)
                this.props.treeNodes['node-2-0'].setBgColor(calcColor)
                this.props.treeNodes['node-3-0'].setBgColor(calcColor)
                break;
            case 'node-1-1':
                this.props.treeNodes['node-1-0'].setBgColor(pathColor)
                this.props.treeNodes['node-2-1'].setBgColor(pathColor)                
                this.props.treeNodes['node-2-0'].setBgColor(calcColor)
                this.props.treeNodes['node-3-0'].setBgColor(calcColor)
                break;
            case 'node-1-2':
                this.props.treeNodes['node-1-3'].setBgColor(pathColor)
                this.props.treeNodes['node-2-0'].setBgColor(pathColor)
                this.props.treeNodes['node-2-1'].setBgColor(calcColor)
                this.props.treeNodes['node-3-0'].setBgColor(calcColor)
                break;
            case 'node-1-3':
                this.props.treeNodes['node-1-2'].setBgColor(pathColor)
                this.props.treeNodes['node-2-0'].setBgColor(pathColor)
                this.props.treeNodes['node-2-1'].setBgColor(calcColor)
                this.props.treeNodes['node-3-0'].setBgColor(calcColor)
                break;
            default:
                break;
        }
    }    
    render() {
        let placement = this.state.address[0] === 3 ? 'top' : this.state.address[0] === 0 ? 'bottom' : this.state.address[1] % 2 === 0 ? 'left' : 'right';
        return (
            <OverlayTrigger
                key={`overlay-trigger-node-${this.state.address[0]}-${this.state.address[1]}`}
                placement={placement}
                overlay={
                    <Tooltip id={`tooltip-node-${this.state.address[0]}-${this.state.address[1]}`} style={{ Xwidth: 250 }}>
                        <h6 className="mb-0">{this.state.type}</h6>
                        <>
                            {this.details()}
                        </>
                    </Tooltip>
                }>
                <span 
                    className="node" 
                    style={{backgroundColor: this.state.color}}
                    id={`node-${this.state.address[0]}-${this.state.address[1]}`}
                    onMouseOverCapture={this.onMouseOverCapture.bind(this)}
                    onMouseOutCapture={this.onMouseOutCapture.bind(this)}
                    >{Truncate.middle(this.state.hash + '', 10)}</span>
            </OverlayTrigger>
        )
    }

    details() {
        if (this.state.values) {
            return <code>{Truncate.middle(this.state.hash + '', 10)}&nbsp;=<br />hash({Truncate.middle(this.state.values[0].value + '', 10)} | {Truncate.middle(this.state.values[1].value + '', 10)})</code>
        } else {
            return <code>{Truncate.middle(this.state.hash + '', 10)}</code>
        }
    }    

}

export default BinaryTreeNode;