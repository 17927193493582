import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Trans } from 'react-i18next';
import XTrans from './XTrans';
import ReactGA from 'react-ga';

class BackgroundInfoComponent extends React.Component {

    constructor() {
        super()
        this.state = {}
    }
    componentDidMount() {
        ReactGA.pageview('/background-info');
    }

    render() {
        return <section className="mt-3" data-aos="zoom-out">
        <Container>
            <h3><Trans>Background information</Trans></h3>
            <Row>                
                <Col>
                    <h5><Trans>Strategy on New Technologies</Trans></h5>
                    <p>
                        <Trans>In December 2018 Secretary-General António Guterres for the first time ever launched a Strategy on New Technologies for the United Nations.</Trans>
                    </p>
                    <p>
                        <Trans>The goal of the initiative is to define how the United Nations system will support the use of new technologies like artificial intelligence, biotechnology, blockchain, and robotics to accelerate the achievement of the 2030 Sustainable Development Agenda and to facilitate their alignment with the values enshrined in the Charter of the United Nations, the Universal Declaration of Human Rights, and the norms and standards of international law.</Trans>
                    </p>
                    <p>
                        <Trans>The Secretary-General identified five guiding principles to support the strategy:</Trans>
                    </p>
                    <ul>
                        <li><Trans>Protect and promote global values.</Trans></li>
                        <li><Trans>Foster inclusion and transparency.</Trans></li>
                        <li><Trans>Work in partnership.</Trans></li>
                        <li><Trans>Build on existing capabilities and mandates.</Trans></li>
                        <li><Trans>Be humble and continue to learn.</Trans></li>
                    </ul>
                    <p>
                        <XTrans>In line with the new strategy, the United Nations Postal Administration is excited to announce the launch for first time ever its <uncss/>.</XTrans> 
                    </p>
                    <p>
                        <XTrans>The stamps were developed in conjunction with the</XTrans>&nbsp;
                        <a href="https://unite.un.org/" title={<Trans>Website of the United Nations Office of Information and Communications Technology</Trans>} 
                        target="_blank" rel="noopener noreferrer">
                        United Nations Office of Information and Communications Technology
                        &nbsp;
                        <FontAwesomeIcon icon={faExternalLinkAlt} className="mr-1"/></a> <Trans>and run on the Ethereum blockchain.</Trans>
                    </p>
                    <p>
                        <Trans>To learn more about the Secretary-General’s Strategy on New Technologies, please visit</Trans>&nbsp;
                        <a href="https://www.un.org/en/newtechnologies/" title={<Trans>Website about about the Secretary-General’s Strategy on New Technologies</Trans>} 
                        target="_blank" rel="noopener noreferrer">
                            https://www.un.org/en/newtechnologies/
                            &nbsp;
                            <FontAwesomeIcon icon={faExternalLinkAlt}/></a>.
                    </p>
                </Col>
            </Row>
        </Container>
        </section>
    }
}

export default BackgroundInfoComponent;