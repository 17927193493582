import React, { Component } from "react";
import { CardDeck } from "react-bootstrap";
import {
  NUMBER_OF_DESIGNS,
  getStampDesignIdentifierMapper,
  VERSIONS,
} from "../../utils/web3/const";
import StampCard from "./StampCard";

class StampCardDeck extends Component {
  constructor(props) {
    super(props);
    this.state = {
      missing: [],
      children: [],
      orderBy: props.orderBy,
    };
    this.existingLocations = new Set();
    this.existing = new Set();
  }

  getMissing() {
    let missing = new Set();
    this.existingLocations.forEach((location) => {
      const numberOfStamp = NUMBER_OF_DESIGNS[this.props.version];
      const startIndex = this.props.version === VERSIONS.V2 ? 0 : 1;

      for (let stamp = startIndex; stamp <= numberOfStamp; stamp++) {
        let key = `${location}-${getStampDesignIdentifierMapper(this.props.version, stamp)}`;
        if (!this.existing.has(key)) {
          missing.add({
            location: location,
            designIdentifier: stamp,
          });
        }
      }
    });
    // console.log(this.existing);
    return missing;
  }

  registerStampData(stamp) {
    this.existingLocations.add(stamp.location);
    let key = `${stamp.location}-${stamp.designIdentifier}`;
    this.existing.add(key);


  }

  componentDidMount() {
    this.populateChildren(this.props.orderBy);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.orderBy !== this.props.orderBy ||
      this.props.stamps.length !== prevProps.stamps.length
    ) {
      this.populateChildren(this.props.orderBy);
    }
  }

  populateChildren(orderBy) {
    // const aos = ['fade-up', 'fade-down', 'fade-right', 'fade-left']
    const aos = ["flip-up", "flip-left", "flip-down", "flip-left"];
    let children = [];
    let idx = 0;

    this.props.stamps.forEach((stamp) => {
      children.push({
        key: stamp.stampId,
        stamp: stamp,
        version: this.props.version,
        linked: true,
        orderBy: orderBy,
        className: this.props.cardClassName,
        registerCallback: (json) => {
          this.registerStampData(json);
        },
        aos: aos[idx % 4],
        aosDelay: 30,
      });
      idx++;
    });

    if (orderBy === "collection") {
      this.getMissing().forEach((missing) => {
        children.push({
          key: "missing-" + idx,
          stamp: {
            stampId: "-----",
            tokenId: "---",
            designIdentifier: getStampDesignIdentifierMapper(
              this.props.version,
              missing.designIdentifier,
            ),
            location: missing.location,
          },
          version: this.props.version,
          placeholder: true,
          linked: false,
          orderBy: orderBy,
          className: this.props.cardClassName + " greyed-out",
          registerCallback: null,
          aos: aos[idx % 4],
          aosDelay: 20 * (idx + 1),
        });
        idx++;
      });
    }
    this.setState({
      children: children,
      orderBy: orderBy,
    });
  }

  render() {
    return (
      <CardDeck>
        {this.state.children.map((value, i) => (
          <StampCard {...value} key={i} />
        ))}
      </CardDeck>
    );
  }
}

export default React.memo(StampCardDeck);
