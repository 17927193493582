import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faArrowRight, faCheckCircle, faMousePointer, faCheck } from '@fortawesome/free-solid-svg-icons';
import { faEthereum } from '@fortawesome/free-brands-svg-icons';
import { Trans } from 'react-i18next'
import XTrans from './XTrans';
import ReactGA from 'react-ga';
import BlockchainButton from './BlockchainButton';

class InstructionsComponent extends React.Component {

    constructor(props) {
      super(props);
      this.state = {};
    }

    componentDidMount() {
        ReactGA.pageview('/start');
    }

    render() {
        return <Container>
            <section>                
                <Container>                                        
                    <Row className="m-2">                        
                        <Col>                            
                            <h2 className="mb-3">
                                <XTrans>Welcome and thank you for purchasing a <uncs/></XTrans>
                            </h2>
                            <p>
                                <Trans>In the above menu bar you can see a button with a diamond inside.</Trans>
                                <FontAwesomeIcon icon={faEthereum} className="ml-1" />
                            </p>
                            <p>
                                <Trans>The color of the button's border and the icon displayed inside tell you if your browser can access the blockchain and if your wallet is connected to this page.</Trans>
                            </p>
                            <p>
                                <Trans>If this is the first time for you to get in touch with the blockchain, then most likely you won't have a wallet and the button will be yellow.</Trans>
                            </p>
                            <p>
                                <FontAwesomeIcon icon={faCheck} className="mr-1" />
                                <b><XTrans>To activate your <uncs/> the button must be green, such as in the example below.</XTrans></b>
                            </p>
                            <p>
                                <Trans>In any case and at any time you can click on the button in the menu bar, and if anything needs to be done for you to be able to connect to the blockchain and access or activate your stamps, the button will detect it and redirect you to the right page containing the proper instructions.</Trans>
                            </p>
                            <p className="mb-0">
                                <FontAwesomeIcon icon={faMousePointer} className="mr-1" />
                                <Trans>Click on the button in the menu bar to learn more!</Trans>
                            </p>
                        </Col>
                    </Row>
                    <Row className="m-1 border-top">
                        <Col className="bg-light p-2">
                            <Container className="text-center bg-dark p-2 rounded">
                                <BlockchainButton chainId={-1} account={-1} inactive={true} placement={'left'} />
                            </Container>                            
                        </Col>
                        <Col>
                            <p className="mt-1 mb-0">                                
                                <Trans>Yellow border with a yellow slash</Trans>
                                <FontAwesomeIcon icon={faArrowRight} className="ml-1 mr-1" />
                                <Trans>Your browser cannot connect to the blockchain.</Trans>
                            </p>
                            <p className="mt-1 mb-0">
                                <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                                <Trans>To be able to connect to the blockchain you will need to install a plugin or extension in your browser.</Trans>
                            </p>
                        </Col>
                    </Row>
                    <Row className="m-1 border-top">
                        <Col className="bg-light p-2">
                            <Container className="text-center bg-dark p-2 rounded">
                                <BlockchainButton chainId={1} account={-1} inactive={true} placement={'left'} />
                            </Container>                            
                        </Col>
                        <Col>
                            <p className="mt-1 mb-0">
                                <Trans>Blue border with a blue question mark</Trans>
                                <FontAwesomeIcon icon={faArrowRight} className="ml-1 mr-1" />
                                <Trans>Your browser can connect to the blockchain, but your wallet is not connected to this page.</Trans>
                            </p> 
                            <p className="mt-1 mb-0">
                                <FontAwesomeIcon icon={faInfoCircle} className="mr-1" />
                                <Trans>Your wallet needs to be connected to this page if you want to access your wallet and see or activate your stamps.</Trans>
                            </p>                        
                        </Col>
                    </Row>
                    <Row className="m-1 border-top">
                        <Col className="bg-light p-2">
                            <Container className="text-center bg-dark p-2 rounded">
                                <BlockchainButton chainId={1} account={1} inactive={true} placement={'left'} />
                            </Container>                            
                        </Col>
                        <Col>
                            <p className="mt-1 mb-0">
                                <Trans>Green border with a green check mark</Trans>
                                <FontAwesomeIcon icon={faArrowRight} className="ml-1 mr-1" />
                                <Trans>Your browser can connect to the blockchain and your wallet is connected to this page.</Trans></p>
                            <p className="mt-1 mb-0">
                                <Trans>On your wallet page you can:</Trans>
                            </p>
                            <ul className="mt-1 mb-0">
                                <li><XTrans>Activate the <uncss/> that you have bought.</XTrans></li>
                                <li><Trans>See the stamps owned by your wallet.</Trans></li>
                            </ul>
                            <p className="mt-1 mb-0">
                                <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                                <Trans>Click on the button to go to your wallet!</Trans>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Container>
    }

}

export default InstructionsComponent;
